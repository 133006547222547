import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../styles/Custom.css";
import { Modal } from "react-bootstrap";
import LoginHome from "../Auth/LoginHome";
import { Link } from "react-router";
import config from "../corefiles/config";
import { getAllSettingAction, getUnreadNotificationCountAction, getUserSessionById } from "../Action/action";
import Cookies from "js-cookie";

const { Connection, PublicKey, clusterApiUrl } = require("@solana/web3.js");
const DashboardHeader = ({
  euronBalance,
  walletAddress,
  disConnectWallet,
  refreshNotifications,
}) => {

  const [loadingData, setLoadingData] = useState(false);
  const [allSetting, setAllSetting] = useState("");

  const [conversionRate, setConversionRate] = useState({
    usdt: null,
    sol: null,
  });

  const [show, setShow] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  let sessionData = Cookies.get("sessionId");
  let parsedSession = JSON.parse(sessionData);

  useEffect(() => {
    getUnreadNotificationCountAPI();
    fetchSessionId()
  }, []);

  const getUnreadNotificationCountAPI = async () => {
    const res = await getUnreadNotificationCountAction();
    if (res.success) {
      setNotificationCount(res.data);
    }
  };

  useEffect(() => {
    if (typeof refreshNotifications === "function") {
      getUnreadNotificationCountAPI();
    } else {
      console.error(
        "refreshNotifications is not a function:",
        refreshNotifications
      );
    }
  }, [refreshNotifications]);

  const handleWalletConnect = async (e) => {
    try {
      if (typeof window === "undefined" || !window.solana) {
        throw new Error(
          "Phantom wallet not found. Please install Phantom Wallet."
        );
      }

      const { solana } = window;
      if (!solana.isPhantom) {
        throw new Error("Phantom wallet is not installed.");
      }
      const response = await solana.connect();
      const walletAddress = response.publicKey.toString();
      const connection = new Connection(
        clusterApiUrl("https://prettiest-warmhearted-snowflake.solana-mainnet.quiknode.pro/75f12c8fcb57eb706bc6ee9c29f056a8599ee388/"),
        "confirmed"
      );

      const balance = await connection.getBalance(new PublicKey(walletAddress));
      return { walletAddress, balance: balance / 1e9 };
    } catch (error) {
      console.error("Error connecting to Phantom Wallet:", error);
      return null;
    }
  };

  const fetchSessionId = async () => {
    try {
      let res = await getUserSessionById(parsedSession)
      if (res.success) {
        console.log('Session Active')
      } else {
        Cookies.remove('loginSuccessEuron')
        window.location.href = `${config.baseUrl}`;
      }
    } catch (error) {
      console.log("error", error.message);
    }
  }

 

  useEffect(() => {
    getAllSetting();
    setInterval(() => {
      getAllSetting();
    }, 30000);
  }, []);

  const getAllSetting = async () => {
    const res = await getAllSettingAction();
    if (res.success) {
      setAllSetting(res.data);
      setLoadingData(true);
    } else {
      setLoadingData(true);
    }
  };

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className=" navbar-background"
          style={{ height: 85 }}
        >
          <Container fluid className="py-1">
            <div className="d-flex d-xl-none w-100">
              <Navbar.Brand className="w-100 d-flex justify-content-center align-items-center ps-5 me-0">
                <Link to={`${config.baseUrl}`} className="">
                  <img
                    src={`${config.baseUrl}images/logoLionIcon.png`}
                    style={{ width: 165, height: 'auto' }}
                    alt=""
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
            </div>
            <Navbar.Offcanvas
              className="offcanvas-body01"
              id={`offcanvasNavbar-expand-${expand} `}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header className="navbar-background" closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Link to={`${config.baseUrl}`}>
                    {" "}
                    <img
                      src="images/logoLionIcon.png"
                      style={{ height: 53, objectFit: "cover" }}
                      alt=""
                    />
                  </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="offcanvas-body01 justify-content-end">
                <div className="d-flex flex-row flex-wrap gap-3 lg-sp align-self-stretch align-items-center w-100 justify-content-between">
                  <div>
                    <div className="price_tag">
                      <p
                        className=" mb-0  px-2 py-1 d-flex justify-content-center align-items-center"

                      >
                        1 LPAY  ={" "}
                        {loadingData
                          ? parseFloat(
                            allSetting?.usdprice / allSetting.solanaPriceUsd
                          ).toFixed(6) + " SOL"
                          : "Loading..."}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <Link
                      to={`${config.baseUrl}notification`}
                      className="text-decoration-none d-flex gap-2 align-items-center me-2"
                    >
                      <img
                        src={`${config.baseUrl}images/noti.svg`}
                        style={{ width: 20, height: 20 }}
                        alt="Notification Icon"
                      />
                      {notificationCount == 0 ? '' :
                        <div className="noti-number">
                          <span>
                            {notificationCount > 0
                              ? notificationCount < 10
                                ? `0${notificationCount}`
                                : notificationCount
                              : ""}
                          </span>
                          <span className="full-text"> Notifications</span>
                        </div>

                      }

                    </Link>

                    <Link
                      to={`${config.baseUrl}buyLionPay`}
                      className="btn01 px-4 py-2 py-sm-1 px-sm-5 px-5 text-decoration-none"
                      style={{ fontSize: 12, fontWeight: 500, height: "100%" }}
                    >
                      Buy LionPay
                    </Link>
                    <div
                      className="border-gradient-wrap  "
                      style={{ display: "none" }}
                    >
                      <div
                        className="border-gradient cursor-pointer "
                        style={{ cursor: "pointer" }}
                        onClick={(e) => handleWalletConnect(e)}
                      >
                        <div className="d-flex gap-2 align-items-center dh-card-data">
                          <p className="mb-0 fs-10">Balance</p>
                          <img
                            src="images/eurocoin.png"
                            style={{ width: 25, height: 25 }}
                            alt=""
                          />
                          <p className="mb-0">
                            {euronBalance
                              ? parseFloat(euronBalance).toFixed(4)
                              : "***** "}{" "}
                            LionPay
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}

      <Modal show={show} size="lg" centered backdropClassName="custom-backdrop">
        <Modal.Body closeButton>
          <div>
            <LoginHome />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardHeader;
