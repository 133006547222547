import { useNavigate } from "react-router";
import config from "../corefiles/config";

export function validatePassword(password) {
    const passwordCriteria = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordCriteria.test(password);
}



export function useHandleBack() {
    const navigate = useNavigate();
    return () => navigate(`${config.baseUrl}dashboard`);
}
