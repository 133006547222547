import React, { useEffect, useState } from "react";
import { Navbar, Nav, Offcanvas, Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Sidebar.css";
import { Link, useLocation } from "react-router";
import { BsJustify } from "react-icons/bs";
import config from "../corefiles/config";
import Cookies from "js-cookie";
import { getProfileDetails } from "../Action/action";

const Sidebar = ({ special, clearModalData }) => {
  const [profileData, setProfileData] = useState("");

  const [show, setShow] = useState(false);
  const [activeCard, setActiveCard] = useState(() => {
    // Retrieve the saved active card index from localStorage or default to 0
    return Number(localStorage.getItem("activeCard")) || 1;
  });
  console.log(activeCard);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    const res = await getProfileDetails();
    if (res.success) {
      setProfileData(res.data);
    }
  };

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  // console.log(activeCard);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = [
    {
      id: 1,
      link: "dashboard",
      title: "Dashboard",
      icon: "images/dashicon1.svg",
    },

    {
      id: 2,
      link: "buyLionPay",
      title: "Buy LionPay",
      icon: "images/buyeuron.svg",
    },
    {
      id: 3,
      link: "wallet",
      title: "Wallet",
      icon: "images/wallet.svg",
    },

    {
      id: 4,
      link: "referral",
      title: "Referral ",
      icon: "images/refer.svg",
    },

    {
      id: 5,
      link: "notification",
      title: "Notifications",
      icon: "images/noti.svg",
    },
    {
      id: 6,
      link: "profile",
      title: "Profile",
      icon: "images/profile.svg",
    },
    {
      id: 7,
      link: "changePassword",
      title: "Change Password",
      icon: "images/password.svg",
    },
  ];

  const handleCardClick = (index) => {
    setActiveCard(index); // Update the active card state
    localStorage.setItem("activeCard", index); // Save to localStorage
  };

  const type = localStorage.getItem("Type");
  const filteredData = data.filter((item) => {
    // Show "Profile" (id 6) only if type is "influencer"
    // if (item.id === 6) {
    //   return type === "influencer";
    // }

    // Show "Referral" (id 4) only if profileData.isInfluencer is true
    // if (item.id === 4) {
    //   return profileData?.isInfluencer === true;
    // }

    // Otherwise, include all other items
    return true;
  });

  const location = useLocation(); // Get the current route

  useEffect(() => {
    const activeItem = filteredData.find(
      (item) => `${config.baseUrl}${item.link}` === location.pathname
      // (item) => console.log("item", `${config.baseUrl}${item.link}`)
    );
    if (activeItem) {
      setActiveCard(activeItem.id);
    }

    console.log("activeItem", activeItem);
  }, [location.pathname]);

  // console.log(filteredData);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        backgroundColor: "#281E1A",
        position: "relative",
        color: "#ffffff",
      }}
      className="bshadow"
    >
      <ScrollToTop />
      {/* Sidebar Toggle Button */}
      <div
        variant="light"
        onClick={handleShow}
        className="d-lg-none"
        style={{
          position: "absolute",
          top: "23px",
          left: "15px",
          fontSize:'27px',
          zIndex: 1000,
        }}
      >
        ☰ <span></span>
      </div>

      {/*-------------------------------------------------------------------------------- Sidebar for larger screens -----------------------------------------------------------------------------*/}
      <nav
        className="d-none d-lg-flex flex-column mt-3"
        style={{
          width: "240px",
          backgroundColor: "#281E1A",
          padding: "15px 0px 15px 15px",
        }}
      >
        <Link to={`${config.baseUrl}`} className="mb-4">
          <img
            src={`${config.baseUrl}images/logoLionIcon.png`}
            style={{ width: 200, height: 53, objectFit: "contain" }}
            alt=""
          />
        </Link>
        <Nav className="flex-column gap-2" style={{ fontSize: "14px" }}>
          {filteredData.map((item, index) => {
            {
              /* console.log(item) */
            }
            return (
              <div
                key={index}
                style={{ minWidth: 200 }}
                onClick={() => {
                  handleCardClick(item.id);
                  console.log("iid", item.id);
                }}
              >
                {console.log("item.id", item.id)}
                <Link
                  to={`${config.baseUrl}${item.link}`}
                  style={{ padding: "11px 0px", fontSize: 14 }}
                  className={`sidebar-tab sidebar-gradient  px-4 ${
                    activeCard === item.id ? "active " : ""
                  }`}
                >
                  <img
                    src={item.icon}
                    alt=""
                    style={{ width: 20, height: 20 }}
                    className="me-2"
                  />{" "}
                  {item.title}
                </Link>
              </div>
            );
          })}
        </Nav>

        {special &&
          profileData.isInfluencer ==
            true(
              <div className="mt-4" style={{ bottom: "15%" }}>
                <div
                  style={{ minWidth: 200 }}
                  onClick={() => handleCardClick(5)}
                >
                  <Link
                    to={`${config.baseUrl}referral`}
                    className={`sidebar-tab sidebar-gradient px-4 ${
                      activeCard === 6 ? "active" : ""
                    }`}
                    style={{ padding: "11px 0px", fontSize: 14 }}
                  >
                    <img
                      src="images/logout.svg"
                      style={{ width: 20, height: 20 }}
                      alt=""
                      className="me-2"
                    />{" "}
                    Referral
                  </Link>
                </div>
              </div>
            )}
        <div className=" mb-0 mt-2">
          <div style={{ minWidth: 225 }}>
            <Link
              to={`${config.baseUrl}`}
              onClick={() => {
                setActiveCard(1);
                localStorage.setItem("activeCard", 1);
                Cookies.remove("Token");
                Cookies.remove("euronBalance");
                Cookies.remove("walletAddress");
                Cookies.remove("loginSuccessEuron");
                clearModalData();
              }}
              className={`sidebar-tab sidebar-gradient  px-4 ${
                activeCard === "logout" ? "active" : ""
              }`}
              style={{ padding: "11px 0px", fontSize: 14 }}
            >
              <img
                src="images/logout.svg"
                style={{ width: 20, height: 20 }}
                alt=""
                className="me-2"
              />{" "}
              Logout
            </Link>
          </div>
        </div>
      </nav>

      {/*---------------------------------------------------------------------- Offcanvas Sidebar for smaller screens----------------------------------------------------------------------------- */}
      <div style={{ position: "relative" }}>
        <Offcanvas
          show={show}
          onHide={handleClose}
          className="d-lg-none slide-animation"
          style={{
            backgroundColor: "#281E1A",
            color: "#fff",

            display: "flex",
            position: "absolute",
            borderRadius: "0px 10px 10px 0px",
            overflow: "auto",
          }}
        >
          <Offcanvas.Header closeButton>
            <Link to={`${config.baseUrl}dashboard`}>
              <img
                src="images/logoLionIcon.png"
                style={{height: 53, objectFit: "cover" }}
                alt=""
              />
            </Link>
          </Offcanvas.Header>
          <Offcanvas.Body className="ocb">
            {data.map((item, index) => (
              <div
                key={index}
                style={{ minWidth: 200 }}
                onClick={() =>
                  requestAnimationFrame(() => handleCardClick(item.id))
                }
              >
                <Nav
                  className="flex-column gap-3 mt-2"
                  style={{ fontSize: "14px" }}
                >
                  <Link
                    to={`${config.baseUrl}${item.link}`}
                    onClick={handleClose}
                    style={{ padding: "13px 0px", fontSize: 14 }}
                    className={`sidebar-tab sidebar-gradient py-3 px-4 ${
                      activeCard === item.id ? "active" : ""
                    }`}
                  >
                    <img src={item.icon} alt="" className="me-2" /> {item.title}
                  </Link>
                </Nav>
              </div>
            ))}
            {special && (
              <div className="mt-4" style={{ bottom: "15%" }}>
                <div style={{ minWidth: 200 }}>
                  <Link
                    to={`${config.baseUrl}referral`}
                    className={`sidebar-tab sidebar-gradient py-3 px-4 ${
                      activeCard === 6 ? "active" : ""
                    }`}
                    style={{ fontSize: "14px" }}
                  >
                    <img src="images/logout.svg" alt="" className="me-2" />{" "}
                    Referral
                  </Link>
                </div>
              </div>
            )}
            <div style={{ minWidth: 200 }}>
              <Nav className="flex-column gap-3 mt-2">
                <Link
                  to={`${config.baseUrl}`}
                  onClick={() => {
                    setActiveCard(1);
                    localStorage.setItem("activeCard", 1);
                    Cookies.remove("loginSuccessEuron");
                    Cookies.remove("euronBalance");
                    Cookies.remove("walletAddress");
                    clearModalData();
                  }}
                  className={`sidebar-tab sidebar-gradient py-3 px-4 ${
                    activeCard === "logout" ? "active" : ""
                  }`}
                  style={{ fontSize: 13 }}
                >
                  <img src="images/logout.svg" alt="" className="me-2" /> Logout
                </Link>
              </Nav>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default Sidebar;
