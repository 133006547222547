import React, { useEffect, useState } from "react";
import Sidebar from "../component/Sidebar";
import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap"; // Import Tab and Tabs
import DashboardHeader from "../component/DashboardHeader";
import "../styles/Dashboard.css";
import "../styles/Custom.css";
import { RxCross2 } from "react-icons/rx";
import DataTable from "react-data-table-component";
import {
  getProfileDetails,
  getRefferalDetailsAction,
  getRefferalPurchaseDetailsAction,
} from "../Action/action";
import moment from "moment/moment";
import config from "../corefiles/config";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { IoCopyOutline } from "react-icons/io5";
import { MdShare } from "react-icons/md";
import { getAllSettingAction } from "../Action/action";

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";
import {
  FaFacebook,
  FaLinkedin,
  FaSquareXTwitter,
  FaTelegram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa6";
const { Connection, PublicKey, clusterApiUrl } = require("@solana/web3.js");

const Referral = ({ special, clearModalData }) => {
  const [refferalDetails, setRefferalDetails] = useState([]);
  const [generateLink, setGenerateLink] = useState(false);
  const [profileData, setProfileData] = useState("");
  const [refferalTokenDetails, setRefferalTokenDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("referralList"); // State to track active tab
  const [totalTokenAmountData, setTotalTokenAmountData] = useState("");
  const [allSetting, setAllSetting] = useState("");
  const [balanceCheck, setBalanceCheck] = useState(false);
  const [balanceCheckGreater, setBalanceCheckGreater] = useState(false);

  const loginData = !Cookies.get("loginSuccessEuron")
    ? []
    : Cookies.get("loginSuccessEuron");
  const shareTitle = "Join me on this platform!";

  const getAllSetting = async () => {
    const res = await getAllSettingAction();
    if (res.success) {
      setAllSetting(res.data);
    } else {
    }
  };

  const columns = [
    {
      name: "S No.",
      selector: (row) => row.id,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Joining Date ",
      selector: (row) => moment(row.Date).format("MMM Do, YYYY"),
    },
  ];

  const columnsRef = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "Referral Amount",
      selector: (row) =>
        parseFloat(row.tokenAmount).toFixed(4) +
        ` ${row.paymentType == "SOLANA" ? "SOL" : row.paymentType}`,
    },
    {
      name: "Wallet Address",
      selector: (row) => {
        const walletAddress = row.walletAddress; // Access the wallet address from the row
        return (
          <>
            {walletAddress
              ? walletAddress?.substring(0, 4) +
                "...." +
                walletAddress?.substring(walletAddress.length - 4)
              : "NA"}
            {walletAddress ? (
              <CopyToClipboard
                text={walletAddress}
                onCopy={() => {
                  toast.success("Copied!");
                }}
              >
                <span style={{ cursor: "pointer" }} className="ms-2">
                  <IoCopyOutline />
                </span>
              </CopyToClipboard>
            ) : (
              ""
            )}
          </>
        );
      },
      // sortable: true,
      center: true,
    },
    {
      name: "Transaction Hash",
      selector: (row) => {
        const trxHash = row.trxHash; // Access the wallet address from the row
        return (
          <>
            {row.paymentType == "Paypal" ? (
              trxHash
            ) : trxHash ? (
              <a
                href={`${config.solanaTrxUrl1}${trxHash}${config.solanaTrxUrl2}`}
                target="_blank"
                className="text-color"
              >
                {trxHash?.substring(0, 4) +
                  "...." +
                  trxHash?.substring(trxHash.length - 4)}
              </a>
            ) : (
              "NA"
            )}
          </>
        );
      },
      center: true,
    },
    {
      name: "Referral %",
      selector: (row) => row?.refPercentage,
    },
    {
      name: "Transaction Date",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "status",
      selector: (row) => {
        return row.status == 0 ? (
          <span className="status-label pending">Pending</span>
        ) : row.status == 1 ? (
          <span className="status-label success">Success</span>
        ) : (
          <span className="status-label reject">Rejected</span>
        );
      },
    },
  ];

  const data = refferalDetails?.map((item, index) => ({
    id: index + 1,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
    Date: item.Date,
  }));

  const customStyles = {
    table: {
      style: {
        borderRadius: 16,
        background:
          "linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%)",
      },
    },
    rows: {
      style: {
        minHeight: "72px", // override the row height
        backgroundColor: "transparent", // Row default color (can be overridden)
        "&:nth-of-type(odd)": {
          backgroundColor: "transparent", // Striped row color
        },
      },
    },
    header: {
      style: {
        backgroundColor: "transparent",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "transparent", // Header background color
        color: "white", // Header text color
        display: "flex",
        alignItems: "center",
        justifyContent: "Center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        color: "white", // Cell text color
        display: "flex",
        alignItems: "center",
        justifyContent: "Center",
        fontSize: "14px",
      },
    },
    noData: {
      style: {
        backgroundColor: "transparent",
        color: "white",
        fontSize: 12,
      },
    },
    pagination: {
      style: {
        color: "white",
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: "transparent",
      },
      pageButtonsStyle: {
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "0px",
        cursor: "pointer",
        transition: "0.4s",
        color: "white",
        fill: "white",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "white",
          fill: "white",
        },
      },
    },
  };

  useEffect(() => {
    getAllSetting();
    getRefferal();
    getProfile();
    getRefferalPurchase();
    
  }, []);

  const getRefferal = async () => {
    const res = await getRefferalDetailsAction();
    if (res.success) {
      setRefferalDetails(res.data[0].referrerDetails);
    }
  };

  const getRefferalPurchase = async () => {
    const res = await getRefferalPurchaseDetailsAction();
    if (res.success) {
      setRefferalTokenDetails(res.data);

      // Calculate totals separately for USDT and SOLANA
      const { usdtTotal, solanaTotal } = res.data.reduce(
        (acc, item) => {
          const amount = Number(item.tokenAmount) || 0;
          if (item.paymentType === "USDT") {
            acc.usdtTotal += amount;
          } else if (item.paymentType === "SOLANA") {
            acc.solanaTotal += amount;
          }
          return acc;
        },
        { usdtTotal: 0, solanaTotal: 0 }
      );

      setTotalTokenAmountData({ usdtTotal, solanaTotal });
    }
  };

  const getProfile = async () => {
    const res = await getProfileDetails();
    if (res.success) {
      setProfileData(res.data);
      if(res.data.walletAddress){
        getCheckBalanceG(res.data.walletAddress)
      }
    }
  };

  const [copied, setCopied] = useState(false);

  const generateLinkAction = () => {
    if (profileData.adminApproval !== "1") {
      toast.error("Your profile is under review! Please wait for approval.");
      setBalanceCheck(false);
      setGenerateLink(false);
    } else if (!profileData.walletAddress) {
      toast.error("Please update your wallet address in profile.");
      setGenerateLink(false);
      setBalanceCheck(false);
    } else if (profileData.walletAddress) {
      checkBalance();
      setGenerateLink(false);
    } else {
      setGenerateLink(true);
    }
  };

  async function checkBalance() {
    try {
      const connection = new Connection(config.clusterUri, "confirmed");
      const publicKey = new PublicKey(profileData.walletAddress);
      const balance = await connection.getBalance(publicKey);
      let bls = balance / 1e9;
     
      if (bls < 0.002) {
        toast.error("0.002 SOL is required in your wallet to receive referral earnings.");
        setGenerateLink(false);

        setBalanceCheck(false);
      } else {
        setGenerateLink(true);
        setBalanceCheck(true);
      }
    } catch (error) {
    }
  }


  async function getCheckBalanceG(walletAdd) {
    try {
      const connection = new Connection(config.clusterUri, "confirmed");
      const publicKey = new PublicKey(walletAdd);
      const balance = await connection.getBalance(publicKey);
      let bls = balance / 1e9;
      if (bls < 0.002) {
        setGenerateLink(false);
        setBalanceCheck(false);
      } else {
        setGenerateLink(true);
        setBalanceCheck(true);
      }
      
    } catch (error) {
    }
  }

  
  const handleCopy = () => {
    const referralUrl = `${config.refferalUrl}${profileData.referralCode}`;

    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(referralUrl)
      .then(() => {
        setCopied(true); // Show the copied confirmation message
        toast.success("copied");
        setTimeout(() => setCopied(false), 2000); // Reset the copied message after 2 seconds
      })
      .catch((err) => {
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div
      className="w-100"
      // style={{ height: "100vh", overflow: "hidden" }}
    >
      <Toaster />
      <div className="d-flex w-100">
        <Sidebar special={special} clearModalData={clearModalData} />
        <div className="w-100" style={{ height: "100%" }}>
          <DashboardHeader />
          <div
            className="pt-sm-2 pt-5 mt-lg-4 px-4 px-lg-5   pb-5  wrapper-dash"
            style={{
              // padding: "15px",
              flex: "1",
              backgroundColor: "#100500",
              color: "#ffffff",
              height: "87vh",
              overflowY: "scroll",
            }}
          >
            <h2 className="dash-header fs-20">Refer a Friend</h2>

            <div className="w-100 refer px-2 h-auto px-md-5 py-4 mt-3 ">
              <div className="d-flex gap-2 gap-sm-5 align-items-center justify-content-center justify-content-md-start  flex-wrap">
                <p className="text-color mb-0 fs-12">
                  <b>Referral Link</b>
                </p>

                {generateLink ||
                (profileData.adminApproval === "1" &&
                  profileData.walletAddress &&
                  balanceCheck == true) ? (
                  <>
                    <div
                      className="btn01 px-4 px-sm-5 py-3 py-sm-2 d-flex flex-wrap gap-2 gap-sm-4 raf"
                      style={{ borderRadius: 10, cursor: "pointer" }}
                    >
                      <div className="d-flex align-items-center gap-3">
                        <p
                          className="mb-0 fs-12"
                          style={{ fontSize: 12, wordBreak: "break-all" }}
                        >
                          {`${config.refferalUrl}${
                            profileData.referralCode || ""
                          }`}
                        </p>
                        <div className="d-flex gap-2">
                          <div className="refer-btn" onClick={handleCopy}>
                            <img
                              src="images/clip.png"
                              style={{ width: 15, height: 15 }}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="refer-btn022" onClick={handleShow}>
                            <img
                              src="images/share.svg"
                              style={{ width: 15, height: 15 }}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        size="md"
                        centered
                        keyboard={false}
                      >
                        <Modal.Body>
                          <div
                            onClick={handleClose}
                            className="float-right text-white"
                            style={{ float: "right", cursor: "pointer" }}
                          >
                            <RxCross2 />
                          </div>
                          <h5 className="text-center text-white mt-2">
                            {" "}
                            Share Via
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: "wrap",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="mt-3"
                          >
                            <FacebookShareButton
                              url={`${config.refferalUrl}${
                                profileData.referralCode || ""
                              }`}
                              className="share-btn"
                              quote={shareTitle}
                            >
                              <button className="custom-facebook-btn">
                                <FaFacebook style={{ width: 20, height: 20 }} />
                              </button>
                            </FacebookShareButton>

                            <TwitterShareButton
                              url={`${config.refferalUrl}${
                                profileData.referralCode || ""
                              }`}
                              title={shareTitle}
                            >
                              <button className="custom-facebook-btn">
                                <FaSquareXTwitter
                                  style={{ width: 20, height: 20 }}
                                />
                              </button>
                            </TwitterShareButton>

                            <LinkedinShareButton
                              url={`${config.refferalUrl}${
                                profileData.referralCode || ""
                              }`}
                            >
                              <button className="custom-facebook-btn">
                                <FaLinkedin style={{ width: 20, height: 20 }} />
                              </button>
                            </LinkedinShareButton>

                            <WhatsappShareButton
                              url={`${config.refferalUrl}${
                                profileData.referralCode || ""
                              }`}
                              title={shareTitle}
                            >
                              <button className="custom-facebook-btn">
                                <FaWhatsapp style={{ width: 20, height: 20 }} />
                              </button>
                            </WhatsappShareButton>

                            <TelegramShareButton
                              url={`${config.refferalUrl}${
                                profileData.referralCode || ""
                              }`}
                              title={shareTitle}
                            >
                              <button className="custom-facebook-btn">
                                <FaTelegram style={{ width: 20, height: 20 }} />
                              </button>
                            </TelegramShareButton>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </>
                ) : (
                  <div
                    className="btn01 px-5 py-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => generateLinkAction()}
                  >
                    Generate Link
                  </div>
                )}
              </div>
            </div>
            <br />
            {/* Tabs Section */}

            <Row className="gy-3">
              <Col xl={4} lg={4} md={4} sm={12}>
                <div className="dashboard-card dash-gradient2 p-3 d-flex gap-3 justify-content-center">
                  <div className="d-flex gap-3 align-items-center">
                    <div className="p-3 d-icon">
                      <img
                        src="images/referred.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="mb-0 fs-12">Total Referral</p>
                      <p
                        className="mb-0 d-flex gap-2 align-items-center"
                        style={{ fontSize: 24, fontWeight: 600 }}
                      >
                        {refferalDetails ? (
                          refferalDetails.length || 0
                        ) : (
                          <div className="custom-loader ms-4 mt-2"></div>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={4} lg={4} md={4} sm={12}>
                <div className="dashboard-card dash-gradient2 p-3 d-flex gap-3 justify-content-center">
                  <div className="d-flex gap-3 align-items-center">
                    <div className="p-3 d-icon">
                      <img
                        src="images/dashboardicon2.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="mb-1 fs-12">Total Earnings</p>
                      <div
                        className="d-flex flex-column"
                        style={{ fontSize: 16, fontWeight: 600 }}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src="https://cryptologos.cc/logos/solana-sol-logo.png"
                            alt="SOLANA"
                            style={{ width: "20px", height: "20px" }}
                          />
                          <span>
                            {parseFloat(
                              totalTokenAmountData.solanaTotal
                            ).toFixed(4)}{" "}
                            SOL
                          </span>
                        </div>
                        <div className="d-flex gap-2 mt-1">
                          <img
                            src="https://cryptologos.cc/logos/tether-usdt-logo.png"
                            alt="USDT"
                            style={{ width: "20px", height: "20px" }}
                          />
                          <span>
                            {parseFloat(totalTokenAmountData.usdtTotal).toFixed(
                              4
                            )}{" "}
                            USDT (SOL)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={4} lg={4} md={4} sm={12}>
                <div className="dashboard-card dash-gradient2 p-3 d-flex gap-3 justify-content-center">
                  <div className="d-flex gap-3 align-items-center">
                    <div className="p-3 d-icon">
                      <img
                        src="images/referred.svg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="mb-0 fs-12">Referral Percentage</p>
                      <p
                        className="mb-0 d-flex gap-2 align-items-center"
                        style={{ fontSize: 24, fontWeight: 600 }}
                      >
                        {profileData.isInfluencer == true
                          ? profileData?.refferalPercentage + "%"
                          : allSetting.userRefferalPercentage + "%"}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Tabs
              activeKey={activeTab}
              onSelect={(key) => setActiveTab(key)}
              id="referral-tabs"
              className="mt-4 referral-tab1"
            >
              <Tab eventKey="referralList" title="Referral List">
                <br />
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  pagination
                  noDataComponent={
                    <div
                      style={{
                        padding: "10px",
                        fontSize: "14px",
                        color: "white",
                      }}
                      className="py-3"
                    >
                      There are no records to display.
                    </div>
                  }
                />
              </Tab>
              <Tab eventKey="referralEarnings" title="Referral Earnings">
                <br />
                <Row>
                  <DataTable
                    columns={columnsRef}
                    data={refferalTokenDetails}
                    customStyles={customStyles}
                    pagination
                    noDataComponent={
                      <div
                        style={{
                          padding: "10px",
                          fontSize: "14px",
                          color: "white",
                        }}
                        className="py-3"
                      >
                        There are no records to display.
                      </div>
                    }
                  />
                </Row>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
