const config = {
  baseUrl: "/",
  refferalUrl: "https://lionpay.io/",
  apiUrl: "https://api.lionpay.io",
  // apiUrl: "http://localhost:7787",
  currencyName: "LIONPAY",
  solanaTrxUrl1: "https://explorer.solana.com/tx/",
  solanaTrxUrl2: "?cluster=mainnet-beta",
  NETWORK: "mainnet-beta",
  EURONBALANCE: "3T3LmoSAfn8hiWWi91RkN1x9tDu6v2MXBNTLtocBgS4Z",
  USDTToken: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
  coingikoApi:
    "https://api.coingecko.com/api/v3/simple/price?ids=tether,solana&vs_currencies=usd",
  clusterUri:
    "https://prettiest-warmhearted-snowflake.solana-mainnet.quiknode.pro/75f12c8fcb57eb706bc6ee9c29f056a8599ee388/",
usdtDecimals:1_000_000,
payoutWallet:"DFQbNnKVNMk2yiWBL8hC94rphdsRQioLibeme1HWiD76",

};

// const config = {
//   baseUrl: "/",

//     refferalUrl:"https://espsofttech.in/lionpay/",
//     apiUrl: "http://localhost:7787",
//     // apiUrl:"https://euronss.espsofttech.org",
//     currencyName:"LIONPAY",
//     solanaTrxUrl1 :"https://explorer.solana.com/tx/",
//     solanaTrxUrl2 :"?cluster=devnet",
//     PAYPALCLIENT_ID :"Afpb86ZicX4MRd__4rSjs7dP1UtR_Mm5deIpmQAd7loaIVZl4tnSkgj9Aos4nNYUIsvkcJUOwX0mDsDl",
//     PAYPALAPP_SECRET :"EGMUdqBzEM27L2L7gN96m3313xUFN-VlTsPlLnUn6vwFGdiiaXOO7dVdQt4R6brzWeCq6v4vKy3TYn5i",
//     STRIPEPUBLISH_KEY:"pk_test_51Qrz2sRSeUq9SfNqc7lnVMfVN4IUGGPaeK34bgJltG1u3TQMrNEA1Saag5qfEoFvStAVYladgbBeCHZ30rjRQXrJ00m4NzGhfM",
//     STRIPESECRET_KEY:"sk_test_51Qrz2sRSeUq9SfNqcFmF8NVQI0T9XXTwTtaAr3mFoQolga61xllM40AkjmzUkDHsnAJvupMcIdBjbZ8EK35WOzPh00t6Lm3qwk",
//     NETWORK:"devnet",
//     EURONBALANCE:"6iRWfc4kZDagTiav8p3kwY5bULKVja3ZCyetN66surdz",
//     s3Location: 'https://naoibucket.s3.ap-south-1.amazonaws.com/',
//     USDTToken:"6yZYAWVtM5gkcmbkPkSTuHqdWopsYNXkHAdwk5H7cCVR",
//     coingikoApi: "https://api.coingecko.com/api/v3/simple/price?ids=tether,solana&vs_currencies=usd",
//     clusterUri : "https://api.devnet.solana.com",
//     payoutWallet:"GATH2emHcXsnytrjkeiGsLEdrz4jWWdrCecNCRPVq8pr",
//     usdtDecimals:1_000_000_000
// }
export default config;
