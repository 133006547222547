import React, { useEffect, useState } from "react";
import DashboardHeader from "../component/DashboardHeader";
import Sidebar from "../component/Sidebar";
import DataTable from "react-data-table-component";
import { Col, Row } from "react-bootstrap";

import CopyToClipboard from "react-copy-to-clipboard";
import { IoCopyOutline, IoSearch } from "react-icons/io5";
import {
  getDashboardStaticsAction,
  getTokenPurchaseHistoryAction,
} from "../Action/action";
import {
  Connection,
  PublicKey,
  clusterApiUrl,
  Transaction,
  SystemProgram,
} from "@solana/web3.js";
import {
  getAssociatedTokenAddress,
  getAccount,
  createTransferInstruction,
  createAssociatedTokenAccountInstruction,
} from "@solana/spl-token";
import config from "../corefiles/config";
import { Buffer } from "buffer";
import Cookies from "js-cookie";
import { formatAmount } from "../formatedAmount";

import moment from "moment/moment";
import toast, { Toaster } from "react-hot-toast";
const Wallet = ({ special, clearModalData }) => {
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const EURONBALANCE = new PublicKey(config.EURONBALANCE); // USDT on Solana
  const [euronBalance, setEuronBalance] = useState(Cookies.get("euronBalance"));
  const network = clusterApiUrl(config.NETWORK); // Change to "mainnet-beta" for real transfers
  const [walletAddress, setWalletAddress] = useState(
    Cookies.get("walletAddress")
  );
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [dashboard, setDashboard] = useState([]);
  const [Loading, setLoading] = useState([]);

  useEffect(() => {
    const checkConnection = async () => {
      if (window.solana && window.solana.isPhantom) {
        try {
          const response = await window.solana.connect({ onlyIfTrusted: true });
          setIsWalletConnected(!!response.publicKey);
        } catch (error) {
          setIsWalletConnected(false);
          Cookies.remove("euronBalance");
          Cookies.remove("walletAddress");
        }
      }
    };
    checkConnection();
  }, []);

  const getDashboardStatics = async () => {
    setLoading(true);
    const res = await getDashboardStaticsAction();
    if (res.success) {
      setLoading(false);
      setDashboard(res.data);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTokenPurchaseHistory();
    getDashboardStatics();
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.Buffer = Buffer;
    }
  }, []);

  useEffect(() => {
    const handleAccountChange = (newPublicKey) => {
      if (newPublicKey) {
        setWalletAddress(newPublicKey.toString());
        fetchEuronBalance(newPublicKey);
      } else {
        setWalletAddress(null);
        setEuronBalance(0);
      }
    };

    const checkWalletStatus = async () => {
      if (window.solana && window.solana.isPhantom) {
        try {
          const response = await window.solana.connect({ onlyIfTrusted: true });

          if (response.publicKey) {
            setWalletAddress(response.publicKey.toString());
            fetchEuronBalance(response.publicKey);
          } else {
            setWalletAddress(null);
            setEuronBalance(0);
          }
        } catch (error) {
          console.log("Wallet is locked or connection failed:", error);
          setWalletAddress(null);
          setEuronBalance(0);
        }
      }
    };

    checkWalletStatus();

    if (window.solana && window.solana.isPhantom) {
      window.solana.on("accountChanged", handleAccountChange);
    }

    return () => {
      if (window.solana && window.solana.isPhantom) {
        window.solana.off("accountChanged", handleAccountChange);
      }
    };
  }, []);

  const fetchEuronBalance = async (publicKey) => {
    if (!publicKey) {
      setEuronBalance(0);
      return;
    }

    try {
      const connection = new Connection(network, "confirmed");
      const usdtTokenAddress = await getAssociatedTokenAddress(
        EURONBALANCE,
        publicKey
      );
      const accountInfo = await connection.getAccountInfo(usdtTokenAddress);

      if (!accountInfo) {
        setEuronBalance(0);
        return;
      }

      const tokenAccount = await getAccount(connection, usdtTokenAddress);
      setEuronBalance(Number(tokenAccount.amount) / 1_000_000_000);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
      setEuronBalance(0);
    }
  };

  const getTokenPurchaseHistory = async () => {
    const res = await getTokenPurchaseHistoryAction();
    if (res.success) {
      setPurchaseHistory(res.data);
    }
  };

  const disConnectWallet = async () => {
    Cookies.remove("euronBalance");
    Cookies.remove("walletAddress");
    setEuronBalance("");
    setWalletAddress(null);
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "LionPay Token",
      selector: (row) => formatAmount(row.tokenAmount) + ` ${config.currencyName}`,
    },
    {
      name: "Crypto/Fiat ",
      selector: (row) =>
        row?.paymentType == "SOLANA"
          ? row?.equivalentAmount + " SOL"
          : "$" + row?.equivalentAmount,
    },
    {
      name: "Wallet Address",
      selector: (row) => {
        const walletAddress = row.walletAddress; // Access the wallet address from the row
        return (
          <>
            {walletAddress
              ? walletAddress?.substring(0, 4) +
              "...." +
              walletAddress?.substring(walletAddress.length - 4)
              : "NA"}
            {walletAddress ? (
              <CopyToClipboard
                text={walletAddress}
                onCopy={() => {
                  toast.success("Copied!");
                }}
              >
                <span style={{ cursor: "pointer" }} className="ms-2">
                  <IoCopyOutline />
                </span>
              </CopyToClipboard>
            ) : (
              ""
            )}
          </>
        );
      },
      // sortable: true,
      center: true,
    },
    {
      name: "Transaction Hash",
      selector: (row) => {
        const trxHash = row.trxHash; // Access the wallet address from the row
        return (
          <>
            {row.paymentType == "Paypal" ? (
              trxHash
            ) : trxHash ? (
              <a
                href={`${config.solanaTrxUrl1}${trxHash}${config.solanaTrxUrl2}`}
                target="_blank"
                className="text-color"
              >
                {trxHash?.substring(0, 4) +
                  "...." +
                  trxHash?.substring(trxHash.length - 4)}
              </a>
            ) : (
              "NA"
            )}
          </>
        );
      },
      center: true,
    },
    {
      name: "Token Transaction Hash",
      selector: (row) => {
        const tokenTransferHash = row.tokenTransferHash; // Access the wallet address from the row
        return (
          <>
            {row.paymentType == "Paypal" ? (
              tokenTransferHash
            ) : tokenTransferHash ? (
              <a
                href={`${config.solanaTrxUrl1}${tokenTransferHash}${config.solanaTrxUrl2}`}
                target="_blank"
                className="text-color"
              >
                {tokenTransferHash?.substring(0, 4) +
                  "...." +
                  tokenTransferHash?.substring(tokenTransferHash.length - 4)}
              </a>
            ) : (
              "NA"
            )}
          </>
        );
      },
      center: true,
    },
    {
      name: "Payment Type",
      selector: (row) => row?.paymentType,
    },
    {
      name: "Purchased Date",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "Status",
      selector: (row) => {
        return row.status == 0 ? (
          <span className="status-label pending">Pending</span>
        ) : row.status == 1 ? (
          <span className="status-label success">Success</span>
        ) : (
          <span className="status-label reject">Rejected</span>
        );
      },
    },
  ];

  const customStyles = {
    table: {
      style: {
        borderRadius: 16,
        background:
          "linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%)",
      },
    },
    rows: {
      style: {
        minHeight: "72px", // override the row height
        backgroundColor: "transparent", // Row default color (can be overridden)
        "&:nth-of-type(odd)": {
          backgroundColor: "transparent", // Striped row color
        },
      },
    },
    header: {
      style: {
        backgroundColor: "transparent",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "transparent", // Header background color
        color: "white", // Header text color
        display: "flex",
        alignItems: "center",
        justifyContent: "Center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        color: "white", // Cell text color
        display: "flex",
        alignItems: "center",
        justifyContent: "Center",
        fontSize: "14px",
      },
    },
    noData: {
      style: {
        backgroundColor: "transparent",
        color: "white",
        fontSize: 12,
      },
    },
    pagination: {
      style: {
        color: "white",
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: "transparent",
      },
      pageButtonsStyle: {
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "0px",
        cursor: "pointer",
        transition: "0.4s",
        color: "white",
        fill: "white",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "white",
          fill: "white",
        },
      },
    },
  };
  return (
    <>
      <Toaster />
      <div
        className="w-100"
      //  style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="d-flex  w-100">
          <Sidebar special={special} clearModalData={clearModalData} />
          <div className="width_content" style={{ height: "100%" }}>
            <DashboardHeader
              euronBalance={euronBalance}
              walletAddress={walletAddress}
              disConnectWallet={disConnectWallet}
            />
            <div
              className="pt-sm-2 pt-5 mt-lg-4 px-4 px-lg-5   pb-5  wrapper-dash"
              style={{
                // padding: "15px",
                flex: "1",
                backgroundColor: "#100500",
                color: "#ffffff",
                height: "87vh",
                overflowY: "scroll",
              }}
            >
              {/* <h2 className=" dash-header fs-20 mb-3">Wallet Overview</h2>
              <div className="wallet-card dash-gradient2 h-auto p-4">
                <div className=" py-2">
                  <p
                    className="fs-26 text-start mb-1 "
                    style={{ fontSize: 18 }}
                  >
                    LionPay Wallet
                  </p>
                  {walletAddress ? (
                    <div className="d-flex gap-2 align-items-center wallet-card-data">
                      <p className="mb-0 fs-14" style={{ fontSize: 14 }}>
                        Wallet Address
                      </p>
                      <p className="mb-0 fs-14" style={{ fontSize: 14 }}>
                        {walletAddress
                          ? walletAddress?.substring(0, 4) +
                            "...." +
                            walletAddress?.substring(walletAddress.length - 4)
                          : "NA"}
                        {walletAddress ? (
                          <CopyToClipboard
                            text={walletAddress}
                            onCopy={() => {
                              toast.success("Copied!");
                            }}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              className="ms-2"
                            >
                              <IoCopyOutline />
                            </span>
                          </CopyToClipboard>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex gap-2 align-items-center wallet-card-data">
                    <p className="mb-0 fs-14" style={{ fontSize: 14 }}>
                      Balance
                    </p>
                    <img
                      src="images/eurocoin.png"
                      style={{ width: 25, height: 25 }}
                      alt=""
                    />
                    <p className="mb-0 fs-14" style={{ fontSize: 14 }}>
                      {euronBalance
                        ? parseFloat(euronBalance).toFixed(4)
                        : "0.00"}{" "}
                      LionPay
                    </p>
                  </div>
                </div>
              </div> */}
              <Row className="gy-3">
                <Col xl={4} lg={6} md={6} sm={12}>
                  <div className="dashboard-card dash-gradient2 p-3 d-flex gap-3">
                    <div className="d-flex gap-3  align-items-center">
                      <div className="p-3 d-icon">
                        <img
                          src="images/dashboardicon2.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="mb-0" style={{ fontSize: 12 }}>
                          Total Tokens
                        </p>
                        <p
                          className="mb-0"
                          style={{ fontSize: 26, fontWeight: 600 }}
                        >
                          <div className="d-flex align-items-center">
                            {Loading
                              ?
                              <div className="custom-loader ms-4 mt-2"></div>
                              :
                              <>
                                <img
                                  className="me-1"
                                  src="images/eurocoin.png"
                                  style={{ width: 20, height: 20 }}
                                  alt=""
                                />
                                {parseFloat(dashboard).toFixed(2)}
                              </>
                            }
                          </div>

                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />

              <p className="fs-20" style={{ fontWeight: 500 }}>
                Transactions History
              </p>
              <DataTable
                columns={columns}
                data={purchaseHistory}
                customStyles={customStyles}
                pagination
                noDataComponent={
                  <div
                    style={{
                      padding: "10px",
                      fontSize: "14px",
                      color: "white",
                    }}
                    className="py-3"
                  >
                    No records found.
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
