import * as Yup from "yup";


export const registerValidator = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required("Confirm Password is required"),
});
export const influencerRegisterValidator = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required("Confirm Password is required"),
    // phoneNumber: Yup.string().required('Phone Number is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    socialMediaLink: Yup.string().url('Invalid URL').required('Social Media Link is required'),
    videoUrl: Yup.string().url('Invalid URL').required('Social Media Video URL is required'),
    other: Yup.string().required('Other details are required'),
    walletAddress: Yup.string().required('Wallet Address is required'),
    description: Yup.string().required('Audience Description is required')
});





export const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required.'),
    password: Yup.string()
        .required('Password is required.')

});