import React from 'react'
import "../styles/Dashboard.css";
import "../styles/Custom.css";
import { Link, useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import Header from '../directives/Header';
import config from '../corefiles/config';
import { PublicKey } from '@solana/web3.js';

const UserSignup = () => {
  const { id } = useParams()
  console.log(id);
  // const handleWalletConnect = async (e) => {
  //   try {
  //     // Check if Phantom Wallet is installed
  //     if (typeof window === "undefined" || !window.solana || !window.solana.isPhantom) {
  //       throw new Error("Phantom wallet not found. Please install Phantom Wallet.");
  //     }

  //     // Request connection to Phantom Wallet
  //     const response = await window.solana.connect();
  //     console.log("Connected to Phantom Wallet");

  //     // Get the wallet address (public key)
  //     const walletAddress = response.publicKey.toString();
  //     console.log("Wallet Address:", walletAddress);

  //     // Create a connection to the Solana blockchain (Mainnet)
  //     const connection = new Connection("https://api.mainnet-beta.solana.com", "confirmed");

  //     // Fetch the wallet balance
  //     const balanceInLamports = await connection.getBalance(new PublicKey(walletAddress));
  //     const balanceInSOL = balanceInLamports / 1e9; // Convert lamports to SOL
  //     console.log("Balance:", balanceInSOL, "SOL");

  //     return { walletAddress, balance: balanceInSOL };
  //   } catch (error) {
  //     console.error("Error fetching Phantom Wallet balance:", error);
  //     return null;
  //   }
  // }

  // Helper function for retry logic
  async function getBalanceWithRetry(connection, publicKey, retries = 3) {
    for (let i = 0; i < retries; i++) {
      try {
        const balance = await connection.getBalance(new PublicKey(publicKey));
        return balance;
      } catch (error) {
        if (i === retries - 1) {
          throw error;
        }
        console.warn("Retrying to fetch balance...");
        await new Promise((resolve) => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
      }
    }
  };
  return (
    <>
      <Header />

      {/* SECTION1  */}
      <div className="section1 px-sm-5 px-3">
        <div className="section1_container ">
          <Container className=" d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
            <div>

              <p className="login-header">Connect Wallet</p>
              <div className="d-flex flex-wrap justify-content-evenly gap-3 mb-3 align-items-center">
                <div className="d-flex flex-column align-items-center">
                  <div className="cw-icon">
                    <img src={`${config.baseUrl}images/cw1.png`} alt="" />
                  </div>
                  <p className="cw-p">Metamask</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="cw-icon">
                    <img src={`${config.baseUrl}images/cw2.png`} alt="" />
                  </div>
                  <p className="cw-p">Coinbase Wallet</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="cw-icon">
                    <img src={`${config.baseUrl}images/cw3.png`} alt="" />
                  </div>
                  <p className="cw-p">Trust Wallet</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="cw-icon">
                    <img src={`${config.baseUrl}images/cw4.png`} alt="" />
                  </div>
                  <p className="cw-p">Wallet Connect</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <div className="cw-icon">
                    <img src={`${config.baseUrl}images/cw5.png`} alt="" />
                  </div>
                  <p className="cw-p">Binance Wallet</p>
                </div>
              </div>
              <div
                className="d-flex justify-content-center w-100 "

              >
                <Link
                  className="btn01   py-2 "
                  style={{ width: 200, cursor: "pointer", textDecoration: "none" }}
                  // onClick={(e) => handleWalletConnect(e)}
                >
                  Connect
                </Link>
              </div>
            </div>
          </Container>
        </div>

      </div>
    </>
  )
}

export default UserSignup