import React, { useState } from "react";
import Header from "../../directives/Header";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../../styles/Footer.css";
import Footer from "../../directives/Footer";
import { insertContactAction } from "../../Action/action";
import toast, { Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidNumber } from "libphonenumber-js";
const ContactUs = () => {
  // Form state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    countryCode: "",
    message: "",
  });

  // Error state
  const [errors, setErrors] = useState({});

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear errors when user types
  };

  // Validate form
  const validateForm = () => {
    let newErrors = {};

    // First Name validation (min: 2, max: 30)
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    } else if (formData.firstName.length < 2) {
      newErrors.firstName = "First Name must be at least 2 characters";
    } else if (formData.firstName.length > 30) {
      newErrors.firstName = "First Name must not exceed 30 characters";
    }

    // Last Name validation (min: 2, max: 30)
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    } else if (formData.lastName.length < 2) {
      newErrors.lastName = "Last Name must be at least 2 characters";
    } else if (formData.lastName.length > 30) {
      newErrors.lastName = "Last Name must not exceed 30 characters";
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    // Phone Number validation
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone Number is required";
    } else if (!isValidNumber(`+${formData.phone}`, "TR")) {
      newErrors.phone = "Invalid phone number for selected country";
    }

    // Message validation
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    } else if (formData.message.length < 2) {
      newErrors.message = "Message must be at least 2 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const contactData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        countryCode: formData.countryCode, // Sending country code separately
        phone: formData.phone, // Sending phone separately
        message: formData.message,
      };

      const res = await insertContactAction(contactData);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  };

  return (
    <>
      <Toaster />

      <Header />
      <Container
        className="text-white d-flex justify-content-center align-items-center"
        style={{ zIndex: 0, minHeight: "80vh" }}
      >
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            className="d-flex flex-column justify-content-center"
          >
            <div className="text-center">
              <img
                src="images/lionlogo.svg"
                className="mb-3 contact-us-img"
                alt=""
              />
            </div>
            <h3 style={{ fontWeight: 600, color: "white" }}>Contact Us</h3>
            <p style={{ fontSize: 14 }}>
              We’d love to hear from you! Whether you have questions, feedback,
              or need assistance, our team is here to help. Reach out to us
              anytime, and we’ll get back to you as soon as possible. Let’s
              connect and make things happen!
            </p>
            <br />
          </Col>
          <Col lg={6} md={12} sm={12}>
            <form onSubmit={handleSubmit}>
              <Row className="contact-us-tab px-3 py-4">
                <h3>Get in Touch</h3>
                <p>You can Reach us Anytime</p>

                {/* First Name */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontSize: 14 }}>
                      First Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="py-2"
                      style={{ fontSize: 14 }}
                      placeholder="Enter your first name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                          handleChange(e);
                        }
                      }}
                    />

                    {errors.firstName && (
                      <div
                        className="text-danger mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.firstName}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                {/* Last Name */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontSize: 14 }}>
                      Last Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="py-2"
                      style={{ fontSize: 14 }}
                      placeholder="Enter your last name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[A-Za-z\s]*$/.test(value)) {
                          handleChange(e);
                        }
                      }}
                    />
                    {errors.lastName && (
                      <div
                        className="text-danger mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.lastName}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                {/* Email */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontSize: 14 }}>
                      Email <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      className="py-2"
                      style={{ fontSize: 14, height: 40 }}
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div
                        className="text-danger mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.email}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                {/* Phone Number */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontSize: 14 }}>
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <PhoneInput
                      className=" w-100 mno"
                      name="phoneNumber"
                      placeholder="Enter Mobile Number"
                      inputStyle={{
                        padding: "10px",
                        width: "90%",
                        marginLeft: "34px",
                        fontFamily: "Poppins",
                        height: 40,
                      }}
                      country={"de"}
                      value={formData.phone}
                      onChange={(value, country) => {
                        setFormData({
                          ...formData,
                          phone: value.replace(`+${country.dialCode}`, ""), // Store only the number
                          countryCode: `+${country.dialCode}`, // Store only the country code
                        });
                        setErrors({ ...errors, phone: "" });
                      }}
                      containerStyle={{ width: "100%" }}
                    />

                    {errors.phone && (
                      <div
                        className="text-danger mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.phone}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                {/* Message */}
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ fontSize: 14 }}>
                      Message <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter your message"
                      rows={3}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    {errors.message && (
                      <div
                        className="text-danger mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.message}
                      </div>
                    )}
                  </Form.Group>
                </Col>

                {/* Submit Button */}
                <Col
                  lg={12}
                  className="d-flex justify-content-sm-end justify-content-sm-center"
                >
                  <button
                    type="submit"
                    className="btn01 px-5 py-2"
                    style={{
                      borderRadius: 50,
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;
