import React, { useEffect, useState } from 'react'
import './App.css'
import Home from './pages/Home'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/Custom.css'
import { BrowserRouter, Route, Router, Routes, useLocation } from 'react-router'
import Dashboard from './pages/Dashboard';
import config from './corefiles/config';
import Notification from './pages/Notification';
import Wallet from './pages/Wallet';
import Profile from './pages/Profile';
import Ico from './pages/Ico';
import Referral from './pages/Referral';
import ChangePassword from './pages/ChangePassword';
import ResubmitForm from './pages/ResubmitForm';
import UserSignup from './pages/UserSignup';
import BuyNow from './pages/BuyNow';
import ProtectedRoute from './component/protectedRoute';
import ContactUs from './pages/FooterPages/ContactUs';
import Terms from "./pages/FooterPages/Terms"
import Privacy from "./pages/FooterPages/Privacy"
import Whitepaper from './pages/FooterPages/Whitepaper';
import UserGuid from './pages/FooterPages/UserGuid';

const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const [special, setSpecial] = useState(() => {
    const savedData = localStorage.getItem("special");
    return savedData ? JSON.parse(savedData) : null;
  });

  useEffect(() => {
    if (special) {
      localStorage.setItem("special", JSON.stringify(special));
    } else {
      localStorage.removeItem("special");
    }
  }, [special]);
  const clearModalData = () => {
    setSpecial(null);
  };

  return (
    <>

      <ScrollToTop />

      <Routes>
        <Route path={`${config.baseUrl}`} element={<Home />} />
        <Route path={`${config.baseUrl}:referralCode`} element={<Home />} />
        <Route path={`${config.baseUrl}dashboard`} element={<ProtectedRoute><Dashboard special={special} clearModalData={clearModalData} /></ProtectedRoute>} />
        <Route path={`${config.baseUrl}profile`} element={<ProtectedRoute><Profile special={special} clearModalData={clearModalData} /></ProtectedRoute>} />
        <Route path={`${config.baseUrl}wallet`} element={<ProtectedRoute><Wallet special={special} clearModalData={clearModalData} /></ProtectedRoute>} />
        <Route path={`${config.baseUrl}notification`} element={<ProtectedRoute><Notification special={special} clearModalData={clearModalData} /></ProtectedRoute>} />
        <Route path={`${config.baseUrl}ico`} element={<ProtectedRoute><Ico /></ProtectedRoute>} />
        <Route path={`${config.baseUrl}referral`} element={<ProtectedRoute><Referral special={special} clearModalData={clearModalData} /></ProtectedRoute>} />
        <Route path={`${config.baseUrl}changePassword`} element={<ProtectedRoute><ChangePassword special={special} clearModalData={clearModalData} /></ProtectedRoute>} />
        <Route path={`${config.baseUrl}resubmit`} element={<ResubmitForm special={special} clearModalData={clearModalData} />} />
        <Route path={`${config.baseUrl}userRefferal/:id`} element={<UserSignup special={special} clearModalData={clearModalData} />} />
        <Route path={`${config.baseUrl}terms-and-conditions`} element={<Terms special={special} clearModalData={clearModalData} />} />
        <Route path={`${config.baseUrl}privacy-policy`} element={<Privacy special={special} clearModalData={clearModalData} />} />
        <Route path={`${config.baseUrl}contact-us`} element={<ContactUs special={special} clearModalData={clearModalData} />} />
        <Route path={`${config.baseUrl}whitepaper`} element={<Whitepaper special={special} clearModalData={clearModalData} />} />
        <Route path={`${config.baseUrl}user-guid`} element={<UserGuid special={special} clearModalData={clearModalData} />} />

        <Route path={`${config.baseUrl}buyLionPay`} element={
          <ProtectedRoute>
            <BuyNow special={special} clearModalData={clearModalData} />
          </ProtectedRoute>
        } />
        {/* <Route path={`${config.baseUrl}contactus`} element={<ContactUs />} /> */}


      </Routes>
    </>
  )
}

export default App