import React, { useEffect, useState } from "react";
import Sidebar from "../component/Sidebar";
import { Col, Container, Row } from "react-bootstrap";
import DashboardHeader from "../component/DashboardHeader";
import "../styles/Dashboard.css";
import "../styles/Custom.css";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router";
import config from "../corefiles/config";
import {
  getDashboardStaticsAction,
  getProfileDetails,
  getTokenPurchaseHistoryAction,
} from "../Action/action";
import CopyToClipboard from "react-copy-to-clipboard";
import { IoCopyOutline } from "react-icons/io5";
import moment from "moment/moment";
import toast, { Toaster } from "react-hot-toast";
import { formatAmount } from "../formatedAmount";

const Dashboard = ({ special, clearModalData }) => {
  const [profileData, setProfileData] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getProfile();
    getDashboardStatics();
    getTokenPurchaseHistory();
  }, []);

  const getProfile = async () => {
    const res = await getProfileDetails();
    if (res.success) {
      setProfileData(res.data);
    }
  };

  const getDashboardStatics = async () => {
    const res = await getDashboardStaticsAction();
    if (res.success) {
      setLoading(false);
      setDashboard(res.data);
    } else {
      setLoading(false);
    }
  };

  const getTokenPurchaseHistory = async () => {
    const res = await getTokenPurchaseHistoryAction();
    if (res.success) {
      const limitedData = res.data.slice(0, 10);
      setPurchaseHistory(limitedData);
    }
  };
  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "LionPay Token",
      selector: (row) =>
        formatAmount(row.tokenAmount) + ` ${config.currencyName}`,
    },
    {
      name: "Crypto/Fiat ",
      selector: (row) =>
        row?.paymentType == "SOLANA"
          ? row?.equivalentAmount + " SOL"
          : "$" + row?.equivalentAmount,
    },
    {
      name: "Wallet Address",
      selector: (row) => {
        const walletAddress = row.walletAddress; // Access the wallet address from the row
        return (
          <>
            {walletAddress
              ? walletAddress?.substring(0, 4) +
              "...." +
              walletAddress?.substring(walletAddress.length - 4)
              : "NA"}
            {walletAddress ? (
              <CopyToClipboard
                text={walletAddress}
                onCopy={() => {
                  toast.success("Copied!");
                }}
              >
                <span style={{ cursor: "pointer" }} className="ms-2">
                  <IoCopyOutline />
                </span>
              </CopyToClipboard>
            ) : (
              ""
            )}
          </>
        );
      },
      // sortable: true,
      center: true,
    },
    {
      name: "Transaction Hash",
      selector: (row) => {
        const trxHash = row.trxHash; // Access the wallet address from the row
        return (
          <>
            {row.paymentType == "Paypal" ? (
              trxHash
            ) : trxHash ? (
              <a
                href={`${config.solanaTrxUrl1}${trxHash}${config.solanaTrxUrl2}`}
                target="_blank"
                className="text-color"
              >
                {trxHash?.substring(0, 4) +
                  "...." +
                  trxHash?.substring(trxHash.length - 4)}
              </a>
            ) : (
              "NA"
            )}
          </>
        );
      },
      center: true,
    },
    {
      name: "Token Transaction Hash",
      selector: (row) => {
        const tokenTransferHash = row.tokenTransferHash; // Access the wallet address from the row
        return (
          <>
            {row.paymentType == "Paypal" ? (
              tokenTransferHash
            ) : tokenTransferHash ? (
              <a
                href={`${config.solanaTrxUrl1}${tokenTransferHash}${config.solanaTrxUrl2}`}
                target="_blank"
                className="text-color"
              >
                {tokenTransferHash?.substring(0, 4) +
                  "...." +
                  tokenTransferHash?.substring(tokenTransferHash.length - 4)}
              </a>
            ) : (
              "NA"
            )}
          </>
        );
      },
      center: true,
    },
    {
      name: "Payment Type",
      selector: (row) => row?.paymentType,
    },
    {
      name: "Purchased Date",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "Status",
      selector: (row) => {
        return row.status == 0 ? (
          <span className="status-label pending">Pending</span>
        ) : row.status == 1 ? (
          <span className="status-label success">Success</span>
        ) : (
          <span className="status-label reject">Rejected</span>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`${config.baseUrl}resubmit`);
  };

  const customStyles = {
    table: {
      style: {
        borderRadius: 16,
        background:
          "linear-gradient(90deg, rgba(232, 23, 23, 0.10) -15.32%, rgba(229, 156, 37, 0.10) 100%)",
      },
    },
    rows: {
      style: {
        minHeight: "72px",
        backgroundColor: "transparent",
        "&:nth-of-type(odd)": {
          backgroundColor: "transparent",
        },
      },
    },
    header: {
      style: {
        backgroundColor: "transparent",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "transparent",
        color: "white",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "Center",
        fontSize: "12px",
      },
    },
    pagination: {
      style: {
        color: "white",
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: "transparent",
      },
      pageButtonsStyle: {
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "0px",
        cursor: "pointer",
        transition: "0.4s",
        color: "white",
        fill: "white",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "white",
          fill: "white",
        },
      },
    },
    noData: {
      style: {
        backgroundColor: "transparent",
        color: "white",
        fontSize: 12,
      },
    },
  };
  return (
    <>
      <Toaster />
      <div className="w-100">
        <div className="d-flex  w-100">
          <div>
            <Sidebar special={special} clearModalData={clearModalData} />
          </div>

          <div className="width_content" style={{ height: "100%" }}>
            <DashboardHeader />
            <div
              className="pt-sm-2 pt-5  mt-lg-4 px-4 px-lg-5 pb-5 mb-5  wrapper-dash "
              style={{
                flex: "1",
                backgroundColor: "#100500",
                color: "#ffffff",
                // maxHeight: "87vh",
                overflowY: "scroll",
              }}
            >
              {profileData.isInfluencer == true &&
                profileData.adminApproval === "1" ? (
                <Row
                  className="text-white justify-content-center gap-2 align-items-center influencer py-md-2 py-4"
                  style={{
                    borderColor: "green",
                    backgroundColor: "rgba(0, 223, 33, 0.03)",
                    color: "green",
                  }}
                >
                  <h6 className="mb-0 text-center text-success">
                    <b> Your Application is Approved.</b>
                  </h6>
                </Row>

              ) : profileData.isInfluencer == true &&
                profileData.adminApproval === "2" ? (
                <Row
                  className="text-white justify-content-center gap-2 align-items-center influencer py-md-2 py-4"
                  style={{
                    borderColor: "red",
                    backgroundColor: "rgba(255, 195, 0, 0.03)",
                    color: "yellow",
                  }}
                >
                  <h6 className="mb-0 text-center text-danger">
                    Your Application is Rejected.
                  </h6>
                  <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={7} xl={4} className="text-center">
                      <button
                        className="btn btn-danger"
                        onClick={handleRedirect}
                        style={{
                          padding: "8px 16px",
                          borderRadius: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        Resubmit
                      </button>
                    </Col>
                  </Row>
                </Row>
              ) : profileData.isInfluencer == true &&
                profileData.adminApproval === "0" ? (
                <Row
                  className="text-white justify-content-center gap-2 align-items-center influencer py-md-2 py-4"
                  style={{
                    borderColor: "yellow",
                    backgroundColor: "rgba(255, 195, 0, 0.03)",
                  }}
                >
                  <h6 className="mb-0 text-center text-warning">
                    Your Application is Pending.
                  </h6>
                </Row>
              ) : (
                <>
                  {/* <Row
                    className="text-white justify-content-center gap-2 align-items-center influencer py-md-2 py-4 position-relative"
                    style={{
                      borderColor: "red",
                      backgroundColor: "rgba(255, 195, 0, 0.03)",
                      color: "yellow",
                    }}
                  >
                    <p className="mb-0 text-center fs-12 text-danger">
                      Your Application is Rejected
                    </p>
                    <button
                      className="btn btn-danger position-absolute"
                      onClick={handleRedirect}
                      style={{
                        padding: "3px 30px",
                        borderRadius: "50px",
                        fontWeight: "normal",
                        width: "max-content",
                        right: 10,
                        fontSize: 12,
                      }}
                    >
                      Resubmit
                    </button>
                  </Row> */}
                </>
              )}

              <br />

              <h2 className=" dash-header">Hello, {profileData.firstName}</h2>
              <Row className="gy-3">
                <Col xl={4} lg={6} md={6} sm={12}>
                  <div className="dashboard-card dash-gradient2 p-3 d-flex gap-3">
                    <div className="d-flex gap-3  align-items-center">
                      <div className="p-3 d-icon">
                        <img
                          src="images/dashboardicon2.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="mb-0" style={{ fontSize: 12 }}>
                          Total Tokens
                        </p>

                        {loading == true ? (
                          <div className="custom-loader ms-4 mt-2"></div>
                        ) : (
                          <>
                            <p
                              className="mb-0"
                              style={{ fontSize: 26, fontWeight: 600 }}
                            >
                              <img
                                src="images/eurocoin.png"
                                style={{ width: 20, height: 20 }}
                                alt=""
                              />
                              &nbsp;
                              {parseFloat(dashboard).toFixed(2)}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={4} lg={6} md={6} sm={12} style={{ display: "none" }}>
                  <div className="dashboard-card dash-gradient2 p-3 d-flex gap-3 ">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="p-3 d-icon">
                        <img
                          src="images/logoLionIcon.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="mb-0" style={{ fontSize: 12 }}>
                          Total Withdrawal
                        </p>
                        <p
                          className="mb-0"
                          style={{ fontSize: 26, fontWeight: 600 }}
                        >
                          45 <span style={{ fontSize: 16 }}>LionPay</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <p className="fs-20" style={{ fontWeight: 500, float: "left" }}>
                Latest Transactions
              </p>
              {purchaseHistory.length > 0 ? (
                <p style={{ fontWeight: 500, float: "right" }}>
                  <a
                    href={`${config.baseUrl}wallet`}
                    className="text-color fs-20"
                  >
                    View All
                  </a>
                </p>
              ) : (
                ""
              )}

              <DataTable
                columns={columns}
                data={purchaseHistory}
                customStyles={customStyles}
                pagination={false}
                noDataComponent={
                  <div
                    style={{
                      padding: "10px",
                      fontSize: "14px",
                      color: "white",
                    }}
                    className="py-3"
                  >
                    There are no records to display.
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
