import React, { useState } from "react";
import "../styles/Custom.css";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import OtpVerification from "./OtpVerification";
import ResetPassword from "./ResetPassword";
import PasswordResetSuccess from "./PasswordResetSuccess";

const LoginHome = ({modalType,setModalType}) => {
  const [step, setStep] = useState(0);

  const handleClose = () => setStep(0);
  const handleNext = () => setStep((prevStep) => Math.min(prevStep + 1, 5));
  const handleBack = () => setStep((prevStep) => Math.max(prevStep - 1, 1));
  const handleBackFirst = () => setStep((prevStep) => Math.max(prevStep - 3, 1));


  const renderStepContent = () => {
    // alert('1')
    switch (step) {
      case 1:
        return (
          <Login modalType={modalType} setModalType={setModalType} handleNext={handleNext} step={step} handleBack={handleBack} />
        );
      case 2:
        return (
          <ForgotPassword
            handleNext={handleNext}
            step={step}
            handleBack={handleBack}
          />
        );
      case 3:
        return (
          <OtpVerification
            handleNext={handleNext}
            step={step}
            handleBack={handleBack}
          />
        );
      case 4:
        return (
          <ResetPassword
            handleNext={handleNext}
            step={step}
            handleBack={handleBackFirst}
          />
        );
      case 5:
        return (
          <PasswordResetSuccess
            handleNext={handleNext}
            handleClose={handleClose}
          />
        );
      default:
        return (
          <Login handleNext={handleNext} step={step} handleBack={handleBack} />
        );
    }
  };
  return <>{renderStepContent()}</>;
};

export default LoginHome;
