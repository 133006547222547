import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { forgetPassword } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";

const ForgotPassword = ({ handleNext, step, handleBack }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClick = async (event) => {
    event.preventDefault();
    if (!email) {
      setError("Please enter your email.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      let data = email.toLowerCase()
      let res = await forgetPassword({ email:data });
      setLoading(false);
      if (res.success) {
        localStorage.setItem("Email", email);
        toast.success(res.msg);
        setTimeout(() => {
          handleNext();
        }, 500);
      } else {
        toast.error(res.msg);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Failed to send OTP.");
      setError("Failed to send reset instructions. Please try again.");
    }
  };

  return (
    <>
      <div className="login">
        <p className="login-header">Forgot Password</p>
        <Form className="text-white">
          <div>
            <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
              <Form.Label>
                Enter Your Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                className="py-2"
                placeholder="name@example.com"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </div>
          {error && (
            <div
              style={{ fontSize: 14 }}
              className="text-danger text-center mb-3"
            >
              {error}
            </div>
          )}
          {step < 5 ? (
            <div className="d-flex justify-content-center w-100">
              <button
                type="submit"
                className="btn01  py-2 mb-3"
                style={{ cursor: "pointer", maxWidth: 280, width: "100%" }}
                onClick={handleClick}
              >
                {loading ? "Sending..." : "Send OTP"}
              </button>
            </div>
          ) : (
            <Button variant="success" onClick={handleBack}>
              Finish
            </Button>
          )}
        </Form>
      </div>
    </>
  );
};

export default ForgotPassword;
