import React, { useEffect, useState } from "react";
import Header from "../directives/Header";
import "../styles/Home.css";
import { Col, Container, Row, Modal, Form, InputGroup } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { color } from "chart.js/helpers";
import Footer from "../directives/Footer";
import config from "../corefiles/config";
import { Country, State, City } from "country-state-city";
import {
  getAllSettingAction,
  getUserSession,
  registerUser,
  sendOtpAction,
  UserLogin,
} from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import { Formik } from "formik";
import * as Yup from "yup";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import Cookies from "js-cookie";
import { PublicKey } from "@solana/web3.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidNumber } from "libphonenumber-js";
import { Slide, Zoom } from "react-awesome-reveal";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Function to calculate time left
  function calculateTimeLeft() {
    const difference = new Date(targetDate).getTime() - new Date().getTime();
    if (difference <= 0) return null;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }
  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (!newTimeLeft) {
        clearInterval(timer); // Stop timer when countdown reaches zero
      }
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [targetDate]);

  if (!timeLeft) {
    return <h2>Time's up!</h2>;
  }

  return (
    <div
      className="d-flex main-timer 
                px-2 px-sm-4 py-2 mb-3"
    >
      <div className="timer-div px-2 px-sm-4">
        <p className="timer-data">{timeLeft.days}</p>
        <p className="timer-label">Days</p>
      </div>
      <div className="timer-div px-2 px-sm-4">
        <p className="timer-data">{timeLeft.hours}</p>
        <p className="timer-label">Hours</p>
      </div>
      <div className="timer-div px-2 px-sm-4">
        <p className="timer-data">{timeLeft.minutes}</p>
        <p className="timer-label">Minutes</p>
      </div>
      <div className="timer-div-last px-2 px-sm-4">
        <p className="timer-data">{timeLeft.seconds}</p>
        <p className="timer-label">Seconds</p>
      </div>
    </div>
  );
};

const Home = ({ setSpecial }) => {

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [influencerChecked, setInfluencerChecked] = useState(false);
  const [error, setError] = useState("");
  const [isCheckedInflu, setIsCheckedInflu] = useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const [isReferralCode, setIsReferralCode] = useState("");
  let { referralCode } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [sendOtpStatus, setSendOtpStatus] = useState(false);
  const [otpSendLoader, setOtpSendLoader] = useState(false);
  const [signUpLoader, setSignUpLoader] = useState(false);
  let loginData = Cookies.get("loginSuccessEuron");

  const [timesShow, setTimesShow] = useState(false);
  const location = useLocation();

  const [allSetting, setAllSetting] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  const [conversionRate, setConversionRate] = useState({
    usdt: null,
    sol: null,
  });

  const [timeLeft, setTimeLeft] = useState(""); // 2 minutes (120 seconds)



  useEffect(() => {
    if (timeLeft === 0) {
      setTimesShow(false);
    } // Stop when timer reaches zero

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer); // Cleanup function
  }, [timeLeft]);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("signup") === "true") {
      setShow(true);
    }
  }, [location]);

  useEffect(() => {
    setCountries(Country.getAllCountries());
    getAllSetting();
  }, []);

  useEffect(() => {
    if (referralCode) {
      setIsReferralCode(referralCode);
      setShow(true);
      setSendOtpStatus(false);
    }
  }, [referralCode]);

  useEffect(() => {
    const handleOpenSignupModal = () => setShow(true);
    window.addEventListener("openSignupModal", handleOpenSignupModal);

    return () => {
      window.removeEventListener("openSignupModal", handleOpenSignupModal);
    };
  }, []);

  // const getAllSetting = async () => {
  //   const res = await getAllSettingAction();
  //   if (res.success) {
  //     setAllSetting(res.data);
  //   }
  // };

  const [activeCard, setActiveCard] = useState(0);
  const targetDate = allSetting.preSaleEndIn;
  const handleCardClick = (index) => {
    setActiveCard(index); // Set the clicked card as active
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    otp: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    socialMediaLink: "",
    modeOfCommunication: "whatsApp",
    modeOfCommunicationData: "",
    videoUrl: "",
    walletAddress: "",
    description: "",
    other: "",
    password: "",
    confirmPassword: "",
  });

  const data = {
    labels: [
      "Referral & Marketing",
      " Liquidity Pool",
      "Treasury",
      "Team and Development",
      "Token Distribution",
    ],
    datasets: [
      {
        label: "Token Distribution",
        data: [40, 15, 25, 15, 30],
        backgroundColor: [
          "rgb(85, 112, 241)",
          "rgb(234, 85, 54)",
          "rgb(254, 194, 64)",
          "rgb(133, 246, 255)",
          "rgb(151, 165, 235)",
          "rgb(158, 235, 151)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const roadmap = [
    {
      id: 1,
      title: "Q2–Q4 2025",
      subtitle: " Pre-Sale & Community-Building",
      step1: "Launch of referral system",
      step2: "Token introduction & start of the pre-sale",
      step3: "Strategic influencer partnerships",
    },
    {
      id: 2,
      title: "Q1–Q3 2026",
      subtitle: "Product Development",
      step1: "Completion of wallet & payment infrastructure",
      step2: "Beta launch of LionPay for early adopters",
      step3: "Rollout of the merchant payment gateway",
    },
    {
      id: 3,
      title: "Q4 2026 – Q1 2027",
      subtitle: "Full Launch & Global Expansion",
      step1: "Full platform deployment",
      step2: "Expansion into international markets",
      step3: "Introduction of new financial services",
    },
    {
      id: 4,
      title: "Q2–Q4 2027",
      subtitle: "Enhancements & Scaling",
      step1: "Integration of additional fiat & crypto services",
      step2: "Expansion of IBAN & credit card solutions",
      step3: "Stronger global reach & regulatory partnerships",
    },
    {
      id: 5,
      title: "2028+",
      subtitle: "Long-Term Vision",
      step1:
        "Potential acquisition of banking licenses or partnerships with neobanks",
      step2: "Decentralized finance (DeFi) services for lending & staking",
      step3: "Additional cashback & rewards programs for high-volume users",
    },
  ];
  const options = {
    responsive: true,

    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top", // Position of the legend (top, left, bottom, right)
        labels: {
          font: {
            size: 14, // Font size
            weight: "bold", // Font weight
            family: "Arial", // Font family
            color: "white",
          },
          color: "white", // Font color
          padding: 20, // Padding between labels
          boxWidth: 10, // Width of the color box
          boxHeight: 10, // Height of the color box
          borderRadius: 10,
          marginBottom: 50,
        },
      },

      tooltip: {
        bodyFont: {
          size: 14, // Font size for tooltip text
          weight: "normal",
          family: "Arial",
        },
        titleFont: {
          size: 16, // Font size for tooltip title
          weight: "bold",
          family: "Arial",
        },
        backgroundColor: "rgba(0,0,0,0.8)", // Background color of tooltip
        titleColor: "#fff", // Color of the tooltip title
        bodyColor: "#fff", // Color of the tooltip body text
      },
    },
    cutout: "70%",
  };

  const validationSchema = (influencerChecked) =>
    Yup.object().shape({
      firstName: Yup.string()
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters")
      // .matches(/^(?! )[A-Za-z]+( [A-Za-z]+)?(?<! )$/, "Only alphabets are allowed, no leading/trailing spaces, and only one space between words")
      .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Must be at least 2 characters")
        .max(50, "Must be at most 50 characters")
        // .matches(/^(?! )[A-Za-z]+( [A-Za-z]+)?(?<! )$/, "Only alphabets are allowed, no leading/trailing spaces, and only one space between words")
        .required("Last Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      selectedCountry: influencerChecked
        ? Yup.string().required("Country is required")
        : Yup.string(),

      // socialMediaLink: influencerChecked
      //   ? Yup.string().url("Invalid URL").required("Field is required")
      //   : Yup.string().url("Invalid URL"),

      modeOfCommunication: influencerChecked
        ? Yup.string().required("modeOfCommunication is required")
        : Yup.string(),

      modeOfCommunicationData: influencerChecked
        ? Yup.string().required("Field is required")
        : Yup.string(),

      description: influencerChecked
        ? Yup.string()
          .min(2, "Must be at least 2 characters")
          .max(400, "Must be at most 400 characters")
          .required("Description is required")
        : "",

      termsAccepted: Yup.boolean().oneOf(
        [true],
        "You must agree to the Terms & Conditions"
      ),
    });

  const handleCountryChange = (e, setFieldValue) => {
    const countryCode = e.target.value;
    setFieldValue("selectedCountry", countryCode); // Update Formik value
    setSelectedCountry(countryCode);
    setStates(State.getStatesOfCountry(countryCode));
    setCities([]);
    setSelectedState("");
    setSelectedCity("");
  };

  const handleStateChange = (e, setFieldValue, values) => {
    const stateCode = e.target.value;
    setFieldValue("state", stateCode); // Update Formik state
    setSelectedState(stateCode);
    setCities(City.getCitiesOfState(values.selectedCountry, stateCode));
    setSelectedCity("");
  };

  const handleCityChange = (e, setFieldValue) => {
    const cityName = e.target.value;
    setFieldValue("city", cityName); // Update Formik state
    setSelectedCity(cityName);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked); // Update state based on checkbox status
  };
  const handleInfluencerChange = (e) => {
    setInfluencerChecked(e.target.checked); // Update state based on checkbox status
  };

  const handleClose = () => {
    setShow(false);
    setSendOtpStatus(false);
    setIsCheckedInflu(false);
    setInfluencerChecked(false);
    setFormData("");
  };
  const handleShow = () => {
    setShow(true);
    setSendOtpStatus(false);
    setIsCheckedInflu(true);
    setInfluencerChecked(true);
  };
  const handleSubmit = async (values) => {
    if (formData.phone) {
      if (!isValidNumber(`+${formData.phone}`, "TR")) {
        toast.error("Invalid phone number for selected country");
        return;
      }
    }

    if (
      values.modeOfCommunication == "WhatsApp" &&
      values.modeOfCommunicationData
    ) {
      if (!isValidNumber(`+${values.modeOfCommunicationData}`, "TR")) {
        toast.error("Invalid WhatsApp Number for selected country");
        return;
      }
    }
    if (influencerChecked && values.walletAddress) {
      setIsSubmitClick(true);
      try {
        new PublicKey(values.walletAddress);
      } catch (error) {
        toast.error("Invalid Wallet Address");
        return;
      }
    }
    if (values.password !== values.confirmPassword) {
      return setError("Passwords do not match.");
    }

    setOtpSendLoader(true);
    setError("");

    try {
      const res = await sendOtpAction({
        email: values.email.toLowerCase(),
        referredBy: influencerChecked ? "" : isReferralCode,
        ...(influencerChecked && { walletAddress: values.walletAddress }), // Only include if influencerChecked is true
      });

      if (res.success) {
        toast.success(res.msg);
        setOtpSendLoader(false);
        setSendOtpStatus(true);
        setTimesShow(true);
        setTimeLeft(120);
      } else {
        toast.error(res.msg);
        setOtpSendLoader(false);
        setSendOtpStatus(false);
      }
      // setShow(false);
    } catch (error) {
      setOtpSendLoader(false);

      setSendOtpStatus(false);
    }
  };

  const redirectTo = () => {
    if (!Cookies.get("loginSuccessEuron")) {
      window.dispatchEvent(new CustomEvent("openLoginModal"));
      setShow(false);
    } else {
      window.location.href = `${config.baseUrl}buyLionPay`;
    }
  };

  const redirectToRefer = () => {
    if (!Cookies.get("loginSuccessEuron")) {
      window.dispatchEvent(new CustomEvent("openLoginModal"));
      setShow(false);
    } else {
      window.location.href = `${config.baseUrl}referral`;
    }
  };

  const fetchSessionId = async (userId) => {
    try {
      let res = await getUserSession({ userId: userId });
      if (res.success) {
        Cookies.set("sessionId", JSON.stringify(res.data[0]));
      }
    } catch (error) {
      toast.error("error", error.message);
    }
  };

  const isEmailVerify = async (values) => {
    toast.dismiss();
    setSignUpLoader(true);

    if (!values.otp) {
      toast.error("OTP is required");
      setSignUpLoader(false);
      return;
    }

    try {
      let data = {
        isInfluencer: influencerChecked,
        firstName:values.firstName.trim(), 
        lastName: values.lastName.trim(),
        email: values.email.toLowerCase(),
        otp: values.otp,
        password: values.password,
        confirmPassword: values.confirmPassword,
        referredBy: isReferralCode,
      };

      if (influencerChecked) {
        Object.assign(data, {
          modeOfCommunicationData: values.modeOfCommunicationData,
          phoneNumber: formData.phone,
          modeOfCommunication: values.modeOfCommunication,
          country: values.selectedCountry,
          state: values.state,
          city: values.city,
          socialMediaLink: values.socialMediaLink,
          videoUrl: values.videoUrl,
          walletAddress: values.walletAddress,
          description: values.description,
          other: values.other,
        });
      }

      const registerRes = await registerUser(data);

      if (registerRes.success) {
        toast.success(
          "You have successfully completed your registration with us."
        );
        setTimeout(() => {
              window.location.href = `${config.baseUrl}dashboard`;
            }, 2000);
        // const loginRes = await UserLogin({
        //   email: values.email.toLowerCase(),
        //   password: values.password,
        // });

        // if (loginRes.success) {
        //   Cookies.set("loginSuccessEuron", loginRes.data.token);
        //   fetchSessionId(loginRes.data.userId);

        //   setTimeout(() => {
        //     window.location.href = `${config.baseUrl}dashboard`;
        //   }, 2000);
        // } else {
        //   toast.error(loginRes.msg);
        // }
      } else {
        toast.error(registerRes.msg);
        setSignUpLoader(false);
        return;
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setSignUpLoader(false);
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);



  useEffect(() => {
    getAllSetting();
    setInterval(() => {
      getAllSetting();
    }, 30000);
  }, []);

  const getAllSetting = async () => {
    const res = await getAllSettingAction();
    if (res.success) {
      setAllSetting(res.data);
      setLoadingData(true);
    } else {
      setLoadingData(true);
    }
  };


  return (
    <>
      <Toaster />
      <Header />

      {/* SECTION1  */}
      <div
        className="section1 px-sm-5 px-3  position-relative overflow-hidden"
        id="s1header"
      >
        {/* <div
          className="motion-tab motion-tab1 rotate-center motag"
          style={{ zIndex: 0 }}
        >
          <img src={`${config.baseUrl}images/beicon02.svg`} alt="" />
        </div>
        <div className="motion-tab motion-tab3 rotate-center2 motag">
          <img src={`${config.baseUrl}images/beicon02.svg`} alt="" />
        </div>
        <div className="motion-tab motion-tab5 rotate-center2 motag">
          <img src={`${config.baseUrl}images/beicon02.svg`} alt="" />
        </div>
        <div className="motion-tab motion-tab6 rotate-center motag">
          <img src={`${config.baseUrl}images/beicon02.svg`} alt="" />
        </div>
        <div className="motion-tab motion-tab7 rotate-center2 motag">
          <img src={`${config.baseUrl}images/beicon02.svg`} alt="" />
        </div> */}

        <div className="section1_container " style={{ zIndex: 1 }}>
          <Container className="">
            {loginData ? (
              ""
            ) : (
              <Row className="text-white  justify-content-lg-between justify-content-center  gap-2 align-items-center influencer py-md-2 py-4">
                <Col lg={3} md={4} className="px-4 mt-0">
                  <p className="fw-800 mb-0 text-center">
                    Are You an Influencer or Affiliate?
                  </p>
                </Col>
                <Col lg={5} md={4} className="mt-0">
                  <p className="mb-0 text-background text-center">
                    Get exclusive referral benefits tailored just for you!
                  </p>
                </Col>
                <Col lg={3} md={4} className="px-2 mt-0">
                  <div
                    className="btn02 px-4 py-2 text-center"
                    onClick={handleShow}
                    style={{ cursor: "pointer" }}
                  >
                    Apply as an Influencer or Affiliate
                  </div>
                </Col>
              </Row>
            )}
            <br />
            {/* WELCOME  */}
            <div className="d-flex flex-column justify-content-center align-items-center section1-header h-100">
              <p
                className="text-background s1txt"
                style={{ letterSpacing: "6px" }}
              >
                <img src={`${config.baseUrl}images/Union.svg`} alt="" /> WELCOME
                TO LIONPAY{" "}
                <img src={`${config.baseUrl}images/Union.svg`} alt="" />
              </p>
              <Zoom>
                <h1 className="header-01">Discover the Next Big Opportunity</h1>
                <h2 className="header-02 text-center">Our Token is Live</h2>
              </Zoom>
              <p className="subheader">
                A next-generation crypto token revolutionizing digital finance
                with seamless crypto and fiat banking integration. <br />
                <span>
                  {/* <a
                    href={`${config.baseUrl}whitepaper`}
                    target="_blank"
                    className="text-color"
                  >
                    Read White Paper
                  </a> */}
                </span>
              </p>

              <div className="position-relative">
                <img alt="" src="images/eurocoin.png" className="img-fluid" width={150} />
                <div className="home_page_pricetag">
                  <div>
                    <div className="price_tag_home">
                      <p
                        className=" mb-0 p-1 d-flex justify-content-center align-items-center"

                      >
                        1 LPAY  = {" "}
                        {loadingData
                          ? parseFloat(
                            allSetting?.usdprice / allSetting?.solanaPriceUsd
                          ).toFixed(6) + " SOL"
                          : "Loading..."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className=" d-flex flex-column justify-content-center align-items-center gap-1">
                <p className="header-text fs-26">PRESALE ENDS IN</p>
                {targetDate ? (
                  <CountdownTimer targetDate={targetDate} />
                ) : (
                  "Loading..."
                )}

                <div className="d-flex gap-3">
                  <Link
                    className="btn01  py-2 "
                    style={{
                      width: 140,
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={() => redirectTo()}
                  >
                    Buy Token
                  </Link>
                  {/* <div
                    className=" py-2 learn-more d-flex justify-content-center align-items-center text-white  "
                    style={{
                      width: "fit-content",
                      minHeight: 50,
                      borderRadius: 50,
                    }}
                  >
                    Learn More
                  </div> */}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      {/* SECTION2  */}
      <div className="section2 pt-5 px-3 px-md-0 " id="s2refer">
        <Container className="s2container">
          <Row className="gy-4">
            <Col lg={6} md={6} sm={12}>
              <div className="d-flex flex-column justify-content-center h-100 gap-3 py-2">
                <Slide>
                  <div className="mb-0 section2-text">
                    Refer LionPay and Earn Instantly{" "}
                    <h2 className="s2header-02 m-0">
                      {allSetting?.userRefferalPercentage}% commission
                    </h2>
                  </div>
                </Slide>

                <div
                  className="btn01 px-5 py-2 "
                  style={{ width: "fit-content", cursor: "pointer" }}
                  onClick={() => redirectToRefer()}
                >
                  Refer Now
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="d-flex align-items-end">
              <div>
                <Zoom>
                  <img
                    src={`${config.baseUrl}images/section02.png`}
                    className="img-fluid"
                    alt=""
                  />
                </Zoom>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* SECTION 3  */}

      <Container className="py-5 px-4 px-md-0 " id="s3feature">
        <div className="text-white">
          <Slide>
            <p className="text-background mb-2 fw-800">WHAT THEY SAY</p>
          </Slide>

          <Slide>
            <h2 className="section3-header mb-2">FEATURES</h2>
          </Slide>
          <p className="text-white fs-16">
            LionPay is built on the Solana blockchain, known for its high-speed
            transactions, low fees, and scalability.
          </p>
          <Zoom>
            <h4> Key Features:</h4>
            <ul>
              <li className=" fs-16 mb-2">
                Decentralized & instant transactions with minimal fees
              </li>
              <li className=" fs-16 mb-2">
                Integration with leading crypto wallets for maximum
                compatibility
              </li>
              <li className=" fs-16 mb-2">
                Smart contract security backed by third-party audits
              </li>
              <li className=" fs-16 mb-2">
                High transaction capacity (65,000 TPS) – perfect for merchants &
                cashback programs
              </li>
            </ul>
          </Zoom>
        </div>
        <div className="pt-4">
          <Slide>
            <p className="text-background mb-1 fw-800">
              Why Choose Our ICO Platform?
            </p>
            <h2 className="section3-header mb-2">
              Easy, Simple and Affordable
            </h2>
          </Slide>

          <Row className="gx-2 gy-2 mt-3 justify-content-between">
            <Col lg={4} md={4} sm={6}>
              <div
                className="s3card overflow-hidden"
                style={{
                  maxHeight: "385px",
                  objectFit: "cover",
                  width: "100%",
                }}
              >
                <Zoom>
                  <img
                    src={`${config.baseUrl}images/Newfolder/1st.jpg`}
                    className="img-fluid three_h_img"
                    alt=""
                  />
                </Zoom>
              </div>
              <div className="mt-3">
                <Zoom>
                  <p className="s3card-header">Web3 Payment Solutions</p>
                  <ul className="text-white fs-14" style={{ paddingLeft: 20 }}>
                    <li>
                      Solana-Powered Transactions – Fast, secure, and low-cost
                      payments
                    </li>
                    <li>
                      Decentralized & Instant – No intermediaries, real-time
                      fiat-to-crypto conversions
                    </li>
                    <li>
                      Merchant-Branded Loyalty Cards – Cashback rewards &
                      seamless Web3 integration
                    </li>
                  </ul>
                </Zoom>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6}>
              <div
                className="s3card overflow-hidden"
                style={{
                  maxHeight: "385px",
                  objectFit: "cover",
                  width: "100%",
                }}
              >
                <Zoom>
                  <img
                    src={`${config.baseUrl}images/Newfolder/2nd.jpg`}
                    className="img-fluid three_h_img"
                    alt=""
                  />
                </Zoom>
              </div>
              <div className="mt-3">
                <Zoom>
                  <p className="s3card-header">Commission Up to 40%</p>
                  <ul className="text-white fs-14" style={{ paddingLeft: 20 }}>
                    <li>High Referral Rewards – Earn commission in Solana</li>
                    <li>Instant Payouts in LionPay Tokens</li>
                    <li>
                      Ideal for Influencers & Affiliates – Monetize your network
                      effortlessly
                    </li>
                  </ul>
                </Zoom>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6}>
              <div
                className="s3card overflow-hidden"
                style={{
                  maxHeight: "385px",
                  objectFit: "cover",
                  width: "100%",
                }}
              >
                <Zoom>
                  <img
                    src={`${config.baseUrl}images/card03.png`}
                    className="img-fluid three_h_img w-100"
                    alt=""
                  />
                </Zoom>
              </div>
              <div className="mt-3">
                <Zoom>
                  <p className="s3card-header">
                    Simple Crypto Buy with Moonpay
                  </p>
                  <ul className="text-white fs-14" style={{ paddingLeft: 20 }}>
                    <li>
                      Easy & Fast: Buy crypto instantly with just a few clicks.
                    </li>
                    <li>
                      Multiple Payment Options: Use credit cards, bank
                      transfers, and more.
                    </li>
                    <li>
                      Safe & Reliable: Top-tier security ensures a protected
                      transaction.
                    </li>
                  </ul>
                </Zoom>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* SECTION 4  */}

      <div className="section4 py-5 px-4 px-md-0 " id="s4tokenomics">
        <Container className="section4-background px-md-5 px-3 py-sm-5 py-4">
          <Row>
            <Col lg={6} md={12}>
              <div className="text-white">
                <Slide>
                  <p className="text-background mb-1 fw-800">
                    Why Choose Our ICO Platform?
                  </p>
                  <h2 className="section3-header mb-2">Tokenomics</h2>
                </Slide>
                <p>The LionPay token is the core of our financial ecosystem.</p>
                <h5 style={{ fontWeight: 600, color: "white" }}>
                  {" "}
                  Token Distribution
                </h5>
                <p className="text-white ">
                  During the pre-sale, the tokens will be distributed as
                  follows:
                </p>
                <ul className="ps-4">
                  <li className="mb-2">40% Referral & Marketing</li>
                  <li className="mb-2">10–15% Liquidity Pool</li>
                  <li className="mb-2">
                    Remaining for Treasury, Team, and Development
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              className="d-flex justify-content-center align-items-center h-100 "
            >
              <div className="gradient-border-mask d-flex gap-4 flex-column justify-content-between align-items-center">
                <Doughnut data={data} options={options} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* SECTION 5  */}

      <Container className="py-5 px-4 px-md-0 " id="s5roadmap">
        <Slide>
          <p className="text-background fw-800">ROADMAP</p>

          <h2 className="section3-header">The Journey of LionPay Token</h2>
        </Slide>
        <br />
        <div className="position-relative w-100">
          <div className="dashed-div position-absolute"></div>
          <div className="d-flex gap-4 w-100 s5overflow" style={{ zIndex: 5 }}>
            {roadmap.map((_, index) => (
              <div
                key={index}
                className={`s5card ${activeCard === index ? "active" : ""}`}
                style={{ minWidth: 300 }}
                onClick={() => handleCardClick(index)}
              >
                <div className="roadmap-icon align-content-center text-center ">
                  <img
                    src={`${config.baseUrl}images/s5card.svg`}
                    style={{ width: 40, height: 40 }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <br />
                <div className="s5c">
                  <p
                    className="text-color mb-0 fw-800"
                    style={{ fontSize: 24 }}
                  >
                    {_.title}
                  </p>
                  <p className="text-color" style={{ fontSize: 18 }}>
                    {_.subtitle}
                  </p>

                  <div className="roadmap-detail fs-14">
                    <ul style={{ paddingLeft: 20 }}>
                      <li>{_.step1}</li>
                      <li>{_.step2}</li>
                      <li>{_.step3}</li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>

      <Footer />

      <Modal
        show={show}
        backdrop="static" // Prevents closing on outside click
        backdropClassName="custom-backdrop"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose} // Still allows closing via Close button
      >
        <Modal.Body>
          <div
            className="d-flex justify-content-end"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          >
            <div className="mod-cross  p-1 mb-2">
              <img src={`${config.baseUrl}images/close2.png`} alt="" />
            </div>
          </div>
          <p className="login-header">Welcome to LionPay!</p>

          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              otp: "",
              password: "",
              confirmPassword: "",
              phoneNumber: "",
              selectedCountry: "",
              state: "",
              city: "",
              socialMediaLink: "",
              videoUrl: "",
              walletAddress: "",
              description: "",
              other: "",
              termsAccepted: false,
              modeOfCommunicationData: "",
              modeOfCommunication: "WhatsApp",
            }}
            validationSchema={() => validationSchema(influencerChecked)} // Pass influencerChecked as a function
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleSubmit,
              handleBlur,
              setFieldValue,
              handleChange,
              touched,
              values,
            }) => (
              <Form className="text-white" onSubmit={handleSubmit}>
                <>
                  {sendOtpStatus == true ? (
                    <Row>
                      <Col lg={3}></Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="otp">
                          <Form.Label style={{ fontSize: 14 }}>
                            <p style={{ color: "orange" }}>
                              OTP has been sent to your email.
                            </p>
                            OTP <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="py-2"
                            name="otp"
                            onKeyPress={(event) => {
                              if (!/^\d{0,1}$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={!sendOtpStatus}
                            placeholder="Enter otp"
                            value={values.otp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.otp && errors.otp && (
                            <div
                              className="text-danger"
                              style={{ fontSize: 12, marginTop: 5 }}
                            >
                              {errors.otp}
                            </div>
                          )}
                        </Form.Group>
                        {timesShow ? (
                          <div
                            className="text-color text-end mb-3"
                            style={{ fontSize: "14px" }}
                          >
                            Resend OTP : {formatTime(timeLeft)} Sec
                          </div>
                        ) : (
                          <div
                            className="text-color text-end mb-3"
                            style={{ cursor: "pointer", fontSize: "14px" }}
                            disabled={otpSendLoader == false ? false : true}
                            onClick={handleSubmit}
                          >
                            {otpSendLoader == false
                              ? "Resend OTP"
                              : "Sending OTP..."}
                          </div>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label style={{ fontSize: 14 }}>
                              First Name <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="py-2"
                              name="firstName"
                              placeholder="Enter First Name"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.firstName && errors.firstName && (
                              <div
                                className="text-danger"
                                style={{ fontSize: 12, marginTop: 5 }}
                              >
                                {errors.firstName}
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label style={{ fontSize: 14 }}>
                              Last Name <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="py-2"
                              name="lastName"
                              placeholder="Enter Last Name"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {touched.lastName && errors.lastName && (
                              <div
                                className="text-danger"
                                style={{ fontSize: 12, marginTop: 5 }}
                              >
                                {errors.lastName}
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col
                          lg={12}
                          className="d-flex gap-2 align-items-end mb-3"
                        >
                          <div className="w-100">
                            <Form.Group controlId="email">
                              <Form.Label style={{ fontSize: 14 }}>
                                Email <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                type="email"
                                className="py-2"
                                name="email"
                                disabled={sendOtpStatus}
                                placeholder="Enter Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.email && errors.email && (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: 12, marginTop: 5 }}
                                >
                                  {errors.email}
                                </div>
                              )}
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="password">
                            <Form.Label style={{ fontSize: 14 }}>
                              Password <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                className="py-2"
                                name="password"
                                placeholder="Enter Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <InputGroup.Text
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: "pointer" }}
                              >
                                {showPassword ? <EyeSlash /> : <Eye />}
                              </InputGroup.Text>
                            </InputGroup>
                            {/* <div>The password should contain 1 special character, 1 Uppercase letter, at least 8 character, at least 1 number</div> */}

                            {touched.password && errors.password && (
                              <div
                                className="text-danger"
                                style={{ fontSize: 12, marginTop: 5 }}
                              >
                                {errors.password}
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="confirmPassword"
                          >
                            <Form.Label style={{ fontSize: 14 }}>
                              Confirm Password{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                type={showConfirmPassword ? "text" : "password"}
                                className="py-2"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <InputGroup.Text
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {showConfirmPassword ? <EyeSlash /> : <Eye />}
                              </InputGroup.Text>
                            </InputGroup>
                            {touched.confirmPassword &&
                              errors.confirmPassword && (
                                <div
                                  className="text-danger"
                                  style={{ fontSize: 12, marginTop: 5 }}
                                >
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </Form.Group>
                        </Col>
                        <span
                          className="mb-2 "
                          style={{ fontSize: 12, color: "#ffffff95" }}
                        >
                          * Password should contain 1 special character, 1
                          Uppercase letter, at least 8 characters, at least 1
                          number.
                        </span>
                      </Row>
                      {influencerChecked || isCheckedInflu == true ? (
                        ""
                      ) : (
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="isReferralCode"
                          >
                            <Form.Label style={{ fontSize: 14 }}>
                              Referral Code (Optional)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="py-2"
                              name="isReferralCode"
                              placeholder="Enter Referral Code"
                              value={isReferralCode}
                              onChange={(e) =>
                                setIsReferralCode(e.target.value)
                              } // Allow manual editing
                            />
                          </Form.Group>
                        </Col>
                      )}

                      {isCheckedInflu == true ? (
                        ""
                      ) : (
                        <div className="text-white d-flex align-items-center gap-2 mb-2">
                          <Form.Check
                            type="checkbox"
                            checked={
                              influencerChecked || isCheckedInflu == true
                            }
                            onChange={handleInfluencerChange}
                          />
                          <span style={{ fontSize: 14 }}>
                            Are You an Influencer or Affiliate?
                          </span>
                        </div>
                      )}

                      {isCheckedInflu == true || influencerChecked ? (
                        // true ?
                        <>
                          <Row>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="phoneNumber"
                              >
                                <Form.Label style={{ fontSize: 14 }}>
                                  Mobile Number (Optional)
                                </Form.Label>

                                <PhoneInput
                                  country={"de"}
                                  value={formData.phone}
                                  className=" w-100 mno"
                                  name="phoneNumber"
                                  placeholder="Enter Mobile Number"
                                  inputStyle={{
                                    padding: "10px",
                                    width: "90%",
                                    marginLeft: "34px",
                                    height: 41,
                                  }}
                                  containerStyle={{ width: "100%" }}
                                  onChange={(value, country) => {
                                    setFormData({
                                      ...formData,
                                      phone: value.replace(
                                        `+${country.dialCode}`,
                                        ""
                                      ), // Store only the number
                                      countryCode: `+${country.dialCode}`, // Store only the country code
                                    });
                                  }}
                                />
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3" controlId="country">
                                <Form.Label style={{ fontSize: 14 }}>
                                  Country{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Select
                                  className="py-2"
                                  value={values.selectedCountry} // Use Formik values
                                  onChange={(e) =>
                                    handleCountryChange(e, setFieldValue)
                                  }
                                  onBlur={handleBlur} // Ensure Formik handles blur event
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((country) => (
                                    <option
                                      key={country.isoCode}
                                      value={country.isoCode}
                                    >
                                      {country.name}
                                    </option>
                                  ))}
                                </Form.Select>

                                {touched.selectedCountry &&
                                  errors.selectedCountry && (
                                    <div
                                      className="text-danger"
                                      style={{ fontSize: 12, marginTop: 5 }}
                                    >
                                      {errors.selectedCountry}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>

                            <Col lg={6}>
                              <Form.Group className="mb-3" controlId="country">
                                <Form.Label style={{ fontSize: 14 }}>
                                  Mode of Communication{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Select
                                  className="py-2"
                                  name="modeOfCommunication"
                                  value={values.modeOfCommunication} // Use Formik values
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="WhatsApp">WhatsApp</option>
                                  <option value="Skype">Skype</option>
                                  <option value="Facebook">Facebook</option>
                                  <option value="Telegram">Telegram</option>
                                </Form.Select>

                                {touched.modeOfCommunication &&
                                  errors.modeOfCommunication && (
                                    <div
                                      className="text-danger"
                                      style={{ fontSize: 12, marginTop: 5 }}
                                    >
                                      {errors.modeOfCommunication}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>

                            {values.modeOfCommunication == "WhatsApp" ||
                              values.modeOfCommunication == undefined ? (
                              <Col lg={6}>
                                <Form.Group
                                  className="mb-1"
                                  controlId="modeOfCommunicationData"
                                >
                                  <Form.Label style={{ fontSize: 14 }}>
                                    WhatsApp Number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>

                                  <PhoneInput
                                    country={"de"}
                                    value={values.modeOfCommunicationData}
                                    className="w-100 mno"
                                    name="modeOfCommunicationData"
                                    placeholder="Enter Mobile Number"
                                    inputStyle={{
                                      padding: "10px",
                                      width: "90%",
                                      marginLeft: "34px",
                                      height: 41,
                                    }}
                                    containerStyle={{ width: "100%" }}
                                    onChange={(value, country) => {
                                      setFieldValue(
                                        "modeOfCommunicationData",
                                        value.replace(
                                          `+${country.dialCode}`,
                                          ""
                                        )
                                      );
                                      setFieldValue(
                                        "countryCode",
                                        `+${country.dialCode}`
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Form.Group>
                                {touched.modeOfCommunicationData &&
                                  errors.modeOfCommunicationData && (
                                    <div
                                      className="text-danger"
                                      style={{ fontSize: 12, marginTop: 5 }}
                                    >
                                      {errors.modeOfCommunicationData}
                                    </div>
                                  )}
                              </Col>
                            ) : values.modeOfCommunication == "Skype" ? (
                              <Col lg={6}>
                                <Form.Group
                                  className="mb-1"
                                  controlId="modeOfCommunicationData"
                                >
                                  <Form.Label style={{ fontSize: 14 }}>
                                    Skype Id
                                  </Form.Label>
                                  <span style={{ color: "red" }}>*</span>

                                  <Form.Control
                                    type="text"
                                    className="py-2"
                                    name="modeOfCommunicationData"
                                    placeholder="Enter Skype Id"
                                    value={values.modeOfCommunicationData}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Form.Group>
                                {touched.modeOfCommunicationData &&
                                  errors.modeOfCommunicationData && (
                                    <div
                                      className="text-danger"
                                      style={{ fontSize: 12, marginTop: 5 }}
                                    >
                                      {errors.modeOfCommunicationData}
                                    </div>
                                  )}
                              </Col>
                            ) : values.modeOfCommunication == "Facebook" ? (
                              <Col lg={6}>
                                <Form.Group
                                  className="mb-1"
                                  controlId="modeOfCommunicationData"
                                >
                                  <Form.Label style={{ fontSize: 14 }}>
                                    Facebook Id
                                  </Form.Label>
                                  <span style={{ color: "red" }}>*</span>

                                  <Form.Control
                                    type="text"
                                    className="py-2"
                                    name="modeOfCommunicationData"
                                    placeholder="Enter Facebook Id"
                                    value={values.modeOfCommunicationData}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Form.Group>
                                {touched.modeOfCommunicationData &&
                                  errors.modeOfCommunicationData && (
                                    <div
                                      className="text-danger"
                                      style={{ fontSize: 12, marginTop: 5 }}
                                    >
                                      {errors.modeOfCommunicationData}
                                    </div>
                                  )}
                              </Col>
                            ) : values.modeOfCommunication == "Telegram" ? (
                              <Col lg={6}>
                                <Form.Group
                                  className="mb-1"
                                  controlId="modeOfCommunicationData"
                                >
                                  <Form.Label style={{ fontSize: 14 }}>
                                    Telegram Id
                                  </Form.Label>
                                  <span style={{ color: "red" }}>*</span>

                                  <Form.Control
                                    type="text"
                                    className="py-2"
                                    name="modeOfCommunicationData"
                                    placeholder="Enter Telegram Id"
                                    value={values.modeOfCommunicationData}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Form.Group>
                                {touched.modeOfCommunicationData &&
                                  errors.modeOfCommunicationData && (
                                    <div
                                      className="text-danger"
                                      style={{ fontSize: 12, marginTop: 5 }}
                                    >
                                      {errors.modeOfCommunicationData}
                                    </div>
                                  )}
                              </Col>
                            ) : (
                              ""
                            )}

                            {/* <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="socialMediaLink"
                              >
                                <Form.Label style={{ fontSize: 14 }}>
                                  Other Links{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="py-2"
                                  name="socialMediaLink"
                                  placeholder="Enter Other Links"
                                  value={values.socialMediaLink}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.socialMediaLink &&
                                  errors.socialMediaLink && (
                                    <div
                                      className="text-danger"
                                      style={{ fontSize: 12, marginTop: 5 }}
                                    >
                                      {errors.socialMediaLink}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col> */}

                            <Col lg={12}>
                              <Form.Group
                                className="mb-3 "
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label style={{ fontSize: 14 }}>
                                  Wallet Address{" "}
                                  <span
                                    style={{
                                      color: "rgba(255, 255, 255, 0.584)",
                                    }}
                                  >
                                    (Solana Address only)
                                  </span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="py-2"
                                  placeholder="Enter Wallet Address"
                                  name="walletAddress"
                                  value={values.walletAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group
                                className="mb-3 "
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label style={{ fontSize: 14 }}>
                                  Audience Description{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="py-2"
                                  name="description"
                                  placeholder="Enter Audience Description"
                                  value={values.description}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {touched.description && errors.description && (
                                  <div
                                    className="text-danger"
                                    style={{ fontSize: 12, marginTop: 5 }}
                                  >
                                    {errors.description}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            {/* Add other form fields similarly */}
                          </Row>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="text-white d-flex align-items-center gap-2 mb-1">
                        <Form.Check
                          type="checkbox"
                          name="termsAccepted"
                          checked={values.termsAccepted}
                          onChange={() =>
                            setFieldValue(
                              "termsAccepted",
                              !values.termsAccepted
                            )
                          }
                        />
                        <span style={{ fontSize: 14 }}>
                          By signing up, I agree to LionPay{" "}
                          <Link
                            to={`${config.baseUrl}terms-and-conditions`}
                            target="_blank"
                            className="text-color"
                          >
                            Terms & Conditions
                          </Link>
                        </span>
                      </div>
                      {touched.termsAccepted && errors.termsAccepted && (
                        <div
                          className="text-danger"
                          style={{ fontSize: 12, marginTop: 5 }}
                        >
                          {errors.termsAccepted}
                        </div>
                      )}
                    </>
                  )}

                  <Row className="gy-3 align-items-center justify-content-center">
                    {sendOtpStatus == false ? (
                      <>
                        {/* <Col lg={6}>
                          <div onClick={handleClose}>
                            <div
                              className="py-1 learn-more d-flex justify-content-center align-items-center text-white w-100"
                              style={{
                                width: "fit-content",
                                minHeight: 40,
                                borderRadius: 50,
                              }}
                            >
                              Close
                            </div>
                          </div>
                        </Col> */}
                        <Col lg={6} className="mt-4">
                          <button
                            type="submit"
                            className="btn01 px-4 text-decoration-none w-100 mb-2"
                            style={{ padding: "11px 0px" }}
                            disabled={otpSendLoader == false ? false : true}

                          // disabled={!isChecked}
                          >
                            {otpSendLoader == false ? "Continue" : "Loading..."}
                          </button>
                          <p className=" mb-0 text-white text-center fs-14">
                            Already have an account?{" "}
                            <span
                              onClick={() => redirectTo()}
                              className="text-color"
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              {" "}
                              Log In
                            </span>
                          </p>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg={6}>
                          <button
                            type="button"
                            className="btn01 px-4 text-decoration-none w-100"
                            style={{ padding: "11px 0px" }}
                            onClick={() => isEmailVerify(values)}
                            disabled={signUpLoader ? true : false}
                          // disabled={!isChecked}
                          >
                            {signUpLoader ? "Loading..." : "Sign Up"}
                          </button>
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;
