import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { resetPasswordAPI } from "../Action/action";
import toast from "react-hot-toast";

const ResetPassword = ({ handleNext, step, handleBack }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (loading) {
      return;
    }

    if (!password || !confirmPassword) {
      setError("Both fields are required.");
      return;
    }

    const passwordRegex =
     /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;"'<>,.?/~`]).{8,}$/;

    if (!passwordRegex.test(password)) {
      setError(
        "Password must be at least 8 characters long, contain 1 uppercase letter, 1 number, and 1 special character."
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Password and confirm password do not match.");
      return;
    }
    setError("");

    setLoading(true);
    try {
      const email = localStorage.getItem("Email");
      const otp = localStorage.getItem("otp");

      let res = await resetPasswordAPI({
        email: email,
        otp: otp,
        password: password,
        confirmPassword: confirmPassword,
      });
      setLoading(false);
      if (res.success) {
        localStorage.removeItem("Email");
        localStorage.removeItem("otp");

        toast.success(res.msg);
        setTimeout(() => {
          handleBack()
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    } catch (err) {
      toast.error("Something went wrong.");
    }
  };

  return (
    <div className="login">
      {step > 1 && step !== 4 && (
        <div
          className="text-white"
          onClick={handleBack}
          style={{ cursor: "pointer" }}
        >
          Back
        </div>
      )}
      <p className="login-header mb-0">Reset Password</p>
      <p className="text-white text-center px-sm-5 px-0">
        Choose a new password for your account
      </p>

      <div className="mb-3">
        <Form className="text-white">
          <Form.Group className="mb-3 position-relative">
            <Form.Label>
              Enter Your Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                className="py-2"
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroup.Text
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <EyeSlash /> : <Eye />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          {/* Confirm Password Input */}
          <Form.Group className="mb-3 position-relative">
            <Form.Label>
              Confirm Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                className="py-2"
                placeholder="********"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <InputGroup.Text
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{ cursor: "pointer" }}
              >
                {showConfirmPassword ? <EyeSlash /> : <Eye />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <span className="mb-3" style={{ fontSize: 14, color: "#ffffff95" }}>
            * Password must be at least 8 characters long, contain 1 uppercase letter, 1 number, and 1 special character.
          </span>
        </Form>
        {/* Error Message */}
        {error && <p className="text-danger text-center">{error}</p>}
      </div>
      {step < 5 ? (
        <div className="d-flex justify-content-center w-100">
          <div
            className="btn01 py-2"
            style={{ cursor: "pointer", maxWidth: 280, width: "100%" }}
            onClick={handleSubmit}
          >
            {loading ? "Processing..." : "Submit"}
          </div>
        </div>
      ) : (
        <Button variant="success" onClick={handleBack}>
          Finish
        </Button>
      )}
    </div>
  );
};

export default ResetPassword;
