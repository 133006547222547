import React, { useEffect, useState } from "react";
import DashboardHeader from "../component/DashboardHeader";
import Sidebar from "../component/Sidebar";
import { Col, Form, Row } from "react-bootstrap";
import "../styles/Dashboard.css";
import "../styles/Custom.css";
import {
  getProfileDetails,
  getUnreadNotificationCountAction,
  updateProfileDetails,
} from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import { City, Country, State } from "country-state-city";
import { useNavigate } from "react-router";
import config from "../corefiles/config";
import { PublicKey } from "@solana/web3.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidNumber } from "libphonenumber-js";

const Profile = ({ special, clearModalData }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    walletAddress: "",
    country: "",
    state: "",
    city: "",
    other: "",
    countryCode: "+91",
  });

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    GetProfile();
    loadCountries();
  }, []);

  useEffect(() => {
    if (profile.state) {
      (async () => {
        const cityList = await City.getCitiesOfState(
          profile.country,
          profile.state
        );
        setCities(cityList);
      })();
    }
  }, [profile.state]);

  const GetProfile = async () => {
    try {
      const res = await getProfileDetails();
      if (res.success) {
        const data = res.data;
        setProfile(data);
        if (data.country) {
          handleCountryChange(data.country, true);
        }
        if (data.state) {
          handleStateChange(data.state, true);
        }
        if (data.city) {
          // alert(data.city)
          handleCityChange(data.city, true);
        }
      } else {
        toast.error(res.msg);
      }
    } catch (error) {}
  };

  const loadCountries = () => {
    const countryList = Country.getAllCountries();
    setCountries(countryList);
  };

  const handleCountryChange = (countryCode, resetProfile = true) => {
    if (resetProfile) {
      setProfile((prev) => ({
        ...prev,
        country: countryCode,
        state: "",
        city: "",
      }));
    }
    const stateList = State.getStatesOfCountry(countryCode);
    setStates(stateList);
    setCities([]);
  };

  const handleStateChange = async (stateCode, resetProfile = true) => {
    let countryCode;

    setProfile((prev) => {
      countryCode = prev.country; // Capture the current country value
      return resetProfile ? { ...prev, state: stateCode, city: "" } : prev;
    });

    const cityList = await City.getCitiesOfState(countryCode, stateCode);
    setCities(cityList);
  };

  const handleCityChange = (city) => {
    setProfile((prev) => ({ ...prev, city }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let errorMsg = "";
    const nameRegex = /^[A-Za-z\s]+$/;

    if (name === "firstName" && !nameRegex.test(value) && value !== "") {
        return; // Prevent updating state if invalid
    }
    if (name === "lastName" && !nameRegex.test(value) && value !== "") {
        return; // Prevent updating state if invalid
    }

    setErrors((prev) => ({ ...prev, [name]: errorMsg }));

    setProfile((prev) => ({
        ...prev,
        [name]: value,
        ...(name === "modeOfCommunication" && { modeOfCommunicationData: "" }) // Reset data on change
    }));
};


const handleUpdateProfile = async () => {
  try {
      const alphabeticRegex = /^[A-Za-z]+$/;
      let newErrors = {};

      // First Name Validation
      if (!profile.firstName) {
          newErrors["firstName"] = "First name is required";
      } 
      // else if (!alphabeticRegex.test(profile.firstName)) {
      //     newErrors["firstName"] = "First name must contain only alphabetic characters";
      // } else if (profile.firstName.length < 2) {
      //     newErrors["firstName"] = "Must be at least 2 characters";
      // } 
      
      else if (profile.firstName.length > 50) {
          newErrors["firstName"] = "Must be at most 50 characters";
      }

      // Last Name Validation
      if (!profile.lastName) {
          newErrors["lastName"] = "Last name is required";
      } 
      
      // else if (!alphabeticRegex.test(profile.lastName)) {
      //     newErrors["lastName"] = "Last name must contain only alphabetic characters";
      // } 
      
      else if (profile.lastName.length < 2) {
          newErrors["lastName"] = "Must be at least 2 characters";
      } else if (profile.lastName.length > 50) {
          newErrors["lastName"] = "Must be at most 50 characters";
      }

      // Mode of Communication Data Validation (Required)
      if (!profile.modeOfCommunicationData && profile.isInfluencer == true) {
          newErrors["modeOfCommunicationData"] = "This field is required";
          toast.error("Please provide a valid communication ID or number.");
      }

      // Wallet Address Validation
      if (profile.walletAddress) {
          try {
              new PublicKey(profile.walletAddress);
          } catch (error) {
              toast.error("Invalid Wallet Address");
              return;
          }
      }

      // Phone Number Validation (If WhatsApp is selected)
      if (profile.modeOfCommunication === "WhatsApp" && profile.isInfluencer == true) {
          if (!profile.modeOfCommunicationData || !isValidNumber(`+${profile.modeOfCommunicationData}`, "IN")) {
              newErrors["modeOfCommunicationData"] = "Invalid WhatsApp number";
              toast.error("Invalid WhatsApp Number for selected country");
          }
      }

      // If errors exist, stop submission
      if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
      }

      setLoader(true);
      profile.firstName =  profile.firstName.trim()
      profile.lastName =  profile.lastName.trim()

      const res = await updateProfileDetails(profile);

      if (res.success) {
          toast.success("Profile updated successfully!");
          setTimeout(() => {
              window.location.reload();
          }, 200);
      } else {
          toast.error(res.msg);
          setLoader(false);
      }
  } catch (error) {
      toast.error("Something went wrong. Please try again later.");
      setLoader(false);
  }
};


  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`${config.baseUrl}dashboard`);
  };

  const getUnreadNotificationCountAPI = async () => {
    const res = await getUnreadNotificationCountAction();
    if (res.success) {
    }
  };

  const isInfluencer = profile?.isInfluencer;

  return (
    <>
      <Toaster />
      <div className="w-100">
        <div className="d-flex  w-100">
          <div>
            <Sidebar special={special} clearModalData={clearModalData} />
          </div>
          <div className="w-100" style={{ height: "100%" }}>
            <DashboardHeader
              refreshNotifications={getUnreadNotificationCountAPI}
            />
            <div
              className="pt-sm-2 pt-5 mt-lg-4 px-4 px-lg-5  mb-5 pb-5  wrapper-dash"
              style={{
                flex: "1",
                backgroundColor: "#100500",
                color: "#ffffff",
                height: "87vh",
                overflowY: "scroll",
              }}
            >
              <h2 className=" dash-header">Profile</h2>

              <br />
              <div className="profile-container dash-gradient2 h-auto p-4">
                <Form>
                  <Row className="gy-2">
                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          First Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            className="py-2"
                            style={{ fontSize: 14 }}
                            name="firstName"
                            value={profile.firstName}
                            placeholder="Enter first name"
                            onChange={handleInputChange}
                          />
                          {errors.firstName && (
                            <small className="text-danger">
                              {errors.firstName}
                            </small>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Last Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type="text"
                            className="py-2"
                            style={{ fontSize: 14 }}
                            placeholder="Enter last name"
                            name="lastName"
                            value={profile.lastName}
                            onChange={handleInputChange}
                          />
                          {errors.lastName && (
                            <small className="text-danger">
                              {errors.lastName}
                            </small>
                          )}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg={isInfluencer ? 6 : 12}>
                      <Form.Group
                        className="mb-3 "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {" "}
                          Email <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type="email"
                            className="py-2 disabled-input"
                            style={{ fontSize: 14 }}
                            placeholder="Enter email address"
                            name="email"
                            disabled
                            value={profile.email}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    {!isInfluencer ? (
                      <Col lg={12}>
                        <Form.Group
                          className="mb-3 "
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label style={{ fontSize: "14px" }}>
                            Wallet Address{" "}
                            <span
                              style={{ color: "rgba(255, 255, 255, 0.584)" }}
                            >
                              (Solana address only)
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="py-2"
                            style={{ fontSize: 14 }}
                            placeholder="Enter Wallet Address"
                            name="walletAddress"
                            value={profile.walletAddress}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                    ) : (
                      ""
                    )}
                    {isInfluencer ? (
                      <>
                        {/* <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="socialMediaLink"
                          >
                            <Form.Label style={{ fontSize: 14 }}>
                              Other Links{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="py-2 disabled-input"
                              style={{ fontSize: 14 }}
                              name="socialMediaLink"
                              disabled
                              placeholder="Enter Other Links"
                              value={profile.socialMediaLink}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col> */}

                        <Col lg={6}>
                          <Form.Group
                            className="mb-3 "
                            as={Col}
                            controlId="formGridState"
                          >
                            <Form.Label>
                              Country <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                              className="py-2"
                              style={{ fontSize: 14 }}
                              value={profile.country}
                              onChange={(e) =>
                                handleCountryChange(e.target.value)
                              }
                            >
                              <option value="">Select Country</option>
                              {countries.map((country) => (
                                <option
                                  key={country.isoCode}
                                  value={country.isoCode}
                                >
                                  {country.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group
                            className="mb-3 "
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Mobile Number (Optional) </Form.Label>
                            <div className="position-relative">
                              <PhoneInput
                                country={"de"}
                                className=" w-100 mno"
                                name="phoneNumber "
                                placeholder="Enter Mobile Number"
                                inputStyle={{
                                  padding: "10px",
                                  width: "90%",
                                  marginLeft: "34px",
                                  height: 41,
                                }}
                                value={profile.phoneNumber}
                                containerStyle={{ width: "100%" }}
                                onChange={(phoneNumber, countryData) => {
                                  if (typeof phoneNumber !== "string") return; // Ensure phoneNumber is a string

                                  const dialCode =
                                    countryData?.dialCode || "91"; // Default to 91 if undefined
                                  const numberWithoutCode =
                                    phoneNumber.startsWith(`+${dialCode}`)
                                      ? phoneNumber.replace(`+${dialCode}`, "")
                                      : phoneNumber;

                                  setProfile({
                                    ...profile,
                                    phoneNumber: numberWithoutCode, // Store only the number
                                    countryCode: `+${dialCode}`, // Store only the country code
                                  });
                                }}
                              />
                            </div>
                          </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="country">
                            <Form.Label style={{ fontSize: 14 }}>
                              Mode of Communication{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Select
                              className="py-2"
                              name="modeOfCommunication"
                              value={profile.modeOfCommunication} // Use Formik values
                              onChange={handleInputChange}
                            >
                              <option value="WhatsApp">WhatsApp</option>
                              <option value="Skype">Skype</option>
                              <option value="Facebook">Facebook</option>
                              <option value="Telegram">Telegram</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        {profile.modeOfCommunication == "WhatsApp" ? (
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3 "
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Mobile Number (WhatsApp) <span style={{ color: "red" }}>*</span></Form.Label>
                              <div className="position-relative">
                                <PhoneInput
                                  country={"de"}
                                  className=" w-100 mno"
                                  name="modeOfCommunicationData"
                                  placeholder="Enter Mobile Number"
                                  inputStyle={{
                                    padding: "10px",
                                    width: "90%",
                                    marginLeft: "34px",
                                    height: 41,
                                  }}
                                  value={profile.modeOfCommunicationData}
                                  containerStyle={{ width: "100%" }}
                                  onChange={(phoneNumber, countryData) => {
                                    if (typeof phoneNumber !== "string") return; // Ensure phoneNumber is a string

                                    const dialCode =
                                      countryData?.dialCode || "91"; // Default to 91 if undefined
                                    const numberWithoutCode =
                                      phoneNumber.startsWith(`+${dialCode}`)
                                        ? phoneNumber.replace(
                                            `+${dialCode}`,
                                            ""
                                          )
                                        : phoneNumber;

                                    setProfile({
                                      ...profile,
                                      modeOfCommunicationData:
                                        numberWithoutCode, // Store only the number
                                      countryCode: `+${dialCode}`, // Store only the country code
                                    });
                                  }}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        ) : profile.modeOfCommunication == "Skype" ? (
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="modeOfCommunicationData"
                            >
                              <Form.Label style={{ fontSize: 14 }}>
                                Skype Id <span style={{ color: "red" }}>*</span>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                className="py-2"
                                name="modeOfCommunicationData"
                                placeholder="Enter Skype Id"
                                value={profile.modeOfCommunicationData}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                          </Col>
                        ) : profile.modeOfCommunication == "Facebook" ? (
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="modeOfCommunicationData"
                            >
                              <Form.Label style={{ fontSize: 14 }}>
                                Facebook Id <span style={{ color: "red" }}>*</span>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                className="py-2"
                                name="modeOfCommunicationData"
                                placeholder="Enter Facebook Id"
                                value={profile.modeOfCommunicationData}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                          </Col>
                        ) : profile.modeOfCommunication == "Telegram" ? (
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="modeOfCommunicationData"
                            >
                              <Form.Label style={{ fontSize: 14 }}>
                                Telegram Id <span style={{ color: "red" }}>*</span>
                              </Form.Label>

                              <Form.Control
                                type="text"
                                className="py-2"
                                name="modeOfCommunicationData"
                                placeholder="Enter Telegram Id"
                                value={profile.modeOfCommunicationData}
                                onChange={handleInputChange}
                              />
                            </Form.Group>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col lg={6}>
                          <Form.Group
                            className="mb-3 "
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label style={{ fontSize: "14px" }}>
                              Wallet Address{" "}
                              <span
                                style={{ color: "rgba(255, 255, 255, 0.584)" }}
                              >
                                (Solana Address only)
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="py-2"
                              style={{ fontSize: 14 }}
                              placeholder="Enter Wallet Address"
                              name="walletAddress"
                              value={profile.walletAddress}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3 "
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label style={{ fontSize: "14px" }}>
                              Audience Description{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="py-2 disabled-input"
                              style={{ fontSize: 14 }}
                              disabled
                              name="description"
                              placeholder="Enter Audience Description"
                              value={profile.description}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                    <Col lg={6} className="">
                      <div className="w-100 mb-2 d-flex justify-content-center">
                        <div
                          className=" py-1 learn-more d-flex px-4 justify-content-center align-items-center text-white   "
                          onClick={handleBack}
                          style={{
                            padding: "11px 0px",
                            borderRadius: 50,
                            fontWeight: 400,
                            fontSize: 13,
                            minHeight: "40px",
                          }}
                        >
                          Back
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        className="btn01 px-4"
                        style={{ padding: "11px 0px", cursor: "pointer" }}
                        onClick={handleUpdateProfile}
                        disabled={loader == false ? false : true}
                      >
                        {loader == false ? "Update Details" : "Loading..."}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
