import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  ProgressBar,
  Row,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../directives/Header";
import { Link } from "react-router";
import "../styles/Home.css";

const CountdownTimer = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Function to calculate time left
  function calculateTimeLeft() {
    const difference = new Date(targetDate).getTime() - new Date().getTime();
    if (difference <= 0) return null;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (!newTimeLeft) {
        clearInterval(timer); // Stop timer when countdown reaches zero
      }
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [targetDate]);

  if (!timeLeft) {
    return <h2>Time's up!</h2>;
  }

  return (
    <div className="d-flex justify-content-between">
      <p className="ico-timer-data">
        {" "}
        {timeLeft.days}
        <span className="ico-timer-label">d</span>
      </p>
      <p className="ico-timer-data">:</p>
      <p className="ico-timer-data">
        {" "}
        {timeLeft.hours}
        <span className="ico-timer-label">h</span>
      </p>
      <p className="ico-timer-data">:</p>
      <p className="ico-timer-data">
        {" "}
        {timeLeft.minutes}
        <span className="ico-timer-label">m</span>
      </p>
      <p className="ico-timer-data">:</p>
      <p className="ico-timer-data">
        {" "}
        {timeLeft.seconds}
        <span className="ico-timer-label">s</span>
      </p>
    </div>
  );
};
const Ico = () => {
  const targetDate = "2025-02-01T00:00:00";
  const now = 60;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  return (
    <>
      <Header />

      {/* SECTION1  */}
      <div className="section1 px-md-5 px-3 my-4">
        <div
          className="section1_container d-flex justify-content-center align-items-center h-auto "
          style={{ minHeight: "80vh" }}
        >
          <Container className="">
            {/* WELCOME  */}
            <Row className="gy-3  pb-4">
              <Col lg={7}>
                <div className="d-flex flex-column justify-content-center h-100 gap-2">
                  <p className="ico-header">Discover the Next Big in Crypto</p>
                  <p className="ico-para">
                    A new smart block chain based marketplace for trading
                    digital goods & assets according.
                  </p>
                  <Link
                    className="btn01  py-2 "
                    style={{
                      width: 180,
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    Buy LionPay Token
                  </Link>
                </div>
              </Col>
              <Col lg={5}>
                <div className="profile-container dash-gradient2 p-5">
                  <p className="text-white text-end">Stage 20%</p>
                  <p className="token-sale">Token sale ends in</p>
                  <CountdownTimer targetDate={targetDate} />
                  <ProgressBar
                    className="pb mb-1"
                    now={now}
                    label={`${now}%`}
                  ></ProgressBar>
                  <small className="text-white ">Raised: 20000</small>

                  <div>
                    <Row className="ico-data py-3">
                      <Col>
                        <p className="ico-table-data">Token Name</p>
                      </Col>
                      <Col>
                        <p className="ico-table-data text-end">LionPay Token</p>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row className="ico-data py-3">
                      <Col>
                        <p className="ico-table-data">Token Symbol</p>
                      </Col>
                      <Col>
                        <p className="ico-table-data text-end">LionPay</p>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row className="ico-data py-3">
                      <Col>
                        <p className="ico-table-data">Current Stage</p>
                      </Col>
                      <Col>
                        <p className="ico-table-data text-end">0.002</p>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row className="ico-data py-3">
                      <Col>
                        <p className="ico-table-data">Next Stage</p>
                      </Col>
                      <Col>
                        <p className="ico-table-data text-end">0.265</p>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <div
                    className="d-flex justify-content-center w-100 "
                    onClick={handleShow}
                  >
                    <Link
                      className="btn01   py-2  w-100"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      Buy LionPay Token
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* MODAL 1  */}

      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="d-flex justify-content-end" onClick={handleClose2}>
            <div className="mod-cross  p-1">
              <img src="images/close2.png" alt="" />
            </div>
          </div>
          <p className="login-header">Connect Wallet</p>
          <div className="d-flex flex-wrap justify-content-evenly gap-3 mb-3 align-items-center">
            <div className="d-flex flex-column align-items-center">
              <div className="cw-icon">
                <img src="images/cw1.png" alt="" />
              </div>
              <p className="cw-p">Metamask</p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="cw-icon">
                <img src="images/cw2.png" alt="" />
              </div>
              <p className="cw-p">Coinbase Wallet</p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="cw-icon">
                <img src="images/cw3.png" alt="" />
              </div>
              <p className="cw-p">Trust Wallet</p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="cw-icon">
                <img src="images/cw4.png" alt="" />
              </div>
              <p className="cw-p">Wallet Connect</p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="cw-icon">
                <img src="images/cw5.png" alt="" />
              </div>
              <p className="cw-p">Binance Wallet</p>
            </div>
          </div>
          <div
            className="d-flex justify-content-center w-100 "
            onClick={() => {
              handleShow2();
              handleClose();
            }}
          >
            <Link
              className="btn01   py-2 "
              style={{ width: 200, cursor: "pointer", textDecoration: "none" }}
            >
              Connect
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      {/* MODAL 2  */}

      <Modal
        show={show2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose2}
      >
        <Modal.Body>
          <div className="d-flex justify-content-end" onClick={handleClose2}>
            <div className="mod-cross  p-1 mb-2">
              <img src="images/close2.png" alt="" />
            </div>
          </div>
          <p className="login-header">Choose Payment Method</p>
          <div className="d-flex flex-wrap justify-content-evenly gap-3 mb-3 align-items-center">
            <div className="d-flex flex-column align-items-center">
              <div className="cw-icon">
                <img src="images/pm-1.png" alt="" />
              </div>
              <p className="cw-p">Solana</p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="cw-icon">
                <img src="images/pm-2.png" alt="" />
              </div>
              <p className="cw-p">USDT</p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="cw-icon">
                <img src="images/pm-03.png" alt="" />
              </div>
              <p className="cw-p">FIAT Pay</p>
            </div>
          </div>
          <div
            className="d-flex justify-content-center w-100 "
            onClick={() => {
              handleShow3();
              handleClose2();
            }}
          >
            <Link
              className="btn01   py-2 "
              style={{ width: 200, cursor: "pointer", textDecoration: "none" }}
            >
              Pay Now
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      {/* MODAL 3  */}
      <Modal
        show={show3}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose3}
      >
        <Modal.Body>
          <div className="d-flex justify-content-end" onClick={handleClose3}>
            <div className="mod-cross  p-1 mb-2">
              <img src="images/close2.png" alt="" />
            </div>
          </div>
          <p className="login-header mb-5">Connect wallet successfully</p>

          <div
            className="d-flex justify-content-center w-100 "
            onClick={handleClose3}
          >
            <Link
              className="btn01   py-2 "
              style={{ width: 200, cursor: "pointer", textDecoration: "none" }}
            >
              Close
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Ico;
