import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { forgetPassword, verifyOTPAction } from "../Action/action";
import toast from "react-hot-toast";

const OtpVerification = ({ handleNext, step, handleBack }) => {
  const otpLength = 4;
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(Array(otpLength).fill(""));
  const [loading, setLoading] = useState(false);
  const [otpSendLoader, setOtpSendLoader] = useState(false);
  const [timesShow, setTimesShow] = useState(true);

  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes (120 seconds)
  
    useEffect(() => {
      if (timeLeft === 0) {
        setTimesShow(false);
      } // Stop when timer reaches zero
  
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
  
      return () => clearInterval(timer); // Cleanup function
    }, [timeLeft]);
  
    // Format time as MM:SS
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
    };
    
  const handleChange = (index, event) => {
    const value = event.target.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < otpLength - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const newOtp = [...otp];
      if (otp[index]) {
        newOtp[index] = "";
      } else if (index > 0) {
        inputRefs.current[index - 1]?.focus();
        newOtp[index - 1] = "";
      }
      setOtp(newOtp);
    }
  };

  const handleVerifyOTP = async () => {
    if (loading) {
      return;
    }
    const enteredOtp = otp.join("");
    if (enteredOtp.length !== otpLength) {
      toast.error("Please enter a 4-digit OTP.");
      return;
    }

    const email = localStorage.getItem("Email");
    if (!email) {
      toast.error("Email required in forget password.");
      return;
    }
    let otpValue = `${otp[0]}${otp[1]}${otp[2]}${otp[3]}`;
    setLoading(true);
    try {
      let res = await verifyOTPAction({ email: email, otp: otpValue });
      setLoading(false);
      if (res.success) {
        localStorage.setItem("otp", otpValue);
        toast.success(res.msg);
        setTimeout(() => {
          handleNext();
        }, 500);
      } else {
        toast.error(res.msg);
      }
    } catch (err) {
      toast.error("Something went wrong.");
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = localStorage.getItem("Email");
    setOtpSendLoader(true);
    try {
      let data = email.toLowerCase()
      let res = await forgetPassword({ email:data });
      setLoading(false);
      if (res.success) {
        localStorage.setItem("Email", email);
        toast.success(res.msg);
        setOtpSendLoader(false)
        setTimeLeft(120)
        setTimesShow(true)
        // setTimeout(() => {
        //   handleNext();
        // }, 500);
      } else {
        toast.error(res.msg);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Failed to send OTP.");
    }
  };

  return (
    <div className="login">
      <p className="text-white text-center px-sm-5 px-0">
        We have sent an email with 4 digit OTP to your registered email.
      </p>
      <p className="header-text login-code">Enter 4 Digit OTP</p>
      <div
        className="d-flex flex-column w-100 justify-content-center align-items-center 
            "
      >
        <div
          className="d-flex  main-timer justify-content-center align-items-center 
            px-2 px-sm-4 py-2 mb-3"
          style={{ width: "fit-content" }}
        >
          {Array(otpLength)
            .fill()
            .map((_, index) => (
              <div key={index} className="timer-div px-2 px-sm-4">
                <input
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  className="timer-data login-timer"
                  style={{
                    maxWidth: "50px",
                    width: "100%",
                    textAlign: "center",
                  }}
                  value={otp[index]}
                  maxLength={1}
                  onChange={(event) => handleChange(index, event)}
                  onKeyDown={(event) => handleKeyDown(index, event)}
                />
              </div>
            ))}
        </div>
      </div>
    
      {timesShow ? (
                          <div
                            className="text-color text-end mb-3"
                            style={{ fontSize: "14px" }}
                          >
                            Resend OTP : {formatTime(timeLeft)} Sec
                          </div>
                        ) : (
                          <div
                            className="text-color text-end mb-3"
                            style={{ cursor: "pointer", fontSize: "14px" }}
                            disabled={otpSendLoader == false ? false : true}
                            onClick={handleSubmit}
                          >
                            {otpSendLoader == false
                              ? "Resend OTP"
                              : "Sending OTP..."}
                          </div>
                        )}
      {step < 5 ? (
        <div className="d-flex justify-content-center w-100">
          <div
            className="btn01  py-2 mb-2"
            style={{ cursor: "pointer", maxWidth: 280, width: "100%" }}
            onClick={handleVerifyOTP}
          >
            {loading ? "Processing..." : "Verify"}
          </div>
        </div>
      ) : (
        <Button variant="success" onClick={handleBack}>
          Finish
        </Button>
      )}
    </div>
  );
};

export default OtpVerification;
