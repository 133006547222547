import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../directives/Header";
import Footer from "../../directives/Footer";

const Whitepaper = () => {
  return (
    <>
      <div className=" w-100 " style={{ zIndex: 1 }}>
        <Header />
      </div>
      <Container className="text-white " style={{ zIndex: 0 }}>
        <h1 className="mb-0 colortheme"> LionPay White Paper</h1>
        <small>(Pre-Sale Edition)</small>

        <div className="d-flex align-items-center gap-3 mt-3">
          <h4 className="text-nowrap mb-0">
            <img
              src="images/lionlogo.svg"
              className="img-fluid"
              style={{ width: 60, height: 60 }}
              alt=""
            />
          </h4>
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "#ffffff40",
              borderRadius: 10,
            }}
          ></div>
        </div>

        <div className="content mt-3">
          <ol className="terms-ol colortheme">
            <li>
              <p className="mb-2 terms-heading">Introduction</p>
              <p className="mb-0 terms-para">
                LionPay is a next-generation digital finance solution seamlessly
                integrating crypto and fiat banking services. Our goal is to
                provide a secure, high-performance, and globally accessible
                payment platform, offering:
              </p>
              <ul>
                <li className="mb-2 terms-para">High transaction limits.</li>
                <li className="mb-2 terms-para">IBAN support.</li>
                <li className="mb-2 terms-para">Fiat-to-crypto conversions.</li>
                <li className="mb-2 terms-para">
                  Scalable merchant payment gateway .
                </li>
                <li className="mb-2 terms-para">Cashback programs.</li>
                <li className="mb-2 terms-para">AI-powered credit system.</li>
              </ul>
              <p className="mb-0 terms-para">
                LionPay serves merchants, influencers, digital nomads, and
                online entrepreneurs with a flexible, decentralized, and
                user-friendly financial ecosystem. Through a strong referral and
                cashback system, LionPay not only enables cost-effective
                financial transactions but also creates new income opportunities
                for users worldwide.
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Vision</p>
              <p className="mb-0 terms-para">
                Bridging the gap between traditional banking and digital
                finance.
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Mission</p>
              <ul>
                <li className="mb-2 terms-para">
                  Enable global financial freedom through crypto & fiat
                  integration. .
                </li>
                <li className="mb-2 terms-para">
                  Provide secure & instant transactions without unnecessary
                  banking bureaucracy. .
                </li>
                <li className="mb-2 terms-para">
                  Offer a payment system that directly rewards merchants &
                  influencers. .
                </li>
                <li className="mb-2 terms-para">
                  Introduce customizable merchant loyalty cards with cashback
                  solutions. .
                </li>
                <li className="mb-2 terms-para">
                  Facilitate access to insurance & financial services for
                  digital nomads. .
                </li>
                <li className="mb-2 terms-para">
                  Deliver revolutionary AI-driven credit limits for all users. .
                </li>
              </ul>
              <p className="mb-0 terms-para">
                LionPay is creating a new banking ecosystem that combines
                freedom, simplicity, and cost efficiency.
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">
                AI-Powered Credit Limit – A Game-Changer in Finance
              </p>
              <h6>
                Instant Credit – No Bureaucracy, No Third-Party Credit Scoring
              </h6>
              <p className="mb-2 terms-para">
                LionPay provides every user with an AI-determined credit limit,
                eliminating the need for traditional banks or credit scoring
                agencies.
              </p>
              <h6>How It Works:</h6>
              <p className="mb-2 terms-para">
                Our AI analyzes spending behavior, transaction history, and
                usage patterns in real-time to determine the user’s available
                credit limit.
              </p>
              <h6>How It Works:</h6>
              <ul>
                <li className="mb-0 terms-para">
                  No external credit scoring from banks or financial
                  institutions .
                </li>
                <li className="mb-0 terms-para">
                  Instant access to liquidity – no application process .
                </li>
                <li className="mb-0 terms-para">
                  Automatic credit limit adjustments based on user activity .
                </li>
                <li className="mb-2 terms-para">
                  Maximum financial flexibility for digital nomads,
                  entrepreneurs, and merchants .
                </li>
              </ul>
              <h6>Use Case: A Business Owner with Irregular Cash Flow</h6>
              <p className="mb-2 terms-para">
                An e-commerce entrepreneur with fluctuating revenues receives a
                dynamically adjusted credit limit tailored to their business
                activities—no credit checks, no waiting, no banks—just instant
                access to funds.
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">
                Technical Infrastructure & Blockchain Details
              </p>
              <p className="mb-2 terms-para">
                LionPay is built on the Solana blockchain, known for its
                high-speed transactions, low fees, and scalability.
              </p>
              <h6>How It Works:</h6>
              <ul>
                <li className="mb-0 terms-para">
                  Decentralized & instant transactions with minimal fees. .
                </li>
                <li className="mb-0 terms-para">
                  Integration with leading crypto wallets for maximum
                  compatibility. .
                </li>
                <li className="mb-0 terms-para">
                  Smart contract security backed by third-party audits. .
                </li>
                <li className="mb-2 terms-para">
                  High transaction capacity (65,000 TPS) – perfect for merchants
                  & cashback programs. .
                </li>
              </ul>
              <h6>Why Solana?</h6>
              <ul>
                <li className="mb-0 terms-para">
                  Ultra-low transaction fees (less than $0.01 per transaction).
                  .
                </li>
                <li className="mb-0 terms-para">
                  High scalability for real-time financial transactions. .
                </li>
                <li className="mb-0 terms-para">
                  Sustainable Proof-of-History (PoH) consensus model for
                  energy-efficient processing. .
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 terms-heading">Limitation of Liability</p>
              <h6>Merchant Solutions & Cashback Programs</h6>
              <p className="mb-2 terms-para">
                LionPay enables merchants and online businesses to offer branded
                payment cards with custom loyalty and cashback rewards.
              </p>
              <h6>Merchant Benefits:</h6>
              <ul>
                <li className="mb-0 terms-para">
                  Custom Branding – Merchants can issue cards with their own
                  logos & designs .
                </li>
                <li className="mb-0 terms-para">
                  Cashback Rewards – Incentivize customer loyalty with instant
                  cashback .
                </li>
                <li className="mb-0 terms-para">
                  Lower transaction fees compared to Visa/Mastercard .
                </li>
              </ul>
              <small className="text-light ">
                Example: A Streetwear Online Store
              </small>
              <p className="mb-2 terms-para mt-2">
                A fashion brand offers a branded LionPay merchant card with
                cashback rewards, providing customers 1–3% cashback in LionPay
                tokens per purchase. Merchants benefit from lower fees & higher
                customer retention.
              </p>
              <h6>Influencers & Online Entrepreneurs</h6>
              <p className="mb-0 terms-para">
                LionPay’s low-cost transaction system allows influencers,
                digital entrepreneurs, and online earners to:{" "}
              </p>
              <ul>
                <li className="mb-0 terms-para">
                  Receive earnings in USD, Solana, or fiat .
                </li>
                <li className="mb-0 terms-para">
                  Transfer funds quickly & affordably .
                </li>
                <li className="mb-0 terms-para">
                  Offer cashback benefits to their community .
                </li>
              </ul>
              <small className="text-light">
                Example: A YouTuber with 500,000 Followers
              </small>
              <p className="mb-0 mt-2 terms-para">
                An influencer can offer their audience exclusive LionPay cards
                with cashback, earning a percentage of every transaction made by
                their fans—a win-win for creators & users.
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">
                Insurance Partnerships – Private Coverage & Financial Security
              </p>
              <p className="mb-0 terms-para">
                LionPay will collaborate with top-tier insurance providers to
                offer: ✔ Private health insurance for digital nomads &
                entrepreneurs
              </p>
              <ul>
                <li className="mb-0 terms-para">
                  Private pension plans integrated into our financial ecosystem
                  .
                </li>
                <li className="mb-2 terms-para">
                  Insurance solutions for digital assets & crypto holdings .
                </li>
              </ul>
              <p className="mb-0 terms-para">
                Our goal is to elevate financial services, providing secure,
                scalable solutions for the future.
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Tokenomics of the Pre-Sale</p>
              <h6>Token Distribution</h6>
              <p className="mb-2 terms-para">
                The LionPay token is the core of our financial ecosystem. During
                the pre-sale, tokens will be distributed as follows:
              </p>
              <ul>
                <li className="mb-0 terms-para">40% Referral & Marketing .</li>
                <li className="mb-2 terms-para">10–15% Liquidity Pool .</li>
                <li className="mb-2 terms-para">
                  Remaining for Treasury, Team, and Development .
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 terms-heading">Roadmap </p>
              <h6>Q2–Q4 2025 – Pre-Sale & Community-Building</h6>
              <ul>
                <li className="mb-0 terms-para">Launch of referral system .</li>
                <li className="mb-0 terms-para">
                  Token introduction & start of the pre-sale .
                </li>
                <li className="mb-2 terms-para">
                  Strategic influencer partnerships .
                </li>
              </ul>
              <h6>Q1–Q3 2026 – Product Development</h6>
              <ul>
                <li className="mb-0 terms-para">
                  Completion of wallet & payment infrastructure .
                </li>
                <li className="mb-0 terms-para">
                  Beta launch of LionPay for early adopters .
                </li>
                <li className="mb-2 terms-para">
                  Rollout of the merchant payment gateway .
                </li>
              </ul>
              <h6>Q4 2026 – Q1 2027 – Full Launch & Global Expansion</h6>
              <ul>
                <li className="mb-0 terms-para">Full platform deployment .</li>
                <li className="mb-0 terms-para">
                  Expansion into international markets .
                </li>
                <li className="mb-2 terms-para">
                  Introduction of new financial services .
                </li>
              </ul>
              <h6>Q2–Q4 2027 – Enhancements & Scaling</h6>
              <ul>
                <li className="mb-0 terms-para">
                  Integration of additional fiat & crypto services .
                </li>
                <li className="mb-0 terms-para">
                  Expansion of IBAN & credit card solutions .
                </li>
                <li className="mb-2 terms-para">
                  Stronger global reach & regulatory partnerships .
                </li>
              </ul>
              <h6>2028+ – Long-Term Vision</h6>
              <ul>
                <li className="mb-0 terms-para">
                  Potential acquisition of banking licenses or partnerships with
                  neobanks .
                </li>
                <li className="mb-0 terms-para">
                  Decentralized finance (DeFi) services for lending & staking .
                </li>
                <li className="mb-2 terms-para">
                  Additional cashback & rewards programs for high-volume users .
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 terms-heading">
                Legal Disclaimer & Risk Warning
              </p>
              <p className="mb-2 terms-para">
                LionPay is not a security, stock, or regulated financial
                product. Purchasing LionPay tokens does not guarantee any return
                on investment or price appreciation.
              </p>
              <p className="mb-2 terms-para">
                There is always a risk of total capital loss.
              </p>
              <p className="mb-2 terms-para">
                Every buyer acts at their own discretion and assumes full
                responsibility for their investment. LionPay is not a registered
                financial entity and is not subject to financial regulatory
                oversight.
              </p>
              <h6>Conclusion</h6>
              <p className="mb-2 terms-para">
                With its AI-powered credit system, advanced merchant solutions,
                cashback incentives, and insurance partnerships, LionPay is more
                than just a payment method—it’s a financial revolution.
              </p>
              <h5 className="mb-0 ">
                Join the pre-sale now and secure your LionPay tokens!
              </h5>
            </li>
          </ol>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Whitepaper;
