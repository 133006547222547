import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie'
import config from "../corefiles/config";
function ProtectedRoute({ children }) {
    // let loginData = (!Cookies.get('loginSuccessEuron')) ? [] : JSON.parse(Cookies.get('loginSuccessEuron'));
    let loginData=Cookies.get('loginSuccessEuron')
    loginData = loginData? loginData: null;
    if (!loginData || loginData.length == 0) {
        return <Navigate to={`${config.baseUrl}`} replace />
    }
    return children;
}
export default ProtectedRoute;
