import React from "react";

const PasswordResetSuccess = ({ handleNext, step, handleClose }) => (
  <div className="login d-flex justify-conntent-center align-items-center gap-3 flex-column h-100">
    {/* {step > 1 && (
      <div className="text-white" onClick={handleBack}>
        Back
      </div>
    )} */}

    <p className="login-header mb-0">Password Reset Successfully</p>



    {step < 5 ? (
      <div className="d-flex justify-content-center w-100">
        <div
          className="btn01  py-2"
          style={{ cursor: "pointer", maxWidth: 280, width: "100%" }}
          onClick={handleNext}
        >
          Continue
        </div>
      </div>

    ) : (

      <div className="d-flex justify-content-center w-100">
        <div
          className="btn01  py-2"
          style={{ cursor: "pointer", maxWidth: 280, width: "100%" }}
          onClick={handleClose}
        >
          Login
        </div>
      </div>
    )}
  </div>
);

export default PasswordResetSuccess;
