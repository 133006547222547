import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router";
import config from "../corefiles/config";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { UserLogin, getUserSession } from "../Action/action";
import { loginSchema } from "../pages/RegisterValidator";
import { Formik } from "formik";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const Login = ({ handleNext, step, handleBack }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading,setLoading] = useState(false)

  useEffect(()=>{
   
  },[])

  const handleSubmit = async (values) => {
    try {
      values.email = values.email.toLowerCase()
     setLoading(true)
      const res = await UserLogin(values);
      if (res.success) {
        Cookies.set("loginSuccessEuron", res.data.token);
        localStorage.setItem("Type", "influencer");
        toast.success(res.msg);
     setLoading(false)
        fetchSessionId(res.data.userId)
        setTimeout(() => {
          window.location.href = `${config.baseUrl}dashboard`;
        }, 1000);
      } else {
        setLoading(false)
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
      setLoading(false)
    }
  };

  const fetchSessionId = async(userId) =>{
    try {
      let res = await getUserSession({userId: userId})
      if(res.success){
        Cookies.set("sessionId", JSON.stringify(res.data[0]));
      }
    } catch (error) {
      toast.error("error", error.message);
      
    }
  }
  
  return (
    <>
      <div className="login">
        <p className="login-header">Welcome to LionPay!</p>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            errors,
            handleSubmit,
            handleBlur,
            handleChange,
            touched,
            values,
          }) => (
            <Form className="text-white" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="fs-14">
                  Email <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  className="py-2"
                  name="email"
                  placeholder="Enter your registered email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.email && errors.email && (
                  <div className="text-danger mt-1" style={{ fontSize: 14 }}>
                    {errors.email}
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-1" controlId="password">
                <Form.Label className="fs-14">
                  Password <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    className="py-2"
                    name="password"
                    placeholder="Enter your password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputGroup.Text
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <Eye /> : <EyeSlash />}
                  </InputGroup.Text>
                </InputGroup>
                {touched.password && errors.password && (
                  <div className="text-danger mt-1" style={{ fontSize: 14 }}>
                    {errors.password}
                  </div>
                )}
              </Form.Group>
              <div
                className="text-color text-end mb-3"
                style={{ cursor: "pointer", fontSize: 14 }}
                onClick={handleNext}
              >
                Forgot password?
              </div>
              <div className="d-flex justify-content-center flex-column align-items-center gap-2 mb-2 w-100">
                <button
                  className="btn01 py-2 w-100 "
                  style={{ cursor: "pointer", maxWidth: "300px" }}
                  disabled={loading ? true:false }
                  type="submit"
                >
                  {loading ? 'Loading...':"Login"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Login;
