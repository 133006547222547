import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../directives/Header";
import "../../styles/Footer.css";
import Footer from "../../directives/Footer";

const Privacy = () => {
  return (
    <>
      <div className=" w-100" style={{ zIndex: 1 }}>
        <Header />
      </div>
      <Container className="text-white " style={{ zIndex: 0 }}>
        <h1 className="mb-0 colortheme"> Privacy Policy</h1>
        <small>Effective from 20 Feb 2025</small>

        <div className="d-flex align-items-center gap-3 mt-3">
          <h4 className="text-nowrap mb-0 colortheme">Your Privacy Matters</h4>
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "#ffffff40",
              borderRadius: 10,
            }}
          ></div>
        </div>

        <div className="content mt-3">
          <ol className="terms-ol colortheme">
            <li>
              <p className="mb-2 terms-heading">Introduction</p>
              <p className="mb-0 terms-para">
                This Privacy Policy outlines how LionPay collects, uses, and
                protects user information. By using our platform, you consent to
                the practices described herein.{" "}
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Information Collection</p>
              <ul>
                <li className="mb-0 terms-para">
                  Basic account details such as username and email address.
                </li>
                <li className="mb-0 terms-para">
                  Transaction-related data necessary for the functionality of
                  LionPay.
                </li>
                <li className="mb-0 terms-para">
                  Device and usage data to improve platform security and
                  performance.
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 terms-heading">Data Protection & Security</p>
              <p className="mb-0 terms-para">
                LionPay implements reasonable security measures to protect user
                data. However, users acknowledge that no digital platform can be
                completely secure and should take their own precautions when
                using online services.{" "}
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Third-Party Disclosure</p>
              <p className="mb-0 terms-para">
                LionPay does not sell or share personal information with third
                parties, except as required by law or for operational purposes
                such as fraud prevention and platform security.{" "}
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">User Rights</p>
              <p className="mb-0 terms-para">Users have the right to:</p>
              <ul>
                <li className="mb-0 terms-para">
                  Access and update their personal information.
                </li>
                <li className="mb-0 terms-para">
                  Request deletion of their account and related data, subject to
                  legal obligations.
                </li>
                <li className="mb-0 terms-para">
                  Opt out of certain data collection practices where applicable.
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 terms-heading">Updates to Privacy Policy</p>
              <p className="mb-0 terms-para">
                We may update this Privacy Policy periodically. Users are
                encouraged to review it regularly to stay informed of any
                changes.{" "}
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Contact Information</p>
              <p className="mb-0 terms-para">
                For any privacy-related inquiries, users can contact our support
                team through the official LionPay website.{" "}
              </p>
            </li>
          </ol>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Privacy;
