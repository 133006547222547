import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../directives/Header";
import Footer from "../../directives/Footer";

const UserGuid = () => {
    return (
        <>
            <div className=" w-100 " style={{ zIndex: 1 }}>
                <Header />
            </div>
            <Container className="text-white " style={{ zIndex: 0 }}>


                <div className="step_container mb-4">
                    <h2 className="mb-4 colortheme">How to Buy LionPay Tokens?</h2>

                    <Row>
                        <Col xl={4} md={6} className="mb-5 box_shadow">
                            <div>
                                <div className="steps_tick">
                                    <span className="position-relative">01</span>
                                </div>

                                <div className="mt-4 ms-3">
                                    <h5 className="terms-para">Step 1: Login to Your Account</h5               >
                                    <p className="terms-para">Log into your LionPay account using your registered credentials. </p>
                                </div>
                            </div>
                        </Col>

                        <Col xl={4} md={6} className="mb-5 box_shadow">
                            <div>
                                <div className="steps_tick">
                                    <span className="position-relative">02</span>
                                </div>

                                <div className="mt-4 ms-3">
                                    <h5 className="terms-para">Step 2: Access the Buy Option</h5               >
                                    <p className="terms-para">Go to your Dashboard and click on the "Buy LionPay" option. </p>
                                </div>
                            </div>
                        </Col>

                        <Col xl={4} md={6} className="mb-5 box_shadow">
                            <div>
                                <div className="steps_tick">
                                    <span className="position-relative">03</span>
                                </div>

                                <div className="mt-4 ms-3">
                                    <h5 className="terms-para">Step 3: Enter Purchase Details</h5              >
                                    {/* {/ <p>Create an account on our platform.</p> /} */}
                                    <ul>
                                        <li className="terms-para">View the current LionPay price displayed. </li>
                                        <li className="terms-para">Enter the number of LionPay tokens you want to buy.</li>
                                        <li className="terms-para">The system will automatically calculate the equivalent amount in Solana (SOL) or USDT (SOL).</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xl={4} md={6} className="mb-5 box_shadow">
                            <div>
                                <div className="steps_tick">
                                    <span className="position-relative">04</span>
                                </div>

                                <div className="mt-4 ms-3">
                                    <h5 className="terms-para">Step 4: Select Payment Method</h5               >
                                    <p className="terms-para">Choose your preferred payment method:</p>
                                    <ul>
                                        <li className="terms-para">Cryptocurrency Wallet (Phantom or Solflare) </li>
                                        <li className="terms-para">MoonPay for fiat purchases </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        <Col xl={4} md={6} className="mb-5 box_shadow">
                            <div>
                                <div className="steps_tick">
                                    <span className="position-relative">05</span>
                                </div>

                                <div className="mt-4 ms-3">
                                    <h4 className="terms-para">Step 5: Complete Your Purchase</h4>
                                    <p className="terms-para">Click "Buy Now" to finalize your transaction. Once processed, the LionPay Tokens will be credited to your wallet. </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>


                <h1 className="mb-0 colortheme">User Guide for LoinPay Token Platform</h1>
                {/* {/ <small>(Pre-Sale Edition)</small> /} */}

                <div className="d-flex align-items-center gap-3 mt-3">
                    <h4 className="text-nowrap mb-0">
                        <img
                            src="images/lionlogo.svg"
                            className="img-fluid"
                            style={{ width: 60, height: 60 }}
                            alt=""
                        />
                    </h4>
                    <div
                        style={{
                            height: 1,
                            width: "100%",
                            backgroundColor: "#ffffff40",
                            borderRadius: 10,
                        }}
                    ></div>
                </div>

                <div className="content mt-3">
                    <ol className="terms-ol colortheme">
                        <li>
                            <p className="mb-2 terms-heading">Introduction</p>
                            <p className="mb-0 terms-para">
                                Welcome to the Loinpay Token platform, where users can participate in ICOs, refer friends for rewards, and influencers can earn commissions. This guide provides a step-by-step approach to using the platform efficiently.
                            </p>
                        </li>
                        <li>
                            <p className="mb-2 terms-heading">System Requirements</p>
                            <p className="mb-0 terms-para">
                                Ensure you have the following:
                            </p>

                            <ul>
                                <li className="mb-2 terms-para">A desktop with internet access.</li>
                                <li className="mb-2 terms-para">A supported web browser (Chrome, Firefox, Edge).</li>
                                <li className="mb-2 terms-para">A Solana cryptocurrency wallet (Phantom, Solflare).
                                </li>
                                <li className="mb-2 terms-para">MoonPay for fiat transactions.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className="mb-2 terms-heading">Installation Guide</p>
                            <p className="mb-0 terms-para fs-5 mt-3">Phantom Wallet Installation</p>
                            <ul>
                                <li className="mb-2 terms-para">
                                    Visit <a href="https://phantom.com/" target="_blank" rel="noopener noreferrer">Phantom Official Website.</a>
                                </li>
                                <li className="mb-2 terms-para">
                                    Choose your preferred browser (Chrome, Firefox, Edge) and download the extension.
                                </li>
                                <li className="mb-2 terms-para">
                                    Click "Add to Browser" and follow the on-screen instructions.

                                </li>
                                <li className="mb-2 terms-para">
                                    Once installed, open the extension and click "Create a New Wallet."
                                </li>
                                <li className="mb-2 terms-para">
                                    Securely store the secret recovery phrase provided.

                                </li>
                                <li className="mb-2 terms-para">
                                    Set a strong password for additional security.
                                </li>
                                <li className="mb-2 terms-para">
                                    Your Phantom Wallet is now ready for use.
                                </li>
                            </ul>
                            <p className="mb-0 terms-para fs-5 mt-3">
                                MoonPay
                            </p>

                            <ul>
                                <li className="mb-2 terms-para">
                                    Visit <a href="https://www.moonpay.com/" target="_blank" rel="noopener noreferrer">MoonPay.</a>
                                </li>
                                <li className="mb-2 terms-para">
                                    Click on "Sign Up" and provide your email address.

                                </li>
                                <li className="mb-2 terms-para">
                                    Complete the identity verification (KYC) process by uploading the necessary documents.
                                </li>
                                <li className="mb-2 terms-para">
                                    Link a preferred payment method (Credit/Debit card, Bank Transfer).

                                </li>
                                <li className="mb-2 terms-para">
                                    Your MoonPay account is now ready for fiat transactions.

                                </li>
                                <li className="mb-2 terms-para">
                                    Purchase USDT(Sol) or SOL from Moonpay in your Phantom or Solflare wallet and then you can flawlessly purchase LPAY tokens through the website.

                                </li>
                            </ul>

                            <p className="mb-0 terms-para fs-5 mt-3">
                                Solflare Wallet Installation

                            </p>

                            <ul>
                                <li className="mb-2 terms-para">
                                    Visit  <a href="https://solflare.com/" target="_blank" rel="noopener noreferrer">Solflare's official website.</a>
                                </li>
                                <li className="mb-2 terms-para">
                                    Visit the browser, select Chrome, Firefox, or Edge, and download the extension.
                                    Install and Open the Wallet


                                </li>
                                <li className="mb-2 terms-para">
                                    Click "Add to Browser" for extensions and follow the on-screen instructions.

                                </li>
                                <li className="mb-2 terms-para">
                                    Click "Create a New Wallet" to generate a new Solflare wallet.


                                </li>
                                <li className="mb-2 terms-para">
                                    Securely store the Secret Recovery Phrase provided. This is essential for wallet recovery.
                                </li>
                                <li className="mb-2 terms-para">
                                    Set a Strong Password: Create a strong and unique password for additional security.
                                </li>

                                <li className="mb-2 terms-para">
                                    Set a Strong Password: Create a strong and unique password for additional security.
                                </li>

                                <li className="mb-2 terms-para">
                                    Start Using the Solflare Wallet
                                </li>

                                <li>Your Solflare Wallet is now ready for use. You can now securely send, receive, and manage SOL and other SPL tokens.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p className="mb-2 terms-heading">
                                User Registration and Login
                            </p>

                            <p className="mb-0 terms-para fs-5 mt-3">Normal User Registration</p>

                            <ul>
                                <li className="mb-0 terms-para">
                                    Visit the LoinPay Token homepage.
                                </li>
                                <li className="mb-0 terms-para">
                                    Click on "SignUp/Register" at the top right.
                                </li>
                                <li className="mb-2 terms-para">
                                    Fill in the required details:

                                    <ul>
                                        <li className="mb-0 terms-para">Name</li>
                                        <li className="mb-0 terms-para">Email</li>
                                        <li className="mb-0 terms-para">Password</li>
                                        <li>Referral code (optional)</li>
                                    </ul>
                                </li>

                                <li className="mb-2 terms-para">Check your email for OTP sent for email verification.
                                </li>
                                <li className="mb-2 terms-para">Log in using your credentials.
                                </li>
                                <li className="mb-2 terms-para">Connect your wallet to start participating in ICOs.</li>
                            </ul>
                            <p className="mb-0 terms-para fs-5 mt-3">Influencer Registration</p>

                            <ul>
                                <li className="mb-0 terms-para">
                                    Visit the LoinPay Token homepage.
                                </li>
                                <li className="mb-0 terms-para">
                                    Click "Apply as an Influencer."
                                </li>
                                <li className="mb-0 terms-para">
                                    Fill in the following details:

                                    <ul>
                                        <li className="mb-0 terms-para">Name</li>
                                        <li className="mb-0 terms-para">Email</li>
                                        <li className="mb-0 terms-para">Create Password</li>
                                        <li className="mb-0 terms-para">Mode of Communication</li>
                                        <li className="mb-0 terms-para">Wallet Address</li>
                                        <li className="mb-0 terms-para">Social Media Link</li>
                                        <li className="mb-0 terms-para">Audience Description</li>
                                        <li>Country</li>
                                        <li>Video Links (if applicable)</li>
                                    </ul>
                                </li>

                                <li className="mb-0 terms-para">
                                    Click "Submit."
                                </li>

                                <li className="mb-0 terms-para">
                                    Wait for approval from the LoinPay team.
                                </li>

                                <li className="mb-0 terms-para">
                                    Upon approval, log in and access the influencer dashboard.
                                </li>

                                <p className="mb-0 terms-para fs-5 mt-3">Dashboard Access and Features</p>
                                <ul>
                                    <li className="mb-0 terms-para">Log in and access the influencer dashboard.</li>
                                    <li className="mb-0 terms-para">View referral earnings and statistics.</li>
                                    <li className="mb-0 terms-para">Generate and manage referral links.
                                    </li>
                                    <li className="mb-0 terms-para">Receive updates and promotional opportunities.
                                    </li>
                                </ul>

                                <p className="mb-0 terms-para fs-5 mt-3">Referral Program</p>

                                <p className="mb-0 terms-para fs-5 mt-3">Referral Link Generation and Usage</p>

                                <ul>
                                    <li className="mb-0 terms-para">Influencers receive a unique referral link after approval.</li>
                                    <li className="mb-0 terms-para">Copy the referral link from the influencer dashboard.
                                    </li>
                                    <li className="mb-0 terms-para">Share the link via social media, email, or messaging apps.</li>
                                </ul>

                                <p className="mb-0 terms-para fs-5 mt-3">Referral Earnings and Rewards</p>

                                <ul>
                                    <li className="mb-0 terms-para">When a new user signs up using the referral link, the influencer earns a commission.</li>
                                    <li className="mb-0 terms-para">Commissions are dynamic and Set by the admin ranging from 0% to 50%.</li>
                                    <li className="mb-0 terms-para">Earnings are credited to the influencer’s wallet in Solana or USDT(SOL)</li>
                                </ul>
                            </ul>
                        </li>
                        <li>
                            <p className="mb-2 terms-heading">
                                ICO Participation Guide
                            </p>
                            <p>
                                Buy Token
                            </p>
                            <ul>
                                <li className="mb-0 terms-para">
                                    The User or Influencer logs into their LionPay account.
                                </li>
                                <li className="mb-0 terms-para">
                                    Once logged in, the user can see an option to <b>Buy LionPay</b> on the Dashboard.
                                </li>
                                <li className="mb-0 terms-para">
                                    The User clicks on the “Buy LionPay” option to proceed with the purchase.

                                </li>
                                <li className="mb-2 terms-para">
                                    <b>Enter Purchase Details:</b> On the purchase screen, the current LionPay Price is shown. The User enters the desired amount of LionPay tokens they wish to buy, and the equivalent value will be automatically calculated in the chosen cryptocurrency (either Solana or USDT (SOL)).

                                </li>


                                <li className="mb-2 terms-para"><b>Select Payment Method:</b> The User can choose to pay via their preferred cryptocurrency wallet (Phantom or Solflare) or use MoonPay as a payment method.
                                </li>

                                <li className="mb-2 terms-para">
                                    <b>Buy Now:</b> After reviewing the details, the User clicks on the “Buy Now” button to finalize and complete the purchase of the LionPay tokens.

                                </li>

                                <li className="mb-2 terms-para">Once the purchase is successfully processed, the LionPay tokens will be reflected in the User's wallet.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default UserGuid;
