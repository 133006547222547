import React, { useEffect, useState } from "react";
import DashboardHeader from "../component/DashboardHeader";
import Sidebar from "../component/Sidebar";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../styles/Dashboard.css";
import "../styles/Custom.css";
import { getProfileDetails, updateProfileDetails } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import { useHandleBack } from "../utilis/utilis";
import { useNavigate } from "react-router";
import config from "../corefiles/config";

const ResubmitForm = ({ special, clearModalData }) => {
	const [formData, setFormData] = useState({
		socialMediaLink: "",
		description: "",
		// AdminApproval: "0",
	});

	useEffect(() => {
		GetProfile();
	}, []);
	const [errors, setErrors] = useState({});

	const GetProfile = async () => {
		try {
			const res = await getProfileDetails();
			if (res.success) {
				const data = res.data;
				setFormData((prev) => ({ ...prev, ...data }));
			} else {
				toast.error(res.msg);
			}
		} catch (error) {
			console.error("Login Error:", error);
			toast.error("Something went wrong. Please try again later.");
		}
	};
	const handleBack = useHandleBack();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const navigate = useNavigate()
	const handleUpdateProfile = async () => {
		let newErrors = {};


		// if (!formData.socialMediaLink.trim()) {
		// 	newErrors.socialMediaLink = "Social Media Link is required";
		// } else if (!isValidURL(formData.socialMediaLink)) {
		// 	newErrors.socialMediaLink = "Enter a valid social media URL";
		// }



		// Description validation (min: 10, max: 500)
		if (!formData.description.trim()) {
			newErrors.description = "Description is required";
		} else if (formData.description.length < 2) {
			newErrors.description = "Description must be at least 2 characters";
		} else if (formData.description.length > 400) {
			newErrors.description = "Description must not exceed 400 characters";
		}

		// If errors exist, set errors and stop execution
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		// Add admin approval
		formData.adminApproval = "0";

		try {
			const res = await updateProfileDetails(formData);
			if (res.success) {
				toast.success("Profile updated successfully!");
				setTimeout(() => {
					navigate(`${config.baseUrl}dashboard`);
				}, 2000);
			} else {
				toast.error(res.msg);
			}
		} catch (error) {
			console.error("Profile Update Error:", error);
			toast.error("Something went wrong. Please try again later.");
		}
	};

	// Helper function to validate URLs
	const isValidURL = (url) => {
		const pattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w]{2,}(:\d+)?(\/.*)?$/i;
		return pattern.test(url);
	};

	return (
		<>
			<Toaster />
			<div className="w-100" style={{ height: "100vh", overflow: "hidden" }} >

				<div className="d-flex  w-100"  >
					<div >
						<Sidebar special={special} clearModalData={clearModalData} />
					</div>
					<div className="w-100" style={{ height: "100%" }}>
						<DashboardHeader />
						<div
							className="mt-5 mt-lg-4 px-4 px-lg-5  wrapper-dash"
							style={{
								// padding: "15px",
								flex: "1",
								backgroundColor: "#100500",
								color: "#ffffff",
								height: "87vh",
								overflowY: "scroll"
							}}
						>
							<h2 className=" dash-header">Resubmit Social Information</h2>

							<br />
							<div className="profile-container dash-gradient2 h-auto p-4">
								<Form>
									<Row className="gy-2">
										{/* Video URL */}
										

										{/* Social Media Link */}
										{/* <Col lg={6}> */}
											{/* <Form.Group className="mb-3" controlId="socialMediaLink">
												<Form.Label>
													Social Media Link <span style={{ color: "red" }}>*</span>
												</Form.Label>
												<div className="position-relative">
													<Form.Control
														type="text"
														// className="py-3"
														name="socialMediaLink"
														value={formData.socialMediaLink}
														placeholder="Enter social media link"
														onChange={handleInputChange}
													/>
													{errors.socialMediaLink && <small className="text-danger">{errors.socialMediaLink}</small>}
												</div>
											</Form.Group> */}
										{/* </Col> */}

										

										{/* Description */}
										<Col lg={12}>
											<Form.Group className="mb-3" controlId="description">
												<Form.Label>Description</Form.Label>
												<div className="position-relative">
													<Form.Control
														type="text"
														// className="py-3"
														name="description"
														value={formData.description}
														placeholder="Enter description"
														onChange={handleInputChange}
													/>
													{errors.description && <small className="text-danger">{errors.description}</small>}
												</div>
											</Form.Group>
										</Col>

										{/* Buttons */}
										<Col lg={6}>
											<div className="profile-update-wrap">
												<div className="profile-update header-text" style={{ cursor: "pointer" }} onClick={handleBack}>
													<p className="header-text mb-0 fw-800">Back</p>
												</div>
											</div>
										</Col>

										<Col lg={6}>
											<div className="btn01 px-4" style={{ padding: "11px 0px", cursor: "pointer" }} onClick={handleUpdateProfile}>
												Update Details
											</div>
										</Col>
									</Row>
								</Form>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResubmitForm;
