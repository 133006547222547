import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../styles/Custom.css";
import { Modal } from "react-bootstrap";
import LoginHome from "../Auth/LoginHome";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import config from "../corefiles/config";
import { getAllSettingAction } from "../Action/action";

const Header = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleSignupClick = () => {
    navigate(`${config.baseUrl}?signup=true`);
    // window.dispatchEvent(new CustomEvent("openSignupModal"));
    setShow(false);
  };


  useEffect(() => {

    const handleOpenLoginModal = () => setShow(true);
    window.addEventListener("openLoginModal", handleOpenLoginModal);

    return () => {
      window.removeEventListener("openLoginModal", handleOpenLoginModal);
    };
  }, []);

  let loginData = Cookies.get("loginSuccessEuron");
  const handleClose2 = () => setShow(false);

  const handleCloseSidebar = () => {
    document.getElementsByClassName('btn-close')[0].click()
  }

  

  return (
    <>

      <div className="header">
        {["xl"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            className=" mb-3 navbar-background "
          >
            <Container className="py-1">
              <Link to={`${config.baseUrl}`}>
                {" "}
                <img
                  src={`${config.baseUrl}images/logoLionIcon.png`}
                  style={{ width: 224, height: 53, objectFit: "contain" }}
                  alt=""
                />
              </Link>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                className="offcanvas-body01"
                id={`offcanvasNavbar-expand-${expand} `}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header className="navbar-background" closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <Link to={`${config.baseUrl}`}>
                      {" "}
                      <img
                        src={`${config.baseUrl}images/logoLionIcon.png`}
                        style={{ height: 53, objectFit: "cover" }}
                        alt=""
                      />
                    </Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvas-body01">
                  <Nav className="justify-content-center gap-3 flex-grow-1 text-white pe-3">
                    <Nav.Link
                      href={config.baseUrl}
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href={`${config.baseUrl}#s3feature`}
                      className="text-white"
                      onClick={handleCloseSidebar}
                      style={{ textDecoration: "none" }}
                    >
                      Features
                    </Nav.Link>
                    <Nav.Link
                      href={`${config.baseUrl}#s4tokenomics`}
                      className="text-white"
                      style={{ textDecoration: "none" }}
                      onClick={handleCloseSidebar}
                    >
                      Tokenomics
                    </Nav.Link>
                    <Nav.Link
                      href={`${config.baseUrl}#s5roadmap`}
                      className="text-white"
                      style={{ textDecoration: "none" }}
                      onClick={handleCloseSidebar}
                    >
                      Roadmap
                    </Nav.Link>
                    <Nav.Link
                      href={`${config.baseUrl}whitepaper`}
                      className="text-decoration-none text-white"
                      target="_blank"
                    >
                      Whitepaper
                    </Nav.Link>
                    <Nav.Link
                      href={`${config.baseUrl}user-guid`}
                      className="text-decoration-none text-white"
                      target="_blank"
                    >
                      User Guide
                    </Nav.Link>
                  </Nav>
                  <div className="d-flex gap-3 lg-sp">
                    {loginData ? (
                      <div
                        className="py-1 learn-more d-flex px-4 justify-content-center align-items-center text-white w-100"
                        style={{
                          width: "fit-content",
                          minHeight: 40,
                          borderRadius: 50,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          (window.location.href = `${config.baseUrl}dashboard`)
                        }
                      >
                        Dashboard
                      </div>
                    ) : (
                      <>
                        <div
                          className="btn01 px-4 py-1 w-100"
                          style={{
                            width: "fit-content",
                            minHeight: 40,
                            borderRadius: 50,
                            cursor: "pointer",
                          }}
                          onClick={() => setShow(true)}
                        >
                          Login
                        </div>

                        <div
                          className="btn01 px-4 py-1 w-100"
                          style={{
                            minHeight: 40,
                            borderRadius: 50,
                            minWidth: 100,
                            cursor: "pointer",
                          }}
                          onClick={handleSignupClick}
                        >
                          Sign Up
                        </div>
                      </>
                    )}
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>

      {loginData ? '' : 
      <Modal
      show={show}
      centered
      handleclose={handleClose2}
      backdropClassName="custom-backdrop"
      dialogClassName="custom-modal"
    >
      <Modal.Body closeButton>
        <div className=" pt-0">
          <div
            className="d-flex justify-content-end"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShow(false);
            }}
          >
            <div className="mod-cross  p-1 mb-2">
              <img src={`${config.baseUrl}images/close2.png`} alt="" />
            </div>
          </div>
          <LoginHome />

          <p className=" mb-0 text-white text-center fs-14">
            Don’t have an account?{" "}
            <span
              onClick={handleSignupClick}
              className="text-color"
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              {" "}
              Sign up
            </span>
          </p>
        </div>
      </Modal.Body>
    </Modal>
      }
      
    </>
  );
};

export default Header;
