import React, { useEffect, useState } from "react";
import DashboardHeader from "../component/DashboardHeader";
import { Col, Container, Form, Modal, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router";
import "../styles/Buynow.css";
import config from "../corefiles/config";
import Select from "react-select";
import Sidebar from "../component/Sidebar";
import { Oval } from "react-loader-spinner";
import { getAllSettingAction, tokenTransferAction } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
} from "@solana/web3.js";
import { Buffer } from "buffer";
import {
  getAssociatedTokenAddress,
  createTransferInstruction,
  createAssociatedTokenAccountInstruction,
} from "@solana/spl-token";
import { isMobile } from "react-device-detect";

const BuyNow = ({ special, clearModalData }) => {
  return (
    <>
      <Toaster />
      <div className="w-100">
        <div className="d-flex  w-100 h-100">
          <div>
            <Sidebar special={special} clearModalData={clearModalData} />
          </div>
          <div className="w-100 " style={{ height: "100%" }}>
            <DashboardHeader />

            <Container
              className="mn-container  text-white position-relative"
              style={{ height: "100%", top: 0, overflow: "hidden" }}
            >
              <div
                className=" d-flex    align-items-center flex-column h-100 "
                style={{ top: 0 }}
              >
                <Container className=" d-flex justify-content-end align-items-center flex-column">
                  <div className="login-header" style={{ zIndex: 1 }}>
                    Buy LionPay Token
                  </div>

                  <Link to={`${config.baseUrl}user-guid`} target="blank" className="text-white">
                    <p>How to buy LionPay Tokens?</p>
                  </Link>
                  <br />
                  <div className="buy-now-tab02 ">
                    <div className="buy-now-tab">
                      <ComponentOne />
                    </div>

                    {/* <div className="motion-tab motion-tab1 rotate-center">
                      <img src="images/beicon02.svg" alt="" />
                    </div>
                    <div className="motion-tab motion-tab3 rotate-center2">
                      <img src="images/beicon02.svg" alt="" />
                    </div>
                    <div className="motion-tab motion-tab4 rotate-center">
                      <img src="images/beicon02.svg" alt="" />
                    </div>
                    <div className="motion-tab motion-tab5 rotate-center2">
                      <img src="images/beicon02.svg" alt="" />
                    </div>
                    <div className="motion-tab motion-tab6 rotate-center">
                      <img src="images/beicon02.svg" alt="" />
                    </div>
                    <div className="motion-tab motion-tab7 rotate-center2">
                      <img src="images/beicon02.svg" alt="" />
                    </div>
                    <div className="motion-tab motion-tab8 rotate-center">
                      <img src="images/beicon02.svg" alt="" />
                    </div> */}
                  </div>
                  <br />
                </Container>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

const ComponentOne = () => {
  const [euronValue, setEuronValue] = useState("");
  const [convertedValue, setConvertedValue] = useState("");
  const [conversionRate, setConversionRate] = useState({
    usdt: null,
    sol: null,
  });
  const options = [
    {
      value: "SOLANA",
      label: (
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="text-white"
        >
          <img
            src="https://cryptologos.cc/logos/solana-sol-logo.png"
            alt="SOLANA"
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          Solana
        </div>
      ),
    },
    {
      value: "USDT",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://cryptologos.cc/logos/tether-usdt-logo.png"
            alt="USDT"
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          USDT (SOL)
        </div>
      ),
    },
  ];
  const [lastInput, setLastInput] = useState();
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [walletAddress, setWalletAddress] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [balance, setBalance] = useState(null);
  const network = config.clusterUri;
  const [txHash, setTxHash] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allSetting, setAllSetting] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  const USDT_MINT_ADDRESS = new PublicKey(config.USDTToken);



  useEffect(() => {
    if (typeof window !== "undefined") {
      window.Buffer = Buffer;
    }
  }, []);

  useEffect(() => {
    getAllSetting();
  }, []);

  const getAllSetting = async () => {
    const res = await getAllSettingAction();
    if (res.success) {
      setAllSetting(res.data);
      setLoadingData(true);
    } else {
      setLoadingData(true);
    }
  };

  const handleChange = (selected) => {
    setSelectedWallet("");
    setSelectedOption(selected);
    if (lastInput == 1) {
      updateConvertedValue(euronValue, selected.value);
    } else {
      updateConvertedValue2(convertedValue, selected.value);
    }
  };

  const handleConvertedChange = (e) => {
    const value = e.target.value;
    setLastInput(2);
    setConvertedValue(value);
    if (!value || isNaN(value)) {
      setEuronValue("");
      return;
    }
    const euronPrice = allSetting?.usdprice ? allSetting?.usdprice : 1;
    let amountInEuron;
    if (selectedOption.value === "USDT") {
      amountInEuron =
        (value * parseFloat(allSetting?.usdTetherPrice).toFixed(2)) / euronPrice;
    } else if (selectedOption.value === "SOLANA") {
      amountInEuron = (value * allSetting?.solanaPriceUsd) / euronPrice;
    } else {
      amountInEuron = value / euronPrice;
    }
    setEuronValue(amountInEuron.toFixed(2));
  };

  const handleEuronChange = (e) => {
    const value = e.target.value;
    setLastInput(1);
    setEuronValue(value);
    if (!value || isNaN(value)) {
      setConvertedValue("");
      return;
    }

    const euronPrice = allSetting?.usdprice || 0; // Price of 1 Euron in USD
    const amountInUSD = value * euronPrice;
    let converted;
    if (selectedOption.value === "USDT") {
      converted =
        amountInUSD / (parseFloat(allSetting?.usdTetherPrice).toFixed(2) || 1);
      setConvertedValue(converted.toFixed(2));
    } else if (selectedOption.value === "SOLANA") {
      let oneS = parseFloat(allSetting?.usdprice / allSetting?.solanaPriceUsd).toFixed(
        6
      );
      converted = value * oneS;
      setConvertedValue(converted.toFixed(4));
    } else {
      converted = amountInUSD;
    }
  };

  const updateConvertedValue = (euronAmount, currency) => {
    if (!euronAmount || isNaN(euronAmount)) {
      setConvertedValue("");
      return;
    }
    const euronPrice = allSetting?.usdprice || 1;
    const amountInUSD = parseFloat(euronAmount) * parseFloat(euronPrice);

    let converted;
    if (currency === "USDT") {
      // converted = amountInUSD / (allSetting?.usdTetherPrice || 1);
      converted = amountInUSD;
      setConvertedValue(converted.toFixed(2));
    } else if (currency === "SOLANA") {
      let oneS = parseFloat(allSetting?.usdprice / allSetting?.solanaPriceUsd).toFixed(
        6
      );
      converted = euronAmount * oneS;
      setConvertedValue(converted.toFixed(6));
    } else {
      converted = amountInUSD;
    }
  };

  const updateConvertedValue2 = (convertedValueAmount, currency) => {
    if (!convertedValueAmount || isNaN(convertedValueAmount)) {
      setEuronValue("");
      return;
    }
    const euronPrice = allSetting?.usdprice || 1;
    const amountInUSD = convertedValueAmount / euronPrice;

    let converted;
    if (currency === "USDT") {
      converted = amountInUSD;
    } else if (currency === "SOLANA") {
      converted = amountInUSD * (allSetting?.solanaPriceUsd || 1);
    } else {
      converted = amountInUSD;
    }
    setEuronValue(converted.toFixed(2));
  };

  const handleSubmit = async () => {
    if (!euronValue) {
      toast.error("Please enter the valid token amount.");
      return;
    }
    if (!convertedValue || convertedValue <= 0) {
      toast.error("Converted value Required");
      return;
    }
    if (!selectedWallet) {
      toast.error("Please select at least one payment method!");
      return;
    }

    if (selectedOption.value === "SOLANA") {
      if (!selectedWallet) {
        toast.error("Please select at least one Wallet");
        return;
      }
    }

    if (
      selectedOption.value?.toUpperCase() === "SOLANA" &&
      selectedWallet?.toUpperCase() === "PHANTOM"
    ) {
      await phantomWalletConnect();
      return;
    }

    if (selectedOption.value === "USDT" && selectedWallet == "Phantom") {
      connectWalletPhantom();
    }

    if (selectedOption.value === "SOLANA" && selectedWallet == "Solflare") {
      solflareWalletConnect();
    }

    if (selectedOption.value === "USDT" && selectedWallet == "Solflare") {
      connectWalletSolflare();
    }
  };

  //=====================================  USDT WALLET WITH PHANTOM =========================================

  const connectWalletPhantom = async () => {
    try {
      if (window.solana) {
        const response = await window.solana.connect();
        setWalletAddress(response.publicKey.toString());
        sendUsdt(response.publicKey);
      } else {
        toast.error("Phantom Wallet not found. Please install the extension.");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const connectWalletSolflare = async () => {
    try {
      if (window.solflare) {
        try {
          await window.solflare.connect();
          if (window.solflare.publicKey) {
            const walletAddress = window.solflare.publicKey.toString();
            setWalletAddress(walletAddress);

            // Fetch SOL balance
            const connection = new Connection(config.clusterUri, "confirmed");
            const balance = await connection.getBalance(
              new PublicKey(walletAddress)
            );
            const solBalance = balance / 1e9; // Convert lamports to SOL (1 SOL = 10^9 lamports)

            if (solBalance > 0) {
              sendUsdtSolflare(walletAddress);
            } else {
              toast.error(
                "Your SOL balance is 0. Please deposit SOL to continue."
              );
            }
          }
        } catch (error) {
          toast.error("Connection failed. Try again.");
        }
      } else {
        toast.error("Solflare Wallet not found. Please install the extension.");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const phantomWalletConnect = async () => {
    try {
      if (window.solana && window.solana.isPhantom) {
        const response = await window.solana.connect();
        const publicKey = response.publicKey.toString();
        setWalletAddress(publicKey);
        const balance = await fetchBalance(response.publicKey);
        if (parseFloat(balance) > 0) {
          await sendSol(publicKey);
        } else {
          toast.error(`You don't have sufficient balance.`);
        }
      } else {
        if (isMobile) {


          const encoded = encodeURIComponent(config.refferalUrl);
          const encodedUrlWithParams = encodeURIComponent(`${config.refferalUrl}buyLionPay`);

          window.open(`https://phantom.app/ul/browse/${encodedUrlWithParams}?ref=${encoded}`, '_blank');

          return;
        }
        else {

          // Open Phantom Mobile app for connection
          window.open(`https://phantom.app/ul/v1/connect?app_url=${config.refferalUrl}`, '_blank');

        }
      }
    } catch (error) {
      setLoading(false);
    }
  };


  const solflareWalletConnect = async () => {
    try {
      if (window.solflare) {
        try {
          await window.solflare.connect();
          if (window.solflare.publicKey) {
            const walletAddress = window.solflare.publicKey.toString();
            setWalletAddress(walletAddress);

            // Fetch SOL balance
            const connection = new Connection(config.clusterUri, "confirmed");
            const balance = await connection.getBalance(
              new PublicKey(walletAddress)
            );
            const solBalance = balance / 1e9; // Convert lamports to SOL (1 SOL = 10^9 lamports)

            if (solBalance > 0) {
              sendSolSolflare(walletAddress);
            } else {
              toast.error(
                "Your SOL balance is 0. Please deposit SOL to continue."
              );
            }
          }
        } catch (error) {
          toast.error("Connection failed. Try again.");
        }
      } else {
        if (isMobile) {


          const encoded = encodeURIComponent(config.refferalUrl);
          const encodedUrlWithParams = encodeURIComponent(`${config.refferalUrl}buyLionPay`);

          window.open(`https://solflare.com/ul/v1/browse/${encodedUrlWithParams}?ref=${encoded}`, '_blank');

          return;
        }
        else {

          // Open Phantom Mobile app for connection
          window.open(`https://solflare.com/ul/v1/connect?app_url=${config.refferalUrl}`, '_blank');

        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const sendUsdt = async (walletAddressData) => {
    setWalletAddress(walletAddressData);
    let recipient = config.payoutWallet;
    if (!walletAddressData) return alert("🚨 Connect Phantom Wallet first!");
    if (!recipient) return alert("🚨 Enter a valid recipient address!");

    setLoading(true);
    try {
      const connection = new Connection(network, "confirmed");
      const senderPublicKey = new PublicKey(walletAddressData);
      const recipientPublicKey = new PublicKey(recipient);

      const senderTokenAddress = await getAssociatedTokenAddress(
        USDT_MINT_ADDRESS,
        senderPublicKey
      );
      const recipientTokenAddress = await getAssociatedTokenAddress(
        USDT_MINT_ADDRESS,
        recipientPublicKey
      );

      // Check if recipient's token account exists
      const recipientAccountInfo = await connection.getAccountInfo(
        recipientTokenAddress
      );
      if (!recipientAccountInfo) {
        const createAccountIx = createAssociatedTokenAccountInstruction(
          senderPublicKey, // Payer
          recipientTokenAddress, // ATA to create
          recipientPublicKey, // Token account owner
          USDT_MINT_ADDRESS // Token mint
        );

        const createTx = new Transaction().add(createAccountIx);
        createTx.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
        createTx.feePayer = senderPublicKey;

        const signedCreateTx = await window.solana.signTransaction(createTx);
        const createTxSignature = await connection.sendRawTransaction(
          signedCreateTx.serialize()
        );
        await connection.confirmTransaction(createTxSignature);
      }

      // Proceed with USDT Transfer
      const transaction = new Transaction().add(
        createTransferInstruction(
          senderTokenAddress,
          recipientTokenAddress,
          senderPublicKey,
          Math.round(convertedValue * config.usdtDecimals) // Rounds to nearest integer
        )
      );

      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      transaction.feePayer = senderPublicKey;

      const signedTransaction = await window.solana.signTransaction(
        transaction
      );
      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize()
      );
      await connection.confirmTransaction(signature);
      setTxHash(signature);
      tokenTransfer(signature, walletAddressData);
    } catch (error) {
      toast.error("🚨 Transaction Failed!");
    } finally {
      setLoading(false);
    }
  };

  const sendUsdtSolflare = async (walletAddressData) => {
    setWalletAddress(walletAddressData);
    let recipient = config.payoutWallet;
    if (!walletAddressData) return alert("🚨 Connect Phantom Wallet first!");
    if (!recipient) return alert("🚨 Enter a valid recipient address!");

    setLoading(true);
    try {
      const connection = new Connection(network, "confirmed");
      const senderPublicKey = new PublicKey(walletAddressData);
      const recipientPublicKey = new PublicKey(recipient);

      const senderTokenAddress = await getAssociatedTokenAddress(
        USDT_MINT_ADDRESS,
        senderPublicKey
      );
      const recipientTokenAddress = await getAssociatedTokenAddress(
        USDT_MINT_ADDRESS,
        recipientPublicKey
      );

      // Check if recipient's token account exists
      const recipientAccountInfo = await connection.getAccountInfo(
        recipientTokenAddress
      );
      if (!recipientAccountInfo) {
        const createAccountIx = createAssociatedTokenAccountInstruction(
          senderPublicKey, // Payer
          recipientTokenAddress, // ATA to create
          recipientPublicKey, // Token account owner
          USDT_MINT_ADDRESS // Token mint
        );

        const createTx = new Transaction().add(createAccountIx);
        createTx.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
        createTx.feePayer = senderPublicKey;

        const signedCreateTx = await window.solflare.signTransaction(createTx);
        const createTxSignature = await connection.sendRawTransaction(
          signedCreateTx.serialize()
        );
        await connection.confirmTransaction(createTxSignature);
      }

      // Proceed with USDT Transfer
      const transaction = new Transaction().add(
        createTransferInstruction(
          senderTokenAddress,
          recipientTokenAddress,
          senderPublicKey,
          Math.round(convertedValue * config.usdtDecimals) // Rounds to nearest integer
        )
      );

      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      transaction.feePayer = senderPublicKey;

      const signedTransaction = await window.solflare.signTransaction(
        transaction
      );
      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize()
      );
      await connection.confirmTransaction(signature);
      setTxHash(signature);
      tokenTransfer(signature, walletAddressData);
    } catch (error) {
      toast.error("🚨 Transaction Failed!");
    } finally {
      setLoading(false);
    }
  };

  // Fetch Wallet Balance
  const fetchBalance = async (publicKey) => {
    try {
      const connection = new Connection(network, "confirmed");
      const balance = await connection.getBalance(publicKey);
      const solBalance = balance / 1_000_000_000; // Convert lamports to SOL
      setBalance(solBalance);
      return solBalance;
    } catch (error) {
      return 0;
    }
  };

  // Send SOL
  const sendSol = async (walletAddress) => {

    let recipient = config.payoutWallet;
    setLoading(true);
    try {
      if (parseFloat(convertedValue) > parseFloat(balance)) {
        toast.error("Insufficient Balance");
        setLoading(false);
        return;
      }

      const connection = new Connection(network, "confirmed");
      const senderPublicKey = new PublicKey(walletAddress);
      const recipientPublicKey = new PublicKey(recipient);

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: senderPublicKey,
          toPubkey: recipientPublicKey,
          lamports: Math.round(parseFloat(convertedValue) * 1_000_000_000)
        })
      );

      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      transaction.feePayer = senderPublicKey;

      const signedTransaction = await window.solana.signTransaction(
        transaction
      );
      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize()
      );
      await connection.confirmTransaction(signature);

      setTxHash(signature);
      if (signature) {
        tokenTransfer(signature, walletAddress);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const sendSolSolflare = async (walletAddress) => {
    let recipient = config.payoutWallet;
    setLoading(true);

    try {
      if (parseFloat(convertedValue) > parseFloat(balance)) {
        toast.error("Insufficient Balance");
        setLoading(false);
        return;
      }

      const connection = new Connection(network, "confirmed");
      const senderPublicKey = new PublicKey(walletAddress);
      const recipientPublicKey = new PublicKey(recipient);

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: senderPublicKey,
          toPubkey: recipientPublicKey,
          lamports: convertedValue * 1_000_000_000, // Convert SOL to lamports
        })
      );

      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      transaction.feePayer = senderPublicKey;

      const signedTransaction = await window.solflare.signTransaction(
        transaction
      );
      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize()
      );
      await connection.confirmTransaction(signature);

      setTxHash(signature);
      if (signature) {
        tokenTransfer(signature, walletAddress);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      fetchBalance(new PublicKey(walletAddress)); // Refresh balance
    }
  };

  // Listen for Account Changes in Phantom Wallet
  useEffect(() => {
    const handleAccountChange = (newPublicKey) => {
      if (newPublicKey) {
        setWalletAddress(newPublicKey.toString());
        fetchBalance(newPublicKey);
      } else {
        setWalletAddress(null);
        setBalance(0);
      }
    };

    if (window.solana && window.solana.isPhantom) {
      window.solana.on("accountChanged", handleAccountChange);
    }

    return () => {
      if (window.solana && window.solana.isPhantom) {
        window.solana.off("accountChanged", handleAccountChange);
      }
    };
  }, []);

  const tokenTransfer = async (txHash, walletAddressData) => {
    let data = {
      tokenAmount: euronValue,
      equivalentAmount: convertedValue,
      paymentType: selectedOption.value,
      walletAddress: walletAddressData,
      trxHash: txHash,
      status: 0,
    };
    try {
      const res = await tokenTransferAction(data);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
    }
  };

  return (
    <>
      <Modal show={loading} centered>
        <Modal.Header>
          <Modal.Title className="text-center"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-3" style={{ color: "#fff" }}>
            Your transaction is being processed. Please do not close or refresh
            this popup as it may interrupt the process.
          </div>
          <div className="d-flex align-items-center justify-content-center mb-2">
            <Oval
              visible={true}
              height="80"
              width="80"
              color="#D127EB"
              ariaLabel="oval-loading"
              secondaryColor="#D127EB"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </Modal.Body>
      </Modal>

      <div className="crypto-tab pt-2 pb-2">



        <InputGroup className="mb-sm-1 mb-3 gap-3 d-flex justify-content-end">
          <div className="bnowtab1">
            <Form.Control
              type="text"
              onInput={(event) => {
                const { value } = event.target;
                if (!/^\d*\.?\d*$/.test(value)) {
                  event.target.value = value.replace(/[^0-9.]/g, ""); // Allow only numbers and one decimal point
                }
              }}

              value={euronValue}
              onChange={(event) => {
                let value = event.target.value;
                if ((value.match(/\./g) || []).length > 1) {
                  return;
                }
                let [integerPart, decimalPart] = value.split(".");
                if (integerPart.length > 15) {
                  integerPart = integerPart.slice(0, 15);
                }
                value =
                  decimalPart !== undefined
                    ? `${integerPart}.${decimalPart}`
                    : integerPart;
                handleEuronChange({ target: { value } });
              }}
              className="buy-now-input px-3 py-2"
              placeholder="Enter amount"
            />
          </div>
          <div className="euron px-4 py-1 float-right">
            <img
              src="images/lionlogo.svg"
              alt="LionPay"
              className="img-fluid me-2"
              style={{ width: 30, height: 30 }}
            />
            LionPay
          </div>
        </InputGroup>
        <div className="d-flex mb-4 gap-3 w-100 align-items-center ms-md-3 ">


          {/* <div
            className="d-flex justify-content-center align-items-center exchange position-absolute "
            style={{ left: "50%" }}
          >
            <CgArrowsExchangeAltV style={{ width: 20, height: 20 }} />
          </div> */}
          <div className="w-100 " style={{ cursor: "pointer" }}>
            {/* <div
              className="my-1"
              style={{ backgroundColor: "#ffffff20", width: "100%", height: 1 }}
            ></div> */}
            {selectedOption.value === "SOLANA" ? (
              <p className="mb-1 ms-3" style={{ fontSize: 12 }}>
                {/* 1 SOL = ${allSetting?.solanaPriceUsd} */}
              </p>
            ) : (
              <p className="mb-1 ms-3" style={{ fontSize: 12 }}>
                {/* 1 USDT = ${allSetting?.usdTetherPrice} */}
              </p>
            )}
          </div>
        </div>

        <div className="d-flex mb-4 ps-3">
          <div className="price_tag">
            {selectedOption.value === "SOLANA" ? (
              <p
                className=" mb-0 px-2 py-1 d-flex justify-content-center align-items-center"
              >
               1 LPAY  ={" "}
                {loadingData
                  ? parseFloat(
                    allSetting?.usdprice / allSetting?.solanaPriceUsd
                  ).toFixed(6) + " SOL"
                  : "Loading..."}
              </p>
            ) : (
              <p
                className=" mb-0  px-4 d-flex justify-content-center align-items-center"
              >
                1 LPAY  ={" "}
                {loadingData ? "$" + allSetting?.usdprice : "Loading..."}
              </p>
            )}
          </div>
        </div>

        <InputGroup className="mb-3 gap-3 d-flex justify-content-end ">
          <div className="bnowtab1">
            <Form.Control
              type="text"
              onInput={(event) => {
                const { value } = event.target;
                if (!/^\d*\.?\d*$/.test(value)) {
                  event.target.value = value.replace(/[^0-9.]/g, ""); // Allow only numbers and one decimal point
                }
              }}
              value={convertedValue}
              onChange={(event) => {
                let value = event.target.value;

                // Allow only one decimal point
                if ((value.match(/\./g) || []).length > 1) {
                  return;
                }

                // Split into integer and decimal parts
                let [integerPart, decimalPart] = value.split(".");

                // Limit integer part to 15 digits
                if (integerPart.length > 15) {
                  integerPart = integerPart.slice(0, 15);
                }

                // Reconstruct the value
                value =
                  decimalPart !== undefined
                    ? `${integerPart}.${decimalPart}`
                    : integerPart;

                handleConvertedChange({ target: { value } });
              }}
              className="buy-now-input px-3 py-2"
              placeholder="Equivalent amount"
            />
          </div>

          <div>
            <Select
              options={options}
              onChange={handleChange}
              value={selectedOption}
              className="buy-now-select"
              isSearchable={false}
              classNamePrefix="buy-now"
            />
          </div>
        </InputGroup>
        {selectedOption.value === "fiat" ? (
          <InputGroup className="mb-3 gap-3 w-100">
            <div style={{ width: "70%" }}>
              <Form.Control
                type="text"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                className="buy-now-input px-3 py-2"
                placeholder="Enter Wallet Address"
              />
            </div>
          </InputGroup>
        ) : (
          ""
        )}
      </div>

      {selectedOption.value === "USDT" || selectedOption.value === "SOLANA" ? (
        <Row className="gy-3 justify-content-center align-items-center">
          <Col className="d-flex align-items-center justify-content-sm-center justify-content-start">
            <label className="radio-container">
              <input
                type="radio"
                style={{ cursor: "pointer" }}
                name="user"
                value="Phantom"
                checked={selectedWallet === "Phantom"}
                onChange={(e) => setSelectedWallet(e.target.value)}
              />
              <div className="d-flex gap-2" style={{ cursor: "pointer" }}>
                <img
                  src="images/phantom.jfif"
                  className="user-image"
                  style={{ width: 20, height: 20, borderRadius: 50 }}
                />
                <small>Phantom</small>
              </div>
            </label>
          </Col>

          <Col className="d-flex align-items-center justify-content-sm-center justify-content-start">
            <label className="radio-container">
              <input
                type="radio"
                style={{ cursor: "pointer" }}
                name="user"
                value="Solflare"
                checked={selectedWallet === "Solflare"}
                onChange={(e) => setSelectedWallet(e.target.value)}
              />
              <div className="d-flex gap-2" style={{ cursor: "pointer" }}>
                <img
                  src="images/solflare.png"
                  className="user-image"
                  style={{ width: 20, height: 20, borderRadius: 50 }}
                />
                <small>Solflare</small>
              </div>
            </label>
          </Col>

          <Col className="d-flex align-items-center justify-content-sm-center justify-content-start">
            <label className="radio-container">
              <div className="d-flex gap-2">
                <a
                  href="https://www.moonpay.com/buy?shortlink=2z2wccfe&pid=moonpay.com&af_xp=qr&source_caller=ui"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <img
                    src={`${config.baseUrl}images/moonpay-logo.png`}
                    className="user-image"
                    style={{ width: 20, height: 20, borderRadius: 50 }}
                  />
                  &nbsp;
                  <small>MoonPay</small>
                </a>
              </div>
            </label>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <div
        className=" d-flex align-items-center mt-3 justify-content-center w-100"
        style={{}}
      >
        <Link
          className="btn01 py-2 mt-2 w-100"
          style={{
            cursor: "pointer",
            textDecoration: "none",
            maxWidth: 400,
            zIndex: 0,
          }}
          onClick={handleSubmit}
        >
          Buy Now
        </Link>
      </div>
    </>
  );
};

export default BuyNow;
