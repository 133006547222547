import React, { useState } from "react";
import DashboardHeader from "../component/DashboardHeader";
import Sidebar from "../component/Sidebar";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../styles/Dashboard.css";
import "../styles/Custom.css";
import toast, { Toaster } from "react-hot-toast";
import { changePasswordAction } from "../Action/action";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import Cookies from "js-cookie";

const ChangePassword = ({ special, clearModalData }) => {
  let sessionData = Cookies.get("sessionId");
  let parsedSession = JSON.parse(sessionData); 

  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [loader,setLoader] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  const handleChangePassword = async () => {
    const { currentPassword, newPassword, confirmPassword } = formData;

    // Password validation regex (at least 8 chars, 1 special char)
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;"'<>,.?/~`]).{8,}$/;

    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("Please fill in all password fields.");
      return;
    }

    if (!passwordRegex.test(newPassword)) {
      toast.error(
        "Password should contain 1 special character, 1 Uppercase letter, at least 8 characters, at least 1 number"
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }
    setLoader(true)
    try {
      let data = {
        ...formData,
        sessionId: parsedSession.sessionId
      }
      const response = await changePasswordAction(data);
      if (response.success) {
        toast.success("Password changed successfully!");
        setFormData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
    setLoader(false)

      } else {
        toast.error(response.msg || "Failed to change password.");
    setLoader(false)
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    setLoader(false)
    }
  };


  return (
    <>
      <Toaster />
      <div
        className="w-100"
        // style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="d-flex  w-100">
          <div>
            <Sidebar special={special} clearModalData={clearModalData} />
          </div>
          <div className="w-100" style={{ height: "100%" }}>
            <DashboardHeader />
            <div
              className="mt-5 mt-lg-4 px-4 px-lg-5  wrapper-dash"
              style={{
                // padding: "15px",
                flex: "1",
                backgroundColor: "#100500",
                color: "#ffffff",
                height: "87vh",
                overflowY: "scroll",
              }}
            >
              <h2 className=" dash-header">Change Password</h2>

              <br />
              <div className="profile-container dash-gradient2 h-auto p-4">
                <Form>
                  <Row className="gy-2">
                    <Col lg={4}>
                      <Form.Group
                        className="mb-2 "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Current Password{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="position-relative">
                          <div
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              togglePasswordVisibility("currentPassword")
                            }
                          >
                            {showPassword.currentPassword ? (
                              <EyeSlash
                                style={{ color: "black", fill: "black" }}
                              />
                            ) : (
                              <Eye style={{ color: "black", fill: "black" }} />
                            )}
                          </div>
                          <Form.Control
                            type={
                              showPassword.currentPassword ? "text" : "password"
                            }
                            name="currentPassword"
                            value={formData.currentPassword}
                            onChange={handleInputChange}
                            className="py-2"
                            placeholder="Enter your current password"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group
                        className="mb-2 "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          New Password <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <div className="position-relative">
                          <div
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              togglePasswordVisibility("newPassword")
                            }
                          >
                            {showPassword.newPassword ? (
                              <EyeSlash
                                style={{ color: "black", fill: "black" }}
                              />
                            ) : (
                              <Eye style={{ color: "black", fill: "black" }} />
                            )}
                          </div>
                          <Form.Control
                            type={
                              showPassword.newPassword ? "text" : "password"
                            }
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleInputChange}
                            className="py-2"
                            placeholder="Enter your new password"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group
                        className="mb-2 "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {" "}
                          Confirm Password{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="position-relative">
                          <div
                            style={{
                              position: "absolute",
                              right: 10,
                              top: 10,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              togglePasswordVisibility("confirmPassword")
                            }
                          >
                            {showPassword.confirmPassword ? (
                              <EyeSlash
                                style={{ color: "black", fill: "black" }}
                              />
                            ) : (
                              <Eye style={{ color: "black", fill: "black" }} />
                            )}
                          </div>
                          <Form.Control
                            type={
                              showPassword.confirmPassword ? "text" : "password"
                            }
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            className="py-2"
                            placeholder="Confirm your password"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <span
                      className="mb-3"
                      style={{ fontSize: 14, color: "#ffffff95" }}
                    >
                      * Password should contain 1 special character, 1 Uppercase letter, at least 8 characters, at least 1 number.
                    </span>
                  </Row>
                  <Row className="gy-2  justify-content-end">
                    <Col lg={4}>
                    {loader == false ?  
                    <div
                    className="btn01 px-4  "
                    style={{ padding: "8px 0px", cursor: "pointer" }}
                    onClick={() => {
                      handleChangePassword();
                    }}
                  >
                    Change Password
                  </div>:
                  <div
                  className="btn01 px-4  "
                  style={{ padding: "8px 0px", cursor: "not-allowed" }}
                 
                >
                  Loading...
                </div>
                  }
                      
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
