import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaFacebook, FaTelegram, FaTiktok, FaXTwitter } from "react-icons/fa6";
import { IoLocationSharp, IoLogoInstagram } from "react-icons/io5";
import { PiEnvelopeSimpleLight } from "react-icons/pi";
import "../styles/Footer.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is loaded
import { Link } from "react-router";
import config from "../corefiles/config";
import { insertNewsLetterAction } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import { MdMyLocation } from "react-icons/md";
const Footer = () => {
  let [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const validateEmail = () => {
    // Email validation pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    if (!emailPattern.test(email)) {
      setEmailError("Invalid email format");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail()) {
      const res = await insertNewsLetterAction({ email: email.toLowerCase() });
      if (res.success) {
        toast.success(res.msg);
        setEmail("");
      } else {
        toast.error(res.msg);
      }
    }
  };

  return (
    <div className="footer pt-5 pb-3 px-3 px-md-0">
      <Toaster />
      <Container>
        <Row className="gy-3">
          <Col lg={3}>
            <div className="d-flex flex-column align-items-lg-start align-items-center">
              <img
                src={`${config.baseUrl}images/logoLionIcon.png`}
                className="img-fluid w-100"
                style={{ maxWidth: 300, objectFit: "contain" }}
                alt="Logo"
              />
              <p className="text-white mb-0" style={{ fontSize: 12 }}>
                <IoLocationSharp /> Lion Pay, San Jose, Costa Rica
              </p>
              <p className="text-white mt-1 mb-2" style={{ fontSize: 11 }}>
                Designed & Developed by{" "}
                <a
                  className="text-white"
                  style={{ textDecoration: "none" }}
                  href="https://www.itsparktechnology.com"
                  target="_blank"
                >
                  {" "}
                  IT Spark Technology Pvt. Ltd.
                </a>{" "}
              </p>
            </div>
          </Col>
          <Col lg={9}>
            {/* Newsletter Form */}
            <form onSubmit={handleSubmit}>
              <Row
                className="d-flex justify-content-between align-items-center footer-letter gy-2"
                style={{ cursor: "none" }}
              >
                <Col lg={6}>
                  <h4
                    className="mb-0"
                    style={{ fontWeight: 600, color: "white" }}
                  >
                    Newsletter
                  </h4>
                  <p
                    className="mb-0"
                    style={{ fontWeight: 400, color: "white" }}
                  >
                    Be the first one to know about discounts, offers, and
                    events.
                  </p>
                </Col>
                <Col lg={6}>
                  <Row className="gy-2">
                    <Col
                      lg={8}
                      className="d-flex position-relative flex-column"
                    >
                      <div
                        className="d-flex position-relative flex-column"
                        style={{ minHeight: 45 }}
                      >
                        <PiEnvelopeSimpleLight
                          className="position-absolute"
                          style={{
                            top: 10,
                            left: 13,
                            width: 25,
                            height: 25,
                            fill: "#00000050",
                          }}
                        />
                        <input
                          type="text"
                          className="w-100 px-5 h-100"
                          style={{
                            borderRadius: 5,
                            border: "none",
                            outline: "none",
                          }}
                          placeholder="Enter your email"
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </div>
                      {emailError && (
                        <div
                          className="mt-2"
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}
                        >
                          {emailError}
                        </div>
                      )}
                    </Col>

                    <Col lg={4} className="d-flex position-relative">
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          borderRadius: 5,
                          maxHeight: 45,
                        }}
                        className="d-flex h-100 footer-btn justify-content-center align-items-center"
                      >
                        Subscribe
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>

            {/* SOCIAL MEDIA */}
            <Row className="d-flex justify-content-between align-items-center gy-3 w-100 mt-2">
              <Col lg={8} md={12} sm={12} xs={12}>
                <div className="text-white smicons d-flex justify-content-lg-start justify-content-center gap-3 mb-md-0 mb-3">
                  <Link
                    to={`${config.baseUrl}terms-and-conditions`}
                    style={{ textDecoration: "none" }}
                    className="footer-page text-nowrap mb-0"
                  >
                    Terms & Conditions
                  </Link>
                  <Link
                    to={`${config.baseUrl}privacy-policy`}
                    style={{ textDecoration: "none" }}
                    className="footer-page text-nowrap mb-0"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`${config.baseUrl}whitepaper`}
                    target="_blank"
                    className="mb-0"
                  >
                    Whitepaper
                  </Link>
                  <Link
                    to={`${config.baseUrl}contact-us`}
                    style={{ textDecoration: "none" }}
                    className="footer-page mb-0"
                  >
                    Contact Us
                  </Link>
                </div>
              </Col>
              <Col
  lg={4}
  md={12}
  sm={12}
  xs={12}
  className="d-flex gap-3 justify-content-lg-end justify-content-center align-items-center"
>
  <a
    href="https://x.com/lionp2225"
    target="_blank"
    rel="noopener noreferrer"
    className="text-white smicons smedia d-flex gap-3 justify-content-lg-end justify-content-center align-items-center"
  >
    <FaXTwitter style={{ fill: "white" }} />
  </a>

  <a
    href="https://www.tiktok.com/@lionpay"
    target="_blank"
    rel="noopener noreferrer"
    className="text-white smicons smedia d-flex gap-3 justify-content-lg-end justify-content-center align-items-center"
  >
    <FaTiktok style={{ fill: "white" }} />
  </a>

  <a
    href="https://www.instagram.com/lionpay44/"
    target="_blank"
    rel="noopener noreferrer"
    className="text-white smicons smedia d-flex gap-3 justify-content-lg-end justify-content-center align-items-center"
  >
    <IoLogoInstagram style={{ fill: "white" }} />
  </a>

  <a
    href="https://t.me/lionpaycommunity"
    target="_blank"
    rel="noopener noreferrer"
    className="text-white smicons smedia d-flex gap-3 justify-content-lg-end justify-content-center align-items-center"
  >
    <FaTelegram style={{ fill: "white" }} />
  </a>
</Col>

            </Row>
          </Col>
        </Row>
        <div className="w-100 text-center">
          <hr className="mb-2" style={{ color: "#ffffff50" }} />
          <p className="mb-0" style={{ color: "#ffffff95", fontSize: 12 }}>
            © 2025 Lion Pay. All rights reserved
          </p>
          <hr className="mb-0 mt-2" style={{ color: "#ffffff50" }} />
        </div>
      </Container>
    </div>
  );
};

export default Footer;
