import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../directives/Header";
import "../../styles/Footer.css";
import Footer from "../../directives/Footer";

const Terms = () => {
  return (
    <>
      <div className=" w-100" style={{ zIndex: 1 }}>
        <Header />
      </div>
      <Container className="text-white " style={{ zIndex: 0 }}>
        <h1 className="mb-0 colortheme"> Terms of Use</h1>
        <small>Updated on 20 Feb 2025</small>

        <div className="d-flex align-items-center gap-3 mt-3">
          <h4 className="text-nowrap mb-0 colortheme">Accepting the terms</h4>
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "#ffffff40",
              borderRadius: 10,
            }}
          ></div>
        </div>

        <div className="content mt-3">
          <ol className="terms-ol colortheme">
            <li>
              <p className="mb-2 terms-heading">Introduction</p>
              <p className="mb-0 terms-para">
                Welcome to LionPay. By accessing or using our platform, you
                agree to comply with these Terms & Conditions. Please read them
                carefully before using our services.
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">
                No Financial or Investment Advice
              </p>
              <p className="mb-0 terms-para">
                LionPay is not a security, stock, or regulated financial
                product. The purchase of LionPay tokens does not guarantee any
                return on investment or price appreciation. Users acknowledge
                that investing in cryptocurrencies carries inherent risks,
                including but not limited to the potential loss of capital.
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">User Responsibilities</p>
              <ul>
                <li className="mb-0 terms-para">
                  Users act at their own discretion and assume full
                  responsibility for their investment decisions.
                </li>
                <li className="mb-0 terms-para">
                  Users must comply with applicable laws and regulations in
                  their respective jurisdictions.
                </li>
                <li className="mb-0 terms-para">
                  Users acknowledge that LionPay does not provide financial,
                  legal, or tax advice.
                </li>
              </ul>
            </li>
            <li>
              <p className="mb-2 terms-heading">No Regulatory Oversight</p>
              <p className="mb-0 terms-para">
                LionPay is not a registered financial entity and is not subject
                to financial regulatory oversight. We do not offer guarantees,
                warranties, or assurances regarding token value, liquidity, or
                stability.{" "}
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Limitation of Liability</p>
              <p className="mb-0 terms-para">
                LionPay shall not be held liable for any financial losses,
                trading losses, or damages arising from the use of our platform
                or services. Users agree to hold LionPay harmless from any
                claims related to their use of the platform.{" "}
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Amendments</p>
              <p className="mb-0 terms-para">
                LionPay reserves the right to modify these Terms & Conditions at
                any time without prior notice. Users are encouraged to review
                the terms periodically.{" "}
              </p>
            </li>
            <li>
              <p className="mb-2 terms-heading">Governing Law</p>
              <p className="mb-0 terms-para">
                These Terms & Conditions shall be governed by and interpreted in
                accordance with the laws of the applicable jurisdiction.{" "}
              </p>
            </li>
          </ol>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Terms;
