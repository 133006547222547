import React, { useEffect, useState } from "react";
import DashboardHeader from "../component/DashboardHeader";
import Sidebar from "../component/Sidebar";
import { getNotificationAction } from "../Action/action";
import moment from "moment/moment";

const Notification = ({ special, clearModalData }) => {
  const [Notification, setNotification] = useState([]);
  useEffect(() => {
    NotificationData();
  }, []);

  const NotificationData = async () => {
    const res = await getNotificationAction();
    if (res.success) {
      setNotification(res.data);
    } else {
      setNotification([]);
    }
  };
  const sortedNotifications = [...Notification].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  return (
    <>
      <div className="w-100" style={{ height: "100vh", overflow: "hidden" }}>
        <div className="d-flex  w-100">
          <div>
            <Sidebar special={special} clearModalData={clearModalData} />
          </div>

          <div className="w-100" style={{ height: "100%" }}>
            <DashboardHeader />
            <div
              className="pt-2 mt-lg-4 px-4 px-lg-5 pb-5 mb-5  wrapper-dash"
              fluid
              style={{
                // padding: "15px",
                flex: "1",
                backgroundColor: "#100500",
                color: "#ffffff",
                height: "87vh",
                overflowY: "scroll",
              }}
            >
              <h2 className=" dash-header">Notifications</h2>
              <br />

              <div className="profile-container dash-gradient2 h-auto px-4 pb-4 pt-2">
                {sortedNotifications.length === 0 ? (
                  <div className="pt-3 text-center">
                    <p className="mb-0" style={{ fontSize: 14 }}>
                      No notifications found
                    </p>
                  </div>
                ) : (
                  sortedNotifications.map((item, index) => (
                    <div
                      key={index}
                      className="py-3 noti d-flex align-items-center"
                    >
                      <img
                        src="images/notification.svg"
                        alt=""
                        className="me-2"
                        style={{ width: 20, height: 20 }}
                      />
                      <div className="d-flex justify-content-between align-items-center w-100 flex-wrap">
                        <p
                          className="mb-0"
                          style={{
                            fontSize: 15,
                            color: item.isRead ? "gray" : "inherit",
                          }}
                        >
                          {item.message}.
                        </p>

                        <span className="noti" style={{ fontSize: 12 }}>
                          {moment(item.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss A"
                          )}
                        </span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
