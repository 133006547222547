import {
  getRequest,
  postRequest,
  PutRequest,
  PatchRequest,
  deleteRequest,
  postRequestFormData,
} from "../corefiles/helper";


export const registerUser = async (data) => {
  const res = await postRequest("user/register", data)
  return res.data
}

export const UserLogin = async (data) => {
  const res = await postRequest("user/login", data)
  return res.data
}

export const getUserSession = async (data) => {
  const res = await postRequest("user/getUserSession", data)
  return res.data
}

export const getUserSessionById = async (data) => {
  const res = await postRequest("user/getUserSessionById", data)
  return res.data
}


export const forgetPassword = async (data) => {
  const res = await postRequest("user/forgetPassword", data)
  return res.data
}

export const verifyOTPAction = async (data) => {
  const res = await postRequest("user/verifyOTP", data)
  return res.data
}

export const sendOtpAction = async (data) => {
  const res = await postRequest("user/sendOtp", data)
  return res.data
}

export const resetPasswordAPI = async (data) => {
  const res = await postRequest("user/resetMyPassword", data)
  return res.data
}

export const insertContactAction = async (data) => {
  const res = await postRequest("user/contactUs", data)
  return res.data
}

export const insertNewsLetterAction = async (data) => {
  const res = await postRequest("user/newsLetter", data)
  return res.data
}


export const getProfileDetails = async () => {
  const res = await getRequest("user/getProfile")
  return res.data
}

export const updateProfileDetails = async (data) => {
  const res = await PutRequest("user/updateProfile", data)
  return res.data
}

export const getAllRefferal = async (data) => {
  const res = await getRequest("user/getRefferal", data)
  return res.data
}

export const getDashboardStaticsAction = async (data) => {
  const res = await getRequest("user/getDashboardStatics", data)
  return res.data
}

export const getTokenPurchaseHistoryAction = async (data) => {
  const res = await getRequest("user/getTokenPurchaseHistory", data)
  return res.data
}

export const changePasswordAction = async (data) => {
  const res = await PutRequest("user/changePassword", data)
  return res.data
}

export const getNotificationAction = async () => {
  const res = await getRequest("user/Notification/getNotification")
  return res.data
}

export const getUnreadNotificationCountAction = async () => {
  const res = await getRequest("user/Notification/getUnreadNotificationCount")
  return res.data
}

export const getRefferalDetailsAction = async () => {
  const res = await getRequest("user/getRefferal")
  return res.data
}

export const getRefferalPurchaseDetailsAction = async () => {
  const res = await getRequest("user/getTokenReferralPurchaseHistory")
  return res.data
}


export const verifyOtpAction = async (data) => {
  const res = await postRequest("user/verifyOtp", data)
  return res.data
}


export const getAllPhaseDetailsAction = async () => {
  const res = await getRequest("admin/phase/getAllPhase")
  return res.data
}

export const tokenTransferAction = async (data) => {
  const res = await postRequest("user/tokenpurchase", data)
  return res.data
}

export const getAllSettingAction = async () => {
  const res = await getRequest("admin/setting/getSetting")
  return res.data
}

